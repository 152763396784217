import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    marginTop: 10,
    paddingBottom: 50,
  },
  rowDiv: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 70,
    color: 'white',
  },
  imgContainer: {
    borderRadius: 50,
    width: 45,
    height: 45,
    marginHorizontal: 20,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: '100%',
    height: '100%',
    borderRadius: 50,
    objectFit: 'cover',
  },
  username: {
    flex: 1,
    fontSize: 17,
    color: 'white',
    textAlign: 'center',
  },
  line: {
    borderBottomWidth: 1,
    borderBottomColor: '#636363',
    marginLeft: 20,
    marginRight: 20,
  },
  emptyState: {
    flex: 1,
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 70,
  },
  text: {
    color: 'white',
    fontSize: 15,
  },
  buttonContainer: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    gap: 15,
  },
})
