import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: 'black',
  },
  cardEventContainer: {
    flexDirection: 'column',
    paddingTop: 50,
    paddingBottom: 70,
    justifyContent: 'center',
    paddingHorizontal: 15,
    gap: 20,
    width: '100%',
    height: '100%',
  },
  emptyContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  emptyText: {
    color: 'white',
    fontSize: 16,
  },
})

export default styles
