import { ScrollView, View, Text, Image } from 'react-native'
import styles from './PeopleListStyles'
import CustomRefreshControl from '@/components/CustomRefreshControl/CustomRefreshControl'
import CustomPressable from '../CustomPressable/CustomPressable'
import defaultProfilePic from '@/assets/People/defaultProfilePic.png'
import { TParticipantDetailModel } from '@/models'
// import { navigateTo } from 'routes/utils'

const PeopleList = ({
  participants,
  PrimaryButton,
  SecondaryButton,
  onRefresh,
  empty = '',
  goToDetailFunc,
  scrollDisabled = false,
}: {
  participants: TParticipantDetailModel[]
  PrimaryButton: React.FC<{ participant: TParticipantDetailModel }>
  SecondaryButton?: React.FC<{ participant: TParticipantDetailModel }>
  onRefresh: (v?: any) => void
  empty?: string
  goToDetailFunc: (participantId: string) => void
  scrollDisabled?: boolean
}) => {
  const profilePic = (participant: TParticipantDetailModel) =>
    participant?.profilePictures?.[0]
      ? // ? { uri: participant.profilePictures[0] }
        participant.profilePictures[0]
      : defaultProfilePic

  const goToDetail = (participantId: string) => {
    if (goToDetailFunc) {
      goToDetailFunc(participantId)
    }
  }

  return (
    <>
      {!participants?.[0] ? (
        <View style={styles.emptyState}>
          <Text style={styles.text}>{empty}</Text>
        </View>
      ) : (
        <ScrollView
          contentContainerStyle={styles.container}
          showsVerticalScrollIndicator={false}
          refreshControl={<CustomRefreshControl onRefresh={onRefresh} />}
          scrollEnabled={!scrollDisabled}
        >
          {participants.map((participant) => (
            <View style={styles.rowDiv} key={participant.participantId}>
              <CustomPressable
                style={styles.row}
                onPress={() => goToDetail(participant.participantId)}
              >
                <View style={styles.imgContainer}>
                  <img
                    src={profilePic(participant)}
                    alt="Foto de perfil"
                    style={styles.img}
                    loading="lazy"
                  />
                  {/* <Image source={profilePic(participant)} style={styles.img} /> */}
                </View>
                <Text style={styles.username}>
                  {participant.name} {participant.lastName}
                </Text>
                <View style={styles.buttonContainer}>
                  {PrimaryButton && (
                    <PrimaryButton
                      key={'primaryBtn-' + participant.participantId}
                      participant={participant}
                    />
                  )}
                  {SecondaryButton && (
                    <SecondaryButton
                      key={'secondaryBtn-' + participant.participantId}
                      participant={participant}
                    />
                  )}
                </View>
              </CustomPressable>
              {participants.length > 1 && <View style={styles.line} />}
            </View>
          ))}
        </ScrollView>
      )}
    </>
  )
}

export default PeopleList
