import { Text, Image } from 'react-native'
import styles from './PendingFeedbackStyles'
import clock from '@/assets/MyEvents/reloj.png'

const PendingFeedback = () => {
  return (
    <>
      <Image source={clock} style={styles.icono} />
      <Text style={styles.title}>
        La solicitud fue enviada, podrás acceder apenas te aprueben y el evento cambie su status de Pendiente a Confirmado
      </Text>
    </>
  )
}

export default PendingFeedback
