import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  imageEvent: {
    marginTop: 20,
    alignItems: 'center',
  },
  image: {
    height: 110,
    width: 110,
    borderRadius: 3,
  },
  titleEvent: {
    fontSize: 22,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    marginVertical: 10,
  },
  buttonDetailsContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10,
    marginBottom: 20,
  },
  buttonDetails: {
    backgroundColor: '#8811c8',
    borderRadius: 7,
    padding: 6,
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonDetailsText: {
    color: 'white',
    fontSize: 15,
    marginBottom: 2,
  },
})

export default styles
