import { View, Text, Animated, Image } from 'react-native'
import { sortByName } from '@/utils'
import { useState, useEffect, useCallback } from 'react'
import { CustomPressable, PeopleList, SmallVincufyButton } from '@/components'
import arrow from '@/assets/MyEvents/collapseArrow.png'
import styles from './CollapseStyles'
import { TParticipantDetailModel } from '@/models'
import { theme } from '@/theme'

const Collapse = ({
  title,
  participants,
  onRefresh,
  openModal,
  isOpenCollapse,
  setIsOpenCollapse,
  goToDetailFunc,
}: {
  title: string
  participants: TParticipantDetailModel[]
  onRefresh: (v: any) => void
  openModal: (v: any) => void
  isOpenCollapse: boolean
  setIsOpenCollapse: (v: boolean) => void
  goToDetailFunc: (participantId: string) => void
}) => {
  const [height] = useState(new Animated.Value(0))

  useEffect(() => {
    const calculatedHeight = Math.max(70, participants.length * 75)
    Animated.timing(height, {
      toValue: isOpenCollapse ? calculatedHeight : 0,
      duration: 300,
      useNativeDriver: false,
    }).start()
  }, [isOpenCollapse, participants, height])

  const handleContact = useCallback(
    async (participant: TParticipantDetailModel) => {
      openModal(participant)
    },
    [],
  )

  return (
    <View style={styles.container}>
      <View style={[styles.header, theme.bgGradientCenter]}>
        <CustomPressable
          style={styles.headerTouchable}
          onPress={() => setIsOpenCollapse(!isOpenCollapse)}
        >
          <Text style={styles.headerText}>{title}</Text>
          <Image
            source={arrow}
            style={[
              styles.arrow,
              {
                transform: [{ rotate: isOpenCollapse ? '180deg' : '0deg' }],
              },
            ]}
            tintColor="white"
          />
        </CustomPressable>
      </View>

      <Animated.View style={[styles.content, { height }]}>
        <PeopleList
          participants={sortByName(participants)}
          onRefresh={onRefresh}
          PrimaryButton={({
            participant,
          }: {
            participant: TParticipantDetailModel
          }) => (
            <SmallVincufyButton
              buttonType={'Contactar'}
              participant={participant}
              onClick={handleContact}
            />
          )}
          goToDetailFunc={goToDetailFunc}
        />
      </Animated.View>
    </View>
  )
}

export default Collapse
