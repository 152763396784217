import { useState } from 'react'
import { View, TextInput, Image } from 'react-native'
import styles from './PasswordStyles'
import show from '@/assets/Password/show.png'
import hide from '@/assets/Password/hide.png'
import { theme } from '@/theme'
import { CustomPressable } from '@/components'

export default function Password({
  value,
  onChange,
  hasError,
  placeholder,
  autoComplete,
}: {
  value: string
  onChange: (newValue: string) => void
  hasError: boolean
  placeholder: string
  autoComplete: 'new-password' | 'password'
}) {
  const [showPass, setShowPass] = useState(false)

  return (
    <View style={styles.passwordContainer}>
      <TextInput
        value={value}
        placeholder={placeholder}
        placeholderTextColor="rgb(96, 96, 96)"
        style={theme.inputAuth}
        secureTextEntry={!showPass}
        onChangeText={(e) => onChange(e)}
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete={autoComplete}
      />
      <CustomPressable
        onPress={() => setShowPass((v) => !v)}
        style={styles.showHideButton}
      >
        <Image source={showPass ? show : hide} style={styles.icon} />
      </CustomPressable>
    </View>
  )
}
