import { ActivityIndicator } from 'react-native'
import { Pressable } from 'react-native-web'

const CustomPressable = ({
  style = {},
  children,
  withFeedback = true,
  disabled = false,
  isLoading = false,
  ...props
}) => (
  <Pressable
    {...props}
    disabled={isLoading || disabled}
    style={(state) => {
      const baseStyle = typeof style === 'function' ? style(state) : style
      return [
        ...(Array.isArray(baseStyle) ? baseStyle : [baseStyle]),
        state.pressed && withFeedback && { opacity: 0.7 },
      ]
    }}
  >
    {isLoading ? <ActivityIndicator size="small" color="#ffffff" /> : children}
  </Pressable>
)

export default CustomPressable
