import { customAlert } from '../custom-alert.util'

export const shareContent = async (eventName: string, eventId: string) => {
  const url = `https://vincufy.com/evento/${eventId}`
  const title = `Evento: ${eventName}`
  const text = `Mirá este evento en Vincufy!`

  if (navigator.share) {
    try {
      await navigator.share({
        title,
        text,
        url,
      })
    } catch (error) {
      console.error('Error al compartir:', error)
      customAlert('Error', 'No se pudo compartir el enlace.')
    }
  } else {
    const fallbackText = `${text}\n${url}`
    await navigator.clipboard.writeText(fallbackText)
    customAlert(
      'Compartir enlace',
      'El navegador no soporta la función de compartir. El enlace ha sido copiado al portapapeles.',
    )
  }
}
