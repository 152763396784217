import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    alignItems:'center'
  },
  contentInfo: {
    alignItems: 'center',
  },
  flyerDiv: {
    height: 110,
    minHeight: 110,
    width: 110,
    minWidth: 110,
    marginTop: 40,
    overflow: 'hidden',
    backgroundColor: '#ffffff',
    borderRadius: 8,
  },
  flyer: {
    height: '100%',
    width: '100%',
  },
  titleEvent: {
    color: 'white',
    fontSize: 23,
    fontWeight: '500',
    textAlign: 'center',
  },
  titleIconRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    marginTop: 15,
  },
  productionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  eventProducerName: {
    color: 'silver',
    fontSize: 16,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  icon: {
    height: 20,
    width: 20,
    marginTop: 3,
  },
  buttonBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  mainButtons: {
    height: 41,
    width: 45,
    borderRadius: 3,
    overflow: 'hidden',
  },
  buttonContainers:{
    flexDirection:'row',
    gap:5
  }
})

export default styles
