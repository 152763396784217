import { memo } from 'react'
import { View, Text, Image, ActivityIndicator } from 'react-native-web'
import styles from '../AllowNotificationsStyles'
import notificationGranted from '@/assets/AllowNotifications/notificationGranted.png'
import { colors } from '@/theme'

const PermissionGranted = () => (
  <View style={styles.container}>
    <Image source={notificationGranted} style={styles.imageNotiGranted} />
    <Text style={styles.title}>Notificaciones Activadas</Text>
    <Text style={styles.description}>
      Terminamos de configurar los ultimos detalles y estamos listos !
    </Text>
    <ActivityIndicator size="large" color={colors.primary} />
  </View>
)

export default memo(PermissionGranted)
