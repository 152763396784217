import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  error: {
    color: 'rgb(215, 64, 64)',
    marginTop: 8,
    marginBottom: -10,
  },
  text: {
    color: '#FFFFFF',
    marginTop: 8,
    marginBottom: -10,
  },
  footerList: {
    marginBottom: 70,
  },
  footerListLoading: {
    marginTop: 17,
    marginBottom: 17,
  },
  bckgImg: {
    width: 170,
    height: 33,
    position: 'absolute',
    borderRadius: 7,
  },
  info: {
    height: 14,
    width: 14,
    marginRight: 10,
  },
})
