import { Text, Image, View } from 'react-native'
import styles from './ErrorScreenStyles'
import errorIcon from '@/assets/MyEvents/error.png'

export const ErrorScreen = () => {
  return (
    <View style={styles.errorScreen}>
      <Image source={errorIcon} style={styles.icono} />
      <Text style={styles.title}>Error del sistema, compruebe su conexión</Text>
      <Text style={styles.title}>y recargue la página.</Text>
    </View>
  )
}
