import { navigationLog } from '@/utils'
import { useEffect, useRef, useState } from 'react'
import {
  isCurrentPage,
  navigateTo,
  PAGES_NAV,
  TAllPageNavigatorsParams,
} from './utils'
import { useInitialLogic, useRouteListener } from './logic'
import { useAppDispatch } from '@/redux/store'
import { postLoggedFlowFinished } from '@/redux/actions'

export const unauthPages: (keyof TAllPageNavigatorsParams)[] = ['EventDetail']

export const useRedirectLogic = () => {
  const dispatch = useAppDispatch()
  const {
    isLoadingInitialLogic,
    hasActiveSession,
    isPostLoggedFlow,
    loggedOrigin,
    isRegistrationIncomplete,
    notificationSetupFinished,
  } = useInitialLogic()

  const { currentRoute } = useRouteListener()
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const isInitialPage = useRef(true)

  if (
    isLoadingPage &&
    typeof window !== 'undefined' &&
    document.title !== 'Vincufy'
  )
    document.title = 'Vincufy'

  useEffect(() => {
    // Redirect Logic

    navigationLog('REDIRECT_LOGIC', {
      currentRoute,
      isInitialPage: isInitialPage.current,
      isLoadingInitialLogic,
      hasActiveSession,
      isRegistrationIncomplete,
      notificationSetupFinished,
      isPostLoggedFlow,
    })

    if (!currentRoute) return

    setIsLoadingPage(isLoadingInitialLogic)
    if (isLoadingInitialLogic) return

    const isUnauthPage =
      currentRoute &&
      unauthPages.some((unauthPage) => isCurrentPage(unauthPage, currentRoute))
    navigationLog('IsUnauthPage: ', isUnauthPage)

    if (!hasActiveSession && !isUnauthPage) {
      // isInitialPage.current = false
      navigateTo('Login', {}, 'force')
      return
    }
    if (hasActiveSession) {
      if (isRegistrationIncomplete) {
        navigateTo('CompleteRegister', {}, 'initial')
        return
      }
      if (!notificationSetupFinished) {
        navigateTo('AllowNotifications', {}, 'initial')
        return
      }
      if (isPostLoggedFlow && loggedOrigin.functionality === 'requestTickets') {
        // isInitialPage.current = false
        navigateTo(
          'EventDetail',
          {
            eventId: loggedOrigin.params?.eventId || '',
          },
          'reset',
        )
        return
      }
    }
    if (isInitialPage.current) {
      // isInitialPage.current = false
      dispatch(postLoggedFlowFinished())

      for (const page in PAGES_NAV) {
        if (
          !PAGES_NAV[page]?.isRedirectPage &&
          isCurrentPage(page as keyof typeof PAGES_NAV, currentRoute)
        ) {
          navigateTo(PAGES_NAV[page].name, {}, 'initial')
          return
        }
      }
      navigateTo('MyEvents', {}, 'reset')
      return
    }
    // if (isPostLoggedFlow) {
    //   navigateTo('MyEvents', {}, 'reset')
    //   return
    // }
    navigationLog('REDIRECT_LOGIC NO_ACTION')
  }, [
    currentRoute,
    isInitialPage,
    isLoadingInitialLogic,
    hasActiveSession,
    isRegistrationIncomplete,
    notificationSetupFinished,
  ])

  return {
    isLoadingPage,
  }
}
