import { PeopleList } from '@/components'
import { TParticipantDetailModel } from '@/models'
import { useAppSelector } from '@/redux/store'
import { useMemo } from 'react'

const ParticipantList = ({
  participantsId,
  emptyMessage,
  primaryButton,
  secondaryButton,
  goToDetailFunc,
}: {
  participantsId: TParticipantDetailModel['participantId'][]
  emptyMessage: string
  primaryButton: React.FC<{ participant: TParticipantDetailModel }>
  secondaryButton?: React.FC<{ participant: TParticipantDetailModel }>
  goToDetailFunc: (participantId: string) => void
}) => {
  const participants = useAppSelector((state) => state.event.participants)

  const selectedParticipants = useMemo(() => {
    return participantsId.map((id) => participants[id]).filter(Boolean)
  }, [participantsId, participants])

  return (
    <PeopleList
      participants={selectedParticipants}
      empty={emptyMessage}
      goToDetailFunc={goToDetailFunc}
      onRefresh={() => {}}
      PrimaryButton={primaryButton}
      SecondaryButton={secondaryButton}
    />
  )
}

export default ParticipantList
