import { TUserAuthModel } from '@/models'
import { LOADING, LOGGED, POST_LOGGED_FLOW_FINISHED } from '@/redux/constants'
import { TLoggedOrigin } from '../reducers/auth.reducer'

export const logged = (
  userAuth: TUserAuthModel,
  origin: TLoggedOrigin = { functionality: '', params: {} },
) => ({
  type: LOGGED,
  payload: { userAuth, origin },
})

export const postLoggedFlowFinished = () => ({
  type: POST_LOGGED_FLOW_FINISHED,
})

export const setLoading = (payload: boolean) => ({
  type: LOADING,
  payload,
})
