import { HTTP_METHOD, vincufyApiCall } from '@/config/vincufyApi'
import {
  GET_EVENT_PARTICIPANTS,
  GET_EVENT_PARTICIPANTS_LOADING,
  GET_EVENT_PARTICIPANTS_ERROR,
  GET_USER_EVENTS,
  GET_ADMIN_EVENT_INFO,
  GET_EVENT_DETAIL,
  GET_EVENT_PARTICIPANT,
  GET_EVENT_PARTICIPANT_ERROR,
  GET_USER_EVENTS_LOADING,
  GET_USER_EVENTS_ERROR,
  GET_ADMIN_EVENT_INFO_ERROR,
  GET_ADMIN_EVENT_INFO_LOADING,
  SET_PARTICIPANT_STATUS,
  SET_PARTICIPANT_STATUS_ERROR,
  SET_PARTICIPANT_STATUS_LOADING,
  SET_PARTICIPANTS,
} from '../constants'
import {
  adminPanelMock,
  eventDetailMock,
  myBondsMock,
  participantsMock,
  userEventsMock,
  virtualRoomMock,
} from '@/mocks'
import { createCustomAsyncThunk } from '../store/createCustomAsyncThunk'
import {
  ParticipantStatus,
  TAdminEventInfoModel,
  TEventParticipantsCursor,
  TParticipantDetailModel,
  TParticipantModel,
} from '@/models'

export const getUserEvents = createCustomAsyncThunk(
  'event/getUserEvents',
  async (_: void, { dispatch }) => {
    try {
      dispatch({
        type: GET_USER_EVENTS_LOADING,
        payload: {},
      })

      const respBody = await vincufyApiCall({
        method: HTTP_METHOD.GET,
        endpoint: `/event/all`,
        body: {},
        mock: { userEvents: userEventsMock, page: 1 },
        forceMock: false,
      })

      const payload = {
        userEvents: respBody.userEvents,
        userEventsPagesFetched: respBody.page,
      }
      dispatch({
        type: GET_USER_EVENTS,
        payload,
      })
      return payload
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn('Action getUserEvents', errorMsg)

      dispatch({
        type: GET_USER_EVENTS_ERROR,
        payload: {
          error: errorMsg,
        },
      })
      throw new Error(errorMsg)
    }
  },
)

export const getEventDetail = createCustomAsyncThunk(
  'event/getEventDetail',
  async (eventId: string, { dispatch }) => {
    try {
      const respBody = await vincufyApiCall({
        method: HTTP_METHOD.GET,
        endpoint: `/event/unique/${eventId}/detail`,
        body: {},
        mock: { eventDetail: eventDetailMock },
        forceMock: false,
      })

      dispatch({
        type: GET_EVENT_DETAIL,
        payload: respBody.eventDetail,
      })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
    }
  },
)

export const requestTickets = createCustomAsyncThunk(
  'event/requestTickets',
  async (
    {
      eventId,
      eventTicketTypeId,
      quantity,
      userProfileId,
    }: {
      eventId: string
      eventTicketTypeId?: string
      quantity?: number
      userProfileId?: string
    },
    { dispatch, getState },
  ) => {
    try {
      const firstEventTicketTypeId =
        getState().event.eventDetail?.eventTicketTypes?.[0]?.id
      const firstUserProfileId = getState().user.userProfiles[0].id

      const respBody = await vincufyApiCall({
        method: HTTP_METHOD.POST,
        endpoint: `/event/tickets`,
        body: {
          eventId,
          eventTicketTypeId: eventTicketTypeId || firstEventTicketTypeId,
          quantity: quantity || 1,
          userProfileId: userProfileId || firstUserProfileId,
        },
        mock: { eventDetail: eventDetailMock },
        forceMock: false,
      })

      dispatch({
        type: GET_EVENT_DETAIL,
        payload: respBody.eventDetail,
      })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
    }
  },
)

export const getEventParticipantsAction = createCustomAsyncThunk(
  'event/getEventParticipantsAction',
  async (
    {
      currentEventId,
      initialFetch,
    }: { currentEventId: string; initialFetch: boolean },
    { dispatch, getState },
  ) => {
    try {
      dispatch({
        type: GET_EVENT_PARTICIPANTS_LOADING,
        payload: { eventId: currentEventId, initialFetch },
      })

      const cursor = !initialFetch
        ? getState().event.eventsParticipants?.[currentEventId]?.cursor ||
          undefined
        : undefined

      const randOrderSeed = !initialFetch
        ? getState().event.eventsParticipants?.[currentEventId]
            ?.randOrderSeed || undefined
        : Math.random()

      const respBody = await vincufyApiCall<{
        participants: TParticipantDetailModel[]
        cursor: TEventParticipantsCursor
        randOrderSeed: number
        hasMore: boolean
      }>({
        method: HTTP_METHOD.GET,
        endpoint: `/event/unique/${currentEventId}/participants?limit=${21}&randOrderSeed=${randOrderSeed}${cursor ? `&randOrderIndex=${cursor.randOrderIndex}&hasNoPhoto=${cursor.hasNoPhoto}` : ''}`,
        body: {},
        mock: {
          participants: virtualRoomMock.participants,
          cursor: {
            randOrderIndex: 1.5,
            hasNoPhoto: 1,
          },
          randOrderSeed: 0.23,
          hasMore: false,
        },
        forceMock: false,
      })

      dispatch({
        type: SET_PARTICIPANTS,
        payload: {
          participants: respBody.participants,
        },
      })
      dispatch({
        type: GET_EVENT_PARTICIPANTS,
        payload: {
          eventId: currentEventId,
          participants: respBody.participants,
          cursor: respBody.cursor,
          randOrderSeed: respBody.randOrderSeed,
          hasMore: respBody.hasMore,
        },
      })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      dispatch({
        type: GET_EVENT_PARTICIPANTS_ERROR,
        payload: {
          eventId: currentEventId,
          error: errorMsg || '',
        },
      })
    }
  },
)

export const getParticipantDetail = createCustomAsyncThunk(
  'event/getParticipantDetail',
  async (participantId: string, { dispatch }) => {
    try {
      const respBody = await vincufyApiCall({
        method: HTTP_METHOD.GET,
        endpoint: `/event/participant/${participantId}`,
        body: {},
        mock: { participant: myBondsMock },
        forceMock: false,
      })

      dispatch({
        type: SET_PARTICIPANTS,
        payload: { participants: [respBody.participant] },
      })
      dispatch({ type: GET_EVENT_PARTICIPANT })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      dispatch({
        type: GET_EVENT_PARTICIPANT_ERROR,
        payload: {
          error: errorMsg || '',
        },
      })
    }
  },
)

export const getAdminEventInfo = createCustomAsyncThunk(
  'event/getAdminEventInfo',
  async (eventId: string, { dispatch }) => {
    try {
      dispatch({ type: GET_ADMIN_EVENT_INFO_LOADING })

      const respBody = await vincufyApiCall<{
        adminEventInfo: TAdminEventInfoModel
        participants: TParticipantDetailModel[]
      }>({
        method: HTTP_METHOD.GET,
        endpoint: `/event/admin-info/${eventId}`,
        body: {},
        mock: {
          adminEventInfo: adminPanelMock,
          participants: participantsMock,
        },
        forceMock: false,
      })

      dispatch({
        type: SET_PARTICIPANTS,
        payload: {
          participants: respBody.participants.map((p) => ({
            ...p,
            bond: undefined,
          })),
        },
      })
      dispatch({
        type: GET_ADMIN_EVENT_INFO,
        payload: {
          adminEventInfo: respBody.adminEventInfo,
        },
      })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      dispatch({
        type: GET_ADMIN_EVENT_INFO_ERROR,
        payload: {
          error: errorMsg || '',
        },
      })
    }
  },
)

export const updateParticipantStatusAction = createCustomAsyncThunk(
  'event/updateParticipantStatusAction',
  async (
    {
      participantId,
      newStatus,
      prevStatus,
      ticketTypeId,
    }: {
      participantId: string
      newStatus: ParticipantStatus
      prevStatus: ParticipantStatus
      ticketTypeId: string
    },
    { dispatch },
  ) => {
    try {
      dispatch({ type: SET_PARTICIPANT_STATUS_LOADING })

      const respBody = await vincufyApiCall<{
        participants: TParticipantModel[]
        eventTicketTypeId: string
        ticketsGiven: number
      }>({
        method: HTTP_METHOD.PATCH,
        endpoint: `/event/participant/${participantId}`,
        body: {
          status: newStatus,
          prevStatus,
          eventTicketTypeId: ticketTypeId,
        },
        mock: {
          participants: [
            {
              id: participantsMock[0].participantId,
              userId: participantsMock[0].userId,
              name: participantsMock[0].name,
              lastName: participantsMock[0].lastName,
              userProfileId: participantsMock[0].userProfileId,
              profilePicture: participantsMock[0].profilePictures[0],
              eventId: participantsMock[0].eventId,
              ticketId: participantsMock[0].ticketId,
              status: participantsMock[0].status,
            },
          ],
          eventTicketTypeId: '123',
          ticketsGiven: 6,
        },
        forceMock: false,
      })

      const participantsToUpdate: Partial<TParticipantDetailModel>[] =
        respBody.participants.map((part) => ({
          participantId: part.id,
          userId: part.userId,
          name: part.name,
          lastName: part.lastName,
          userProfileId: part.userProfileId,
          profilePictures: [part.profilePicture, '', ''],
          eventId: part.eventId,
          ticketId: part.ticketId,
          status: part.status,
        }))

      dispatch({
        type: SET_PARTICIPANTS,
        payload: {
          participants: participantsToUpdate,
        },
      })
      dispatch({
        type: SET_PARTICIPANT_STATUS,
        payload: {
          participants: respBody.participants,
          ticketTypeId,
          updatedTicketsGiven: respBody.ticketsGiven,
          newStatus,
          prevStatus,
        },
      })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      dispatch({
        type: SET_PARTICIPANT_STATUS_ERROR,
        payload: {
          error: errorMsg || '',
        },
      })
    }
  },
)
