import { View } from 'react-native'
import { getScreenLayoutStyles } from './ScreenLayoutStyles'
import Header from '../Header/Header'
import { useAppSelector } from '@/redux/store'
import { ReactNode } from 'react'

export default function ScreenLayout({
  goBackFunc = null,
  children,
}: {
  goBackFunc?: null | (() => boolean)
  children: ReactNode
}) {
  const shouldHideTabBar = useAppSelector(
    (state) => state.user.shouldHideTabBar,
  )
  const styles = getScreenLayoutStyles(shouldHideTabBar)
  return (
    <View style={styles.screenLayout}>
      <Header goBackFunc={goBackFunc} />
      {children}
    </View>
  )
}
