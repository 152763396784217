import { StyleSheet, Dimensions } from 'react-native'

const { width } = Dimensions.get('window');
const isLargeScreen = width >= 768;

export default StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    width: isLargeScreen ? width * 0.3 : width * 0.9,
    minHeight: '22%',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 30,
  },
  backgroundImage: {
    borderRadius: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    gap: 20,
    marginBottom: 20
  },
  text: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
    lineHeight: 24,
    paddingHorizontal: 5,
    paddingTop: 15
  },
  btn: {
    backgroundColor: '#9459CE',
    paddingVertical: 10,
    // paddingHorizontal: 20,
    borderRadius: 5,
    width: 110,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 10,
  },
  btnText: {
    color: 'white',
    fontSize: 14,
  },
  btnIcon: {
    height: 13,
    width: 13,
  },
  imgInfo: {
    height: 70,
    width: 70,
  },
})
