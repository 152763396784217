import { useEffect, useState } from 'react'
import { Text, View } from 'react-native'
import { CustomPressable, Loader, ScreenLayout } from '@/components'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import ParticipantsGrid from './components/ParticipantsGrid/ParticipantsGrid'
import PreVirtualRoom from './components/PreVirtualRoom/PreVirtualRoom'
import { setShouldHideTabBar } from '@/redux/thunks'
import { VirtualRoomNavigatorParamList } from 'routes/Navigators'
import styles from './EventVirtualRoomStyles'
import { getUrlParams, navigateTo, useAppRouteParams } from 'routes/utils'
import { useEventBondsController } from './components/EventBonds/controllers/useEventBondsController'
import { goBackTo } from 'routes/utils/goBackTo'

const EventVirtualRoom = () => {
  const dispatch = useAppDispatch()
  const navParams = useAppRouteParams<
    VirtualRoomNavigatorParamList,
    'EventVirtualRoom'
  >()
  const [routeParams, setRouteParams] = useState<
    Partial<VirtualRoomNavigatorParamList['EventVirtualRoom']>
  >({})
  const params = {
    eventId: navParams?.eventId || routeParams?.eventId || '',
  }
  useEffect(() => {
    const [urlParamEventId] = getUrlParams('EventVirtualRoom')

    setRouteParams({
      eventId: urlParamEventId,
    })
  }, [])

  const { eventBondsRequest } = useEventBondsController(params.eventId)

  useEffect(() => {
    dispatch(setShouldHideTabBar(true))
  }, [])

  const userEvent = useAppSelector((state) =>
    state.event.userEvents.find((event) => event.eventId === params.eventId),
  )

  const [remainingSecondsToStart, setRemainingSecondsToStart] = useState<
    number | null
  >(null)
  const virtualRoomStarted =
    !remainingSecondsToStart || remainingSecondsToStart <= 0
  const eventName =
    userEvent?.eventName && userEvent.eventName.length > 26
      ? `${userEvent?.eventName.slice(0, 26)}...`
      : userEvent?.eventName

  const updateRemainingSeconds = (): null | number => {
    if (userEvent?.virtualRoomStartAt) {
      const totalSeconds = Math.floor(
        (new Date(userEvent.virtualRoomStartAt).getTime() -
          new Date().getTime()) /
          1000,
      )

      setRemainingSecondsToStart(totalSeconds)
      return totalSeconds
    }
    return null
  }

  useEffect(() => {
    let updateRemainingSecondsInterval: undefined | NodeJS.Timeout
    if (userEvent?.virtualRoomStartAt) {
      const totalSeconds = updateRemainingSeconds()

      if (totalSeconds !== null && totalSeconds > 0) {
        updateRemainingSecondsInterval = setInterval(
          updateRemainingSeconds,
          1000,
        ) // Verificamos cada segundo si ya comenzó la sala
      }
    }

    return () => clearInterval(updateRemainingSecondsInterval) // Limpiamos el intervalo cuando el componente se desmonte
  }, [userEvent?.virtualRoomStartAt])

  // useEffect(() => {
  //   if (params?.eventId && userEvent?.eventId !== params?.eventId) {
  //     dispatch(getEventDetail(params.eventId))
  //   }
  // }, [params?.eventId, userEvent])

  if (!userEvent) return <Loader />

  return (
    <ScreenLayout goBackFunc={() => goBackTo('MyEvents', {})}>
      {virtualRoomStarted && (
        <View style={styles.buttonContainer}>
          <Text style={styles.eventName}>{eventName}</Text>
          <CustomPressable
            onPress={() =>
              navigateTo('EventBonds', {
                eventId: params?.eventId || '',
              })
            }
            disabled={!virtualRoomStarted}
          >
            <View style={styles.bondRequestBtn}>
              <Text style={styles.buttonText}>Mis solicitudes</Text>
              {!!eventBondsRequest?.length && (
                <View style={styles.bondRequestNoti} />
              )}
            </View>
          </CustomPressable>
        </View>
      )}
      {virtualRoomStarted ? (
        <ParticipantsGrid eventId={userEvent.eventId} />
      ) : (
        <PreVirtualRoom
          remainingSecondsToStart={remainingSecondsToStart}
          userEvent={userEvent}
        />
      )}
    </ScreenLayout>
  )
}

export default EventVirtualRoom
