import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  labelInfoTickets: {
    flexDirection: 'row',
    marginVertical: 10,
    marginHorizontal: 15,
  },
  labelText: {
    color: 'white',
    fontSize: 15,
    flex: 1,
    textAlign: 'center',
  },
  infoTickets: {
    backgroundColor: '#2E2E2E',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 15,
    height: 35,
    borderRadius: 15,
  },
  currency: {
    color: 'white',
    width: '100%',
    fontSize: 17,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 2,
  },
  quantity: {
    color: 'white',
    fontSize: 17,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 2,
    width: '100%',
  },
  line: {
    width: 1,
    backgroundColor: '#636363',
    height: '80%',
  },
})

export default styles
