import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  shadow: {
    height: '100%',
    width: 45,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 3,
  },
  status: {
    height: '100%',
    width: '100%',
    borderRadius: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textStatus: {
    transform: [{ rotate: '-90deg' }],
    color: 'white',
    width: 95,
    fontWeight: '400',
    textAlign: 'center',
    fontSize: 12.5,
  },
})

export default styles
