import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  bckgImg: {
    width: '100%',
    height: 60,
    justifyContent: 'center',
  },
  background: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bckgImgIos: {
    width: '100%',
    height: 100,
  },
  logo: {
    width: 86,
    height: 20,
  },
  iconButton: {
    marginLeft: 22,
    position: 'absolute',
    zIndex: 9999,
    top: 20,
  },
  icon: {
    height: 18,
    width: 18,
  },
})
