import { View, ScrollView } from 'react-native'
import {
  Carousel,
  InfoCard,
  UserDataInputs,
  ModalProfileSettings,
  Loader,
  InfoModal,
  ScreenLayout,
  ExitWhileEditingProfileModal,
  CustomPressable,
} from '@/components'
import styles from './MyProfileStyles'
import { useMyProfileController } from './controllers/MyProfileController'
import CustomRefreshControl from '@/components/CustomRefreshControl/CustomRefreshControl'
import { useState } from 'react'
import settings from '@/assets/Profile/ajustes.png'
import editInfoIcon from '@/assets/Profile/editInfo1.png'
import { Image, Text } from 'react-native-web'
import useKeyboardVisible from '@/utils/UseKeyboardVisible'
import { theme } from '@/theme'

const MyProfile = () => {
  const {
    editableUserInfo,
    updateEditableUserInfo,
    isEditingProfile,
    editProfile,
    isLoading,
    submitEditedUser,
    isSubmitingEditedUser,
    cancelEditProfile,
    refreshMyProfile,
  } = useMyProfileController()

  const [openSettingsModal, setOpenSettingsModal] = useState(false)
  const { isKeyboardVisible } = useKeyboardVisible()

  return (
    <ScreenLayout>
      {isLoading ? (
        <Loader />
      ) : (
        <ScrollView
          showsVerticalScrollIndicator={false}
          refreshControl={<CustomRefreshControl onRefresh={refreshMyProfile} />}
        >
          <>
            {!isKeyboardVisible && (
              <Carousel
                images={editableUserInfo?.profilePictures}
                isEditingProfile={isEditingProfile}
              />
            )}
            {!isEditingProfile ? (
              <>
                <View style={styles.btnsContainer}>
                  <CustomPressable
                    onPress={editProfile}
                    style={theme.btnTertiary}
                  >
                    <Image source={editInfoIcon} style={styles.editIcon} />
                    <Text style={theme.btnText}>Editar</Text>
                  </CustomPressable>
                  <CustomPressable
                    onPress={() => setOpenSettingsModal(true)}
                    style={theme.btnTertiary}
                  >
                    <Image source={settings} style={styles.settingsIcon} />
                    <Text style={theme.btnText}>Ajustes</Text>
                  </CustomPressable>
                </View>
                <InfoCard userInfo={editableUserInfo} />
                <ModalProfileSettings
                  openSettingsModal={openSettingsModal}
                  setOpenSettingsModal={setOpenSettingsModal}
                />
              </>
            ) : (
              <>
                <View style={styles.btnsContainer}>
                  <CustomPressable
                    onPress={cancelEditProfile}
                    style={theme.btnTertiary}
                  >
                    <Text style={theme.btnText}>Cancelar</Text>
                  </CustomPressable>
                  <CustomPressable
                    onPress={submitEditedUser}
                    style={theme.btnPrimary}
                    isLoading={isSubmitingEditedUser}
                  >
                    <Text style={theme.btnText}>Guardar</Text>
                  </CustomPressable>
                </View>
                <UserDataInputs
                  editableUserInfo={editableUserInfo}
                  updateEditableUserInfo={updateEditableUserInfo}
                />
              </>
            )}
          </>
        </ScrollView>
      )}
      <InfoModal />
      <ExitWhileEditingProfileModal />
    </ScreenLayout>
  )
}

export default MyProfile
