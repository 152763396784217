import { ISODateString } from '../utils.model'

export type EventType = 'EVENT' | 'COMMUNITY'
export const EventType = {
  EVENT: 'EVENT',
  COMMUNITY: 'COMMUNITY',
} as const

export type EventVisualizationType = 'PUBLIC' | 'PRIVATE'
export const EventVisualizationType = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
} as const

export type TEventModel = {
  id: string
  type: EventType
  name: string
  flyer: string | null
  description: string | null
  startAt: ISODateString
  endAt: ISODateString
  mercadoPagoToken: string | null
  location: null | {
    id: string
    name: string
  }
  visualization: EventVisualizationType
  ticketsLimit: number
  userTicketsLimit: number
}
