import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  backgroundImage: {
    flex: 1,
    justifyContent: 'center',
    zIndex: 99999,
  },
  gral: {
    flexGrow: 1,
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  logoVincufy: {
    marginTop: '15%',
    height: 60,
    width: '100%',
  },
  registerButton: {
    width: '100%',
    height: 45,
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    fontSize: 15,
    marginTop: 5,
  },
  inputDiv: {
    marginTop: 40,
    marginBottom: '4%',
    width: '100%',
  },
  input: {
    backgroundColor: '#bfbfbf',
    borderRadius: 10,
    height: 40,
    fontSize: 14,
    textAlign: 'center',
    marginTop: 30,
    fontWeight: '600',
    color: 'black',
  },
  error: {
    color: 'rgb(215, 64, 64)',
    marginTop: 8,
    marginBottom: -10,
  },
  forgotPw: {
    color: 'white',
    fontWeight: '400',
  },
  registerDiv: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(197, 197, 197, 0.116)',
    height: 35,
    marginTop: '14%',
    paddingHorizontal: 20,
    borderRadius: 8,
    fontSize: 18,
    flexDirection: 'row',
    gap: 10,
  },
  registerText: {
    color: 'white',
    fontWeight: '500',
  },
  span: {
    color: 'white',
    fontWeight: '500',
    textDecorationLine: 'underline',
    marginLeft: 5,
  },
  underline: {
    textDecorationLine: 'none',
    marginTop: 20,
  },
  text: { color: 'white' },
  buttonText: {
    color: 'white',
  },
})
