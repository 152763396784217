import { StyleSheet, Dimensions } from 'react-native'
const { width } = Dimensions.get('window')

export default StyleSheet.create({
  input: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: 'black',
    borderRadius: 5,
    paddingVertical: '20%',
    borderColor: '#ccc',
    borderWidth: 0.5,
  },
  genderItem: {
    padding: 10,
    borderRadius: 5,
    paddingHorizontal: width * 0.3,
    marginHorizontal: 10,
  },
  genderText: {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    fontWeight: '300',
  },
  openButtonText: {
    marginHorizontal: 10,
    fontWeight: '600',
  },
})
