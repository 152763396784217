import { TParticipantModel } from './participant.model'

export type BondStatus = 'PENDING' | 'ACCEPTED' | 'REJECTED'
export const BondStatus = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
} as const

export type TBondModel = {
  id: string
  requesterId: TParticipantModel['id']
  receiverId: TParticipantModel['id']
  status: BondStatus
  eventId: string
}
