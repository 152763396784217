import { useState } from 'react'
import { View, Text } from 'react-native'
import { Header } from '../../../../../components'
import styles from './ContactMethodScreenStyles'
import { SelectContactMethodType } from '../../../../../components'
import { goBackTo } from 'routes/utils'

const ContactMethodScreen = () => {
  const [showModalSelect, setShowModalSelect] = useState(false)
  return (
    <>
      <Header goBackFunc={() => goBackTo('MyProfile', {})} />
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>
            Agregá tus métodos de contacto favoritos para que los nuevos
            vínculos puedan hablarte
          </Text>
          <SelectContactMethodType
            showModalSelect={showModalSelect}
            setShowModalSelect={setShowModalSelect}
          />
        </View>
      </View>
    </>
  )
}

export default ContactMethodScreen
