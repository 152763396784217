import { formatDate, formatDateToTime } from '@/utils'
import { View, Image, Text } from 'react-native'
import styles from './ParticipantFoundCardStyles'
import successIcon from '@/assets/MyEvents/success.png'
import errorIcon from '@/assets/MyEvents/error.png'
import { CustomPressable } from '@/components'
import { theme } from '@/theme'
import {
  fetchStatus,
  ParticipantStatus,
  TParticipantDetailModel,
} from '@/models'
import defaultPic from '@/assets/People/defaultProfilePic.png'
import { useAppSelector } from '@/redux/store'

const ParticipantFoundCard = ({
  participant,
  showResult = false,
  error,
  onScanTicket,
}: {
  showResult: boolean
  participant: TParticipantDetailModel | null
  error: string | null
  onScanTicket: () => void
}) => {
  const scanTicketLoading = useAppSelector(
    (state) => state.event.scanTicketStatus === fetchStatus.LOADING,
  )
  const scanTicketError = useAppSelector((state) => state.event.scanTicketError)

  return (
    <View style={styles.container}>
      {!showResult ? (
        <></>
      ) : (
        <View style={[styles.card, theme.bgGradientCenter]}>
          {participant ? (
            <>
              <View style={styles.imgContainer}>
                <Image
                  source={
                    participant.profilePictures[0]
                      ? { uri: participant.profilePictures[0] }
                      : defaultPic
                  }
                  style={styles.img}
                />
              </View>
              <Text style={styles.name}>
                {participant?.name} {participant?.lastName}
              </Text>
              {participant.birthDate && (
                <Text style={styles.dateOfBirth}>
                  {'Nacido el ' + formatDate(participant.birthDate)}
                </Text>
              )}
              {participant.age && (
                <Text style={styles.dateOfBirth}>
                  {participant.age}
                </Text>
              )}
              {!!participant.ticketScannedAt ? (
                <View style={styles.scannedTicket}>
                  <Image source={successIcon} style={styles.icon} />
                  <View style={styles.box}>
                    <Text style={styles.buttonText}>
                      Ingresó a las{' '}
                      {formatDateToTime(participant.ticketScannedAt)}
                    </Text>
                  </View>
                </View>
              ) : participant.status === ParticipantStatus.WITH_TICKET ? (
                <>
                  <CustomPressable
                    style={styles.btn}
                    onPress={onScanTicket}
                    isLoading={scanTicketLoading}
                  >
                    <Text style={styles.buttonText}>Confirmar ingreso</Text>
                  </CustomPressable>
                  {scanTicketError && (
                    <Text style={styles.errorText}>{scanTicketError}</Text>
                  )}
                </>
              ) : participant.status === ParticipantStatus.ACCEPTED ? (
                <View style={styles.scannedTicket}>
                  <Image source={errorIcon} style={styles.icon} />
                  <View style={styles.box}>
                    <Text style={styles.buttonText}>
                      Ticket confirmado con pago pendiente
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={styles.scannedTicket}>
                  <Image source={errorIcon} style={styles.icon} />
                  <View style={styles.box}>
                    <Text style={styles.buttonText}>
                      Ticket pendiente de confirmación
                    </Text>
                  </View>
                </View>
              )}
            </>
          ) : error ? (
            <Text style={styles.dateOfBirth}>{error}</Text>
          ) : (
            <Text style={styles.dateOfBirth}>
              No existe un ticket con ese código
            </Text>
          )}
        </View>
      )}
    </View>
  )
}

export default ParticipantFoundCard
