//Response para la pantalla My events
import {
  EventType,
  EventVisualizationType,
  ParticipantStatus,
  TUserEventModel,
  UserEventRole,
} from '@/models'

export const userEventsMock: TUserEventModel[] = [
  {
    flyer: null,
    eventId: '820dbbf1-314a-4130-a249-a1bf3439eacc',
    eventName: 'Fiesta de Sugus',
    type: EventType.EVENT,
    visualization: EventVisualizationType.PUBLIC,
    startDate: '2025-01-20T21:00:00.000Z',
    endDate: '2025-01-21T01:00:00.000Z',
    virtualRoomStartAt: '2025-01-20T18:00:00.000Z',
    virtualRoomEndAt: '2025-01-20T22:00:00.000Z',
    participantStatus: null,
    role: 'EVENT_TEAM',
  },
  {
    eventId: '18f473af-7f43-4b46-9cb1-a80e086cc722',
    eventName: 'ASETEC Community',
    type: EventType.COMMUNITY,
    visualization: EventVisualizationType.PUBLIC,
    flyer: null,
    startDate: '2024-12-26T21:00:00.000',
    endDate: '2024-12-27T05:00:00.000',
    virtualRoomStartAt: '2024-12-26T20:00:00.000', //Tabla virtual room
    virtualRoomEndAt: '2024-12-27T21:00:00.000', //Tabla virtual room
    participantStatus: ParticipantStatus.PENDING, //Tabla receipt
    role: UserEventRole.PARTICIPANT,
  },
  {
    eventId: 'f5f4d467-80ea-4bd0-9ea6-812ddc67be6f',
    type: EventType.EVENT,
    visualization: EventVisualizationType.PRIVATE,
    flyer: null,
    eventName: 'Fiesta de la Estación',
    startDate: '2024-12-26T21:00:00.000',
    endDate: '2024-12-27T05:00:00.000',
    virtualRoomStartAt: '2024-12-26T20:00:00.000', //Tabla virtual room
    virtualRoomEndAt: '2024-12-27T21:00:00.000', //Tabla virtual room
    participantStatus: ParticipantStatus.WITH_TICKET, //Tabla receipt
    role: UserEventRole.PARTICIPANT,
  },
]
