import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { ScreenLayout, CustomPressable } from '@/components'
import { View, Image, Text, ScrollView, ActivityIndicator } from 'react-native'
import { EventDetailNavigatorParamList } from 'routes/Navigators'
import { setShouldHideTabBar, getEventDetail } from '@/redux/thunks'
import { EventType, UserEventRole } from '@/models'
import { CommunityInfo, EventInfo, TicketSection } from './components'
import { shareContent } from '@/utils'
import { theme } from '@/theme'
import styles from './EventDetailStyles'
import publicIcon from '@/assets/MyEvents/public.png'
import privateIcon from '@/assets/MyEvents/private.png'
import defaultEventPic from '@/assets/MyEvents/defaultPicEvent.png'
import { goBackTo, useAppRouteParams } from 'routes/utils'
import share from '@/assets/MyEvents/compartir.png'

const EventDetail = () => {
  const navParams = useAppRouteParams<
    EventDetailNavigatorParamList,
    'EventDetail'
  >()
  const newEventId = navParams?.eventId || window.location.hash?.split('/')?.[2]
  const dispatch = useAppDispatch()
  const eventDetail = useAppSelector((state) => state.event.eventDetail)
  const isLogged = useAppSelector((state) => state.auth.isLogged)

  const userEventInfo = useAppSelector((state) =>
    state.event.userEvents.find((event) => event.eventId === newEventId),
  )

  const [eventHeaderInfo, setEventHeaderInfo] = useState<{
    eventName: string
    flyer: string | null
    type: EventType
    role: UserEventRole
    eventId: string
  } | null>(null)

  useEffect(() => {
    if (!newEventId) return
    if (userEventInfo?.eventId === newEventId && !eventHeaderInfo?.eventName) {
      setEventHeaderInfo({
        eventName: userEventInfo.eventName,
        flyer: userEventInfo.flyer,
        type: userEventInfo.type,
        role: userEventInfo.role,
        eventId: userEventInfo.eventId,
      })
    }
  }, [newEventId, userEventInfo, eventHeaderInfo])

  const fetchEventDetail = () => {
    if (!newEventId) return
    dispatch(getEventDetail(newEventId))
  }

  useEffect(() => {
    if (!newEventId) return
    if (eventDetail?.eventId !== newEventId) {
      fetchEventDetail()
    } else {
      if (!eventHeaderInfo?.eventName) {
        setEventHeaderInfo({
          eventName: eventDetail.eventName,
          flyer: eventDetail.flyer,
          type: eventDetail.type,
          role: eventDetail.role,
          eventId: eventDetail.eventId,
        })
      }
    }
    dispatch(setShouldHideTabBar(true))
  }, [newEventId, eventDetail, eventHeaderInfo?.eventName])

  const isLoadingPage = !newEventId || eventDetail?.eventId !== newEventId

  const goBackFunc = !isLogged
    ? null
    : eventHeaderInfo?.role === UserEventRole.EVENT_TEAM
      ? () => goBackTo('AdminPanel', { eventId: eventHeaderInfo?.eventId })
      : () => goBackTo('MyEvents', {})

  return (
    <ScreenLayout goBackFunc={goBackFunc}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.container}>
          <View style={styles.flyerDiv}>
            <Image
              style={styles.flyer}
              source={
                eventHeaderInfo?.flyer
                  ? { uri: eventHeaderInfo.flyer }
                  : defaultEventPic
              }
              alt="flyer"
              resizeMode="cover"
            />
          </View>
          <View style={styles.titleIconRow}>
            <Text style={styles.titleEvent}>
              {eventHeaderInfo?.eventName || '...'}
            </Text>
            {!!eventDetail?.eventTicketTypes[0] && (
              <Image
                style={styles.icon}
                source={
                  eventDetail.eventTicketTypes[0]?.hasTicketsRequest
                    ? privateIcon
                    : publicIcon
                }
                alt="icon"
              />
            )}
          </View>
          {isLoadingPage ? (
            <ActivityIndicator
              size="small"
              color="#ffffff"
              style={{ marginTop: 40 }}
            />
          ) : (
            <>
              {eventDetail.eventProducers.map((eventProducer) => (
                <Text key={eventProducer.name} style={styles.eventProducerName}>
                  {eventProducer.name}
                </Text>
              ))}
              <View style={styles.buttonContainers}>
                <TicketSection
                  eventTicketTypes={eventDetail.eventTicketTypes}
                  participantStatus={eventDetail.participantStatus}
                  eventId={eventDetail.eventId}
                  eventType={eventDetail.type}
                />
                <CustomPressable
                  style={styles.mainButtons}
                  onPress={() =>
                    shareContent(eventDetail?.eventName, eventDetail?.eventId)
                  }
                >
                  <View
                    style={[styles.buttonBackground, theme.bgGradientCenter]}
                  >
                    <Image source={share} style={styles.icon} />
                  </View>
                </CustomPressable>
              </View>
              {eventDetail.type === EventType.COMMUNITY ? (
                <CommunityInfo description={eventDetail.description} />
              ) : (
                <EventInfo
                  startDate={eventDetail.startDate}
                  endDate={eventDetail.endDate}
                  virtualRoomStartAt={eventDetail.virtualRoomStartAt}
                  virtualRoomEndAt={eventDetail.virtualRoomEndAt}
                  locationName={eventDetail.location?.name}
                  description={eventDetail.description}
                />
              )}
            </>
          )}
        </View>
      </ScrollView>
    </ScreenLayout>
  )
}

export default EventDetail
