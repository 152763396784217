import vincufyApi, { HTTP_METHOD, vincufyApiCall } from '@/config/vincufyApi'
import { logged } from '../actions/auth.action'
import {
  AUTH_LOGOUT,
  BOND_LOGOUT,
  EVENT_LOGOUT,
  SET_USER,
  USER_LOGOUT,
} from '../constants'
import { createCustomAsyncThunk } from '../store/createCustomAsyncThunk'
import { userAuthMock } from '@/mocks/Users/userAuthMock'
import {
  TJwtUserAuthModel,
  TUserAuthModel,
  TUserModel,
  TUserProfileModel,
} from '@/models'
import { userInfoMock } from '@/mocks'
import { TFullRegisterModel } from '@/models/full-register.model'
import { TLoggedOrigin } from '../reducers/auth.reducer'

export const cleanStore = createCustomAsyncThunk(
  'auth/cleanStore',
  async (_: void, { dispatch }) => {
    dispatch({ type: AUTH_LOGOUT })
    dispatch({ type: USER_LOGOUT })
    dispatch({ type: EVENT_LOGOUT })
    dispatch({ type: BOND_LOGOUT })
  },
)

export const verifySession = createCustomAsyncThunk(
  'auth/verifySession',
  async (_: void, { dispatch }) => {
    const respBody = await vincufyApiCall<{ jwtUserAuth: TJwtUserAuthModel }>({
      method: HTTP_METHOD.POST,
      endpoint: `/auth/verify-session`,
      body: {},
      mock: false,
      forceMock: false,
    })

    dispatch(
      logged({
        id: respBody.jwtUserAuth.userAuthId,
        userId: respBody.jwtUserAuth.userId,
        userName: respBody.jwtUserAuth.userName,
        email: respBody.jwtUserAuth.email,
      }),
    )
  },
)

export const register = createCustomAsyncThunk(
  'auth/register',
  async (
    {
      email,
      password,
      fromFunctionality,
    }: {
      email: string
      password: string
      fromFunctionality?: TLoggedOrigin
    },
    { dispatch },
  ) => {
    try {
      const respBody = await vincufyApiCall<{ userAuth: TUserAuthModel }>({
        method: HTTP_METHOD.POST,
        endpoint: `/auth/register`,
        body: {
          email,
          password,
        },
        mock: { userAuth: userAuthMock },
        forceMock: false,
      })

      dispatch(logged(respBody.userAuth, fromFunctionality))
    } catch (err) {
      console.warn('ERROR', err)
      // if (error.response?.status === 400) {
      //     throw new Error('Usuario o contraseña incorrectos')
      // }

      throw err
    }
  },
)

export const fullRegister = createCustomAsyncThunk(
  'user/fullRegister',
  async (
    {
      newUser,
      fromFunctionality,
    }: {
      newUser: TFullRegisterModel
      fromFunctionality?: TLoggedOrigin
    },
    { dispatch },
  ) => {
    try {
      if (!(Object.keys(newUser).length > 0)) throw new Error('No newUser')

      const respBody = await vincufyApiCall<{
        user: TUserModel
        userProfile: TUserProfileModel
        userAuth: TUserAuthModel
      }>({
        method: HTTP_METHOD.POST,
        endpoint: `/full-register`,
        body: newUser,
        mock: {
          user: userInfoMock.user,
          userProfile: userInfoMock.userProfiles[0],
          userAuth: userAuthMock,
        },
        forceMock: false,
      })

      dispatch({
        type: SET_USER,
        payload: {
          user: respBody.user,
          userProfiles: [respBody.userProfile],
        },
      })

      dispatch(logged(respBody.userAuth, fromFunctionality))

      return respBody.user
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn('ERROR fullRegister:', errorMsg)
      throw err
    }
  },
)

export const login = createCustomAsyncThunk(
  'auth/login',
  async (
    {
      email,
      password,
      fromFunctionality,
    }: {
      email: string
      password: string
      fromFunctionality?: TLoggedOrigin
    },
    { dispatch },
  ) => {
    try {
      const respBody = await vincufyApiCall<{ userAuth: TUserAuthModel }>({
        method: HTTP_METHOD.POST,
        endpoint: `/auth/login`,
        body: {
          email,
          password,
        },
        mock: { userAuth: userAuthMock },
        forceMock: false,
      })

      dispatch(logged(respBody.userAuth, fromFunctionality))
    } catch (err) {
      console.warn('ERROR', err)
      // if (error.response?.status === 400) {
      //     throw new Error('Usuario o contraseña incorrectos')
      // }

      throw err
    }
  },
)

export const logout = createCustomAsyncThunk(
  'auth/logout',
  async (_: void, { dispatch }) => {
    try {
      await vincufyApiCall({
        method: HTTP_METHOD.POST,
        endpoint: `/auth/logout`,
        body: {},
        mock: false,
        forceMock: false,
      })
    } catch (err) {
      console.warn('Error in logout:', err)
    }

    vincufyApi.defaults.headers.common.Authorization = undefined
    dispatch(cleanStore())
  },
)
