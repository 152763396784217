import { useState, useEffect, useRef } from 'react'
import { useInitialAuth } from './useInitialAuth'
import { useInitialFetch } from './useInitialFetch'
import { useInitialNotificationSetup } from './useInitialNotificationSetup'
import { isDesktop } from '@/utils'

export const useInitialLogic = () => {
  const {
    checkIfHasActiveSession,
    isLogged,
    userId,
    isLoadingInitialAuth,
    isPostLoggedFlow,
    loggedOrigin,
  } = useInitialAuth()
  const {
    startInitialNotificationSetup,
    notificationSetupFinished,
    isLoadingInitialNotificationSetup,
  } = useInitialNotificationSetup()
  const { startInitialFetch, isLoadingInitialFetch } = useInitialFetch()

  const isFirstAppLoad = useRef(true)
  const [hasActiveSession, setHasActiveSession] = useState(false)
  const [isRegistrationIncomplete, setIsRegistrationIncomplete] = useState(true)

  const loadingLogic = () => {
    if (isLoadingInitialAuth) return true
    if (
      hasActiveSession &&
      !isRegistrationIncomplete &&
      isLoadingInitialNotificationSetup
    )
      return true
    if (notificationSetupFinished && isLoadingInitialFetch) return true
    return false
  }

  const isLoadingInitialLogic = loadingLogic()

  useEffect(() => {
    if (isFirstAppLoad.current && !isDesktop()) {
      isFirstAppLoad.current = false

      checkIfHasActiveSession()
    }
  }, [isFirstAppLoad])

  useEffect(() => {
    if (!isLogged && hasActiveSession) {
      setHasActiveSession(false)
      setIsRegistrationIncomplete(true)
      return
    }

    if (isLogged && !hasActiveSession) {
      setHasActiveSession(true)
    }

    if (isLogged && !!userId && isRegistrationIncomplete) {
      setIsRegistrationIncomplete(false)

      startInitialFetch()
      startInitialNotificationSetup()
    }
  }, [isLogged, userId, hasActiveSession, isRegistrationIncomplete])

  return {
    isLoadingInitialLogic,
    hasActiveSession,
    isPostLoggedFlow,
    loggedOrigin,
    isRegistrationIncomplete,
    notificationSetupFinished,
  }
}
