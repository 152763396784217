import { StyleSheet } from 'react-native-web'

export default StyleSheet.create({
  datePickerContainer: {
    width: '100%',
    alignItems: 'center',
  },
  datePickerInput: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    height: 40,
    width: '100%',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    backgroundColor: '#bfbfbf',
  },
  datePickerLabel: {
    color: 'black',
    fontWeight: '600',
    fontSize: 14,
    minWidth: 'fit-content',
  },
  datePickerDivider: {
    height: '100%',
    width: 2,
    backgroundColor: 'black',
    marginLeft: 8,
  },
  datePickerInputField: {
    minWidth: 120,
    width: '100%',
    height: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    color: 'black',
    fontSize: 14,
    fontWeight: '600',
    textAlign: 'center',
    cursor: 'pointer',
  },
  calendarIconContainer: {
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
  },
})
