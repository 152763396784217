import { View, ScrollView, Text } from 'react-native'
import { CustomRefreshControl, Loader, ScreenLayout } from '@/components'
import { useMyEventsController } from './controllers/useMyEventsController'
import styles from './MyEventsStyles'
import EventCard from './components/EventCard/EventCard'
import { ErrorScreen } from '@/components/ErrorScreen/ErrorScreen'
import { useEffect } from 'react'
import { setShouldHideTabBar } from '@/redux/thunks'
import { useAppDispatch } from '@/redux/store'
import { postLoggedFlowFinished } from '@/redux/actions'

const MyEvents = () => {
  const { userEvents, isLoading, backError, fetchUserEvents } =
    useMyEventsController()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setShouldHideTabBar(false))
    dispatch(postLoggedFlowFinished())
  }, [])

  return (
    <ScreenLayout>
      {isLoading ? (
        <Loader />
      ) : backError ? (
        <ErrorScreen />
      ) : (
        <>
          {!userEvents || !userEvents.length ? (
            <View style={styles.emptyContainer}>
              <Text style={styles.emptyText}>
                Todavía no tenés ningun evento próximo
              </Text>
            </View>
          ) : (
            <ScrollView
              style={styles.container}
              showsVerticalScrollIndicator={false}
              refreshControl={
                <CustomRefreshControl onRefresh={fetchUserEvents} />
              }
            >
              <View style={styles.cardEventContainer}>
                {userEvents?.map((event) => (
                  <EventCard userEvent={event} key={event.eventId} />
                ))}
              </View>
            </ScrollView>
          )}
        </>
      )}
    </ScreenLayout>
  )
}

export default MyEvents
