import { View, Text, Image, Linking } from 'react-native'
import { useState } from 'react'
import { ButtonList, CustomPressable } from '@/components'
import { shareContent } from '@/utils'
import styles from './HeaderButtonsStyles'
import share from '@/assets/MyEvents/compartir.png'
import { theme } from '@/theme'
import { navigateTo } from 'routes/utils'

const HeaderButtons = ({ eventName, eventType, eventId }) => {
  const [isOpenCollapse, setIsOpenCollapse] = useState(false)

  // Opciones del botón "Herramientas"
  const options = [
    ...(eventType === 'EVENT'
      ? [
          {
            text: 'Control de tickets',
            action: () => {
              navigateTo('TicketControl', {
                eventId,
              })
              setIsOpenCollapse(false)
            },
          },
        ]
      : []),
    {
      text: 'Aumentar cupos',
      action: () => {
        Linking.openURL(
          `https://wa.me/${'5492214773939'}?text=${encodeURIComponent(`Hola! Me gustaría obtener más cupos para mi evento: ${eventName}.`)}`,
        )
        setIsOpenCollapse(false)
      },
    },
  ]

  return (
    <View style={styles.buttonContainer}>
      <ButtonList
        text={'Herramientas'}
        options={options}
        isOpenCollapse={isOpenCollapse}
        setIsOpenCollapse={setIsOpenCollapse}
      />
      <CustomPressable
        style={styles.mainButtons}
        onPress={() => shareContent(eventName, eventId)}
      >
        <View style={[styles.buttonBackground, theme.bgGradientCenter]}>
          <Image source={share} style={styles.icon} />
          <Text style={styles.buttonText}>Compartir</Text>
        </View>
      </CustomPressable>
    </View>
  )
}

export default HeaderButtons
