import { TUserAuthModel } from '@/models'
import {
  LOADING,
  LOGGED,
  AUTH_LOGOUT,
  POST_LOGGED_FLOW_FINISHED,
} from '@/redux/constants'

export type TLoggedOrigin = {
  functionality: '' | 'requestTickets'
  params: any
}

const initialState: {
  isLogged: boolean
  isPostLoggedFlow: boolean
  loggedOrigin: TLoggedOrigin
  userAuth:
    | TUserAuthModel
    | {
        id: null
        userId: null
        email: null
        userName: null
      }
  isLoading: boolean
} = {
  isLogged: false,
  isPostLoggedFlow: false,
  loggedOrigin: {
    functionality: '',
    params: {},
  },
  userAuth: {
    id: null,
    userId: null,
    email: null,
    userName: null,
  },
  isLoading: true,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: action.payload as boolean,
      }
    case LOGGED:
      return {
        ...state,
        isLogged: true,
        isPostLoggedFlow: true,
        userAuth: action.payload.userAuth as TUserAuthModel,
        loggedOrigin: {
          functionality:
            (action.payload.origin
              ?.functionality as TLoggedOrigin['functionality']) || '',
          params:
            (action.payload.origin?.params as TLoggedOrigin['params']) || {},
        },
        isLoading: false,
      }
    case POST_LOGGED_FLOW_FINISHED:
      return {
        ...state,
        loggedOrigin: {
          functionality: '',
          params: {} as TLoggedOrigin['params'],
        },
        isPostLoggedFlow: false,
      }
    case AUTH_LOGOUT:
      return initialState
    default:
      return state
  }
}

export default authReducer
