import { useState } from 'react'
import { useAppDispatch } from '@/redux/store'
// import {
//   getEventParticipantsAction,
//   getUserEvents,
// } from '@/redux/thunks/event.thunk'
import { fetchLog } from '@/utils/devLog'
import { getUserDB, getUserBondsAction, getUserEvents } from '@/redux/thunks'

export const useInitialFetch = () => {
  const dispatch = useAppDispatch()
  const [isLoadingInitialFetch, setIsLoadingInitialFetch] = useState(true)

  const startInitialFetch = async () => {
    setIsLoadingInitialFetch(true)
    try {
      fetchLog('Starting...')

      await Promise.allSettled([
        dispatch(getUserDB())
          .unwrap()
          .then(() => fetchLog('User Ready.')),
        dispatch(getUserEvents())
          .unwrap()
          .then(() => fetchLog('Events Ready.')),
        dispatch(getUserBondsAction())
          .unwrap()
          .then(() => fetchLog('Bonds Ready.')),
      ])

      // if (userEventsData?.selectedEventId) {
      //   await getEventParticipantsAction(
      //     userEventsData.selectedEventId,
      //     1,
      //     userId,
      //     bonds,
      //     dispatch,
      //   )
      //   fetchLog('Guets Ready.')
      // }

      fetchLog('finished.')
    } catch (error) {
      throw new Error('Error -> Initial Fetch.' + error)
    } finally {
      setIsLoadingInitialFetch(false)
    }
  }

  return {
    startInitialFetch,
    isLoadingInitialFetch,
  }
}
