import { ISODateString } from '@/models'

//Esta función se utiliza para el control de tickets
export const formatDateToTime = (isoDate: ISODateString) => {
  const date = new Date(isoDate)
  let hours = date.getHours()
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const amPm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12 || 12

  return `${hours}:${minutes} ${amPm}`
}

export const formatDate = (isoDate?: ISODateString) => {
  if (!isoDate) return { date: '', time: '' }
  const date = new Date(isoDate)
  const formattedDate = date.toLocaleDateString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
  return formattedDate //Ejemplo: "02/04/1997"
}

//Esta función se utiliza para EventDetail
export const formatDateAndTime = (isoString) => {
  const date = new Date(isoString);

  // Formateamos el día y el mes usando Intl.DateTimeFormat
  const formattedDay = new Intl.DateTimeFormat('es-ES', { day: 'numeric' }).format(date);
  const formattedMonth = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(date);
  const formattedYear = new Intl.DateTimeFormat('es-ES', { year: 'numeric' }).format(date);

  // Creamos la fecha con el formato que queremos
  const formattedDate = `${formattedDay} de ${formattedMonth}, ${formattedYear}`;

  // Formateamos la hora
  const formattedTime = date.toLocaleTimeString('es-ES', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  return {
    date: formattedDate, // "26 de septiembre, 2024"
    time: formattedTime,  // Ejemplo: "20:37"
  };
};



