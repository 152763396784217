import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 8,
    overflow: 'hidden',
    marginHorizontal: 20,
    marginTop: 40,
  },
  header: {
    padding: 15,
  },
  headerTouchable: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: {
    fontSize: 16,
    color: 'white',
    marginBottom: 2,
  },
  arrow: {
    width: 20,
    height: 20,
  },
  content: {
    overflow: 'hidden',
  },
})
