// import { StyleSheet } from 'react-native-web'
import { colors } from './colors'

const btnText = {
  color: 'white',
  // fontWeight: 600,
  fontSize: '12px',
  lineHeight: '12px',
}

const btnBase = {
  height: '30px', // small button
  width: '110px',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '7px',
  padding: '10px',
  gap: 10,

  ...btnText,
}

const btnBig = {
  height: '40px',
  width: '100%',
  paddingHorizontal: '16px',
}

export const theme = {
  input: {
    height: 40,
    width: '100%',
    backgroundColor: colors.inputBg,
    fontSize: 14,
    fontWeight: 600,
    color: 'black',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 5,
  },
  inputAuth: {
    height: 40,
    width: '100%',
    maxWidth: 343,
    backgroundColor: colors.inputBg,
    fontSize: 14,
    fontWeight: 600,
    color: 'black',
    borderRadius: 10,
    textAlign: 'center',
    marginTop: 25,
  },
  btnPrimary: {
    ...btnBase,
    backgroundColor: colors.primary,
    backgroundImage: 'none',
  },
  btnSecondary: {
    ...btnBase,
    backgroundColor: 'black', // Color sólido
    backgroundImage: 'none',
  },
  btnTertiary: {
    ...btnBase,
    background:
      'linear-gradient(to right, rgb(25, 25, 25) 10%, rgb(45, 45, 45) 25%, rgb(55, 55, 55) 36%, 43%, rgb(65, 65, 65) 46%, 54%, rgb(55, 55, 55) 57%, 64%, rgb(45, 45, 45) 75%, rgb(25, 25, 25) 90%)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  btnReturn: {
    ...btnBase,
    backgroundColor: 'black',
    borderWidth: '1px',
    borderColor: colors.gray,
  },
  bgGradientCenter: {
    background:
      'linear-gradient(to right, rgb(25, 25, 25) 10%, rgb(45, 45, 45) 25%, rgb(55, 55, 55) 36%, 43%, rgb(65, 65, 65) 46%, 54%, rgb(55, 55, 55) 57%, 64%, rgb(45, 45, 45) 75%, rgb(25, 25, 25) 90%)',
  } as any,
  bgGradientRight: {
    background:
      'linear-gradient(to right, rgba(28, 28, 28, 1) 0%, 12.191510365251727%, rgba(44, 42, 42, 1) 24.383020730503453%, 36.87068114511352%, rgba(60, 57, 57, 1) 49.3583415597236%, 61.84600197433367%, rgba(81, 79, 79, 1) 74.33366238894374%, 87.16683119447187%, rgba(102, 102, 102, 1) 100%)',
  } as any,
  bgGradientRightCenter: {
    background:
      'linear-gradient(to right, rgba(25, 25, 25, 1) 0%, 22.556762092793683%, rgba(86, 86, 86, 1) 45.113524185587366%, 53.10957551826259%, rgba(115, 115, 115, 1) 61.10562685093781%, 65.99210266535044%, rgba(93, 93, 93, 1) 70.87857847976308%, 85.43928923988153%, rgba(38, 38, 38, 1) 100%)',
  } as any,
  bgGradientViolet: {
    background:
      'linear-gradient(to right, rgba(67, 8, 98, 1) 0%, 5.923000987166831%, rgba(87, 22, 122, 1) 11.846001974333662%, 18.26258637709773%, rgba(108, 37, 145, 1) 24.6791707798618%, 31.015816129447618%, rgba(128, 51, 169, 1) 37.21618953603159%, 43.83020730503455%, rgba(149, 65, 194, 1) 50.444225074037504%, 56.46594274432378%, rgba(158, 68, 206, 1) 62.48766041461007%, 68.65745310957551%, rgba(151, 51, 204, 1) 74.82724580454096%, 80.70088845014807%, rgba(143, 34, 202, 1) 86.57453109575518%, 93.28726554787758%, rgba(136, 17, 200, 1) 100%)',
  } as any,

  btnText,
} as const
