import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  background: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 30,
    paddingVertical: 19,
  },
  bckgImg: {
    width: '100%',
    height: 60,
  },
  icons: {
    height: 20,
    width: 20,
  },
  iconPeople: {
    height: 20,
    width: 20,
  },
})
