import { useAppDispatch } from '@/redux/store'
import {
  validateConfirmPassword,
  validateEmail,
  validatePassword,
} from '@/models/auth.model'
import { register } from '@/redux/thunks'
import { useState } from 'react'

export type TRegisterForm = {
  email: string
  password: string
  confirmPassword: string
}

export type TRegisterFormErrors = {
  email?: string
  password?: string
  confirmPassword?: string
}

export const useRegisterController = () => {
  const dispatch = useAppDispatch()

  const [input, setInput] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [formErrors, setFormErrors] = useState<TRegisterFormErrors>({})
  const [errorRegisterMessage, setErrorRegisterMessage] = useState('')
  const [isLoadingRegister, setIsLoadingRegister] = useState(false)

  const registerUser = async (input) => {
    await dispatch(
      register({ email: input.email, password: input.password }),
    ).unwrap()
  }

  const validateRegisterForm = (input: TRegisterForm) => {
    let errors: TRegisterFormErrors = {}
    errors.email = validateEmail(input.email) || undefined
    errors.password = validatePassword(input.password) || undefined
    errors.confirmPassword =
      validateConfirmPassword(input.confirmPassword, input.password) ||
      undefined
    return errors
  }

  const handleChange = (newValue: string, name: string) => {
    if (name === 'email') {
      newValue = newValue.toLocaleLowerCase()
    }
    const newInput = { ...input, [name]: newValue }
    setInput(newInput)
    setFormErrors(validateRegisterForm(newInput))
  }

  const handleSubmit = async () => {
    try {
      setIsLoadingRegister(true)
      setErrorRegisterMessage('')
      await registerUser(input)
    } catch (err) {
      console.warn(err)
      setErrorRegisterMessage(err instanceof Error ? err?.message : String(err))
    } finally {
      setIsLoadingRegister(false)
    }
  }
  const registerBtnDisabled =
    !!formErrors.email ||
    !!formErrors.password ||
    !!formErrors.confirmPassword ||
    input.email.length === 0 ||
    input.password.length === 0 ||
    input.confirmPassword.length === 0 ||
    isLoadingRegister

  return {
    handleChange,
    handleSubmit,
    registerBtnDisabled,
    input,
    formErrors,
    errorRegisterMessage,
    isLoadingRegister,
  }
}
