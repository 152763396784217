import { useEffect, useState } from 'react'
import { StyleSheet, Image, Vibration, View, Linking } from 'react-native'
import { ParticipantList, SectionButton } from '..'
import accept from '@/assets/MyEvents/success.png'
import deny from '@/assets/MyEvents/error.png'
// import acceptDisabled from '@/assets/MyEvents/successDisabled.png'
import enter from '@/assets/MyEvents/enter.png'
import enterDisabled from '@/assets/MyEvents/enterDisabled.png'
import {
  TAdminEventInfoModel,
  TTicketTypeParticipants,
} from '@/models/admin-event-info.model'
import { ParticipantStatus, TParticipantDetailModel } from '@/models'
import { ConfirmModal, CustomPressable } from '@/components'
import { TConfirmModal } from '@/components/Modals/ConfirmModal/ConfirmModal'
import { useAppDispatch } from '@/redux/store'
import { updateParticipantStatusAction } from '@/redux/thunks'
import { navigateTo } from 'routes/utils'

const ParticipantsTicketControl = ({
  adminEventInfo,
  totalAvailableTickets,
}: {
  adminEventInfo: TAdminEventInfoModel
  totalAvailableTickets: number
}) => {
  const dispatch = useAppDispatch()

  const [confirmModal, setConfirmModal] = useState<TConfirmModal>(null)
  const [activeSection, setActiveSection] = useState<ParticipantStatus>(
    ParticipantStatus.PENDING,
  )

  const [hasTicketsRequest, setHasTicketsRequest] = useState(true)

  const [participantsIdByStatus, setParticipantsIdByStatus] =
    useState<TTicketTypeParticipants>({
      [ParticipantStatus.PENDING]: [],
      [ParticipantStatus.ACCEPTED]: [],
      [ParticipantStatus.REJECTED]: [],
    } as TTicketTypeParticipants)

  const eventIsFree = adminEventInfo.eventTicketTypes?.[0]?.price === '0'

  useEffect(() => {
    if (adminEventInfo?.ticketTypesParticipants) {
      let newHasTicketsRequest = false
      const newParticipantsIdByStatus = {
        [ParticipantStatus.PENDING]: [],
        [ParticipantStatus.ACCEPTED]: [],
        [ParticipantStatus.REJECTED]: [],
      } as TTicketTypeParticipants

      adminEventInfo.eventTicketTypes.forEach((ticketType) => {
        if (ticketType.hasTicketsRequest) {
          newHasTicketsRequest = true
          newParticipantsIdByStatus[ParticipantStatus.PENDING].push(
            ...adminEventInfo.ticketTypesParticipants[ticketType.id][
              ParticipantStatus.PENDING
            ],
          )
        }
        newParticipantsIdByStatus[ParticipantStatus.ACCEPTED].push(
          ...adminEventInfo.ticketTypesParticipants[ticketType.id][
            ParticipantStatus.ACCEPTED
          ],
        )
        newParticipantsIdByStatus[ParticipantStatus.REJECTED].push(
          ...adminEventInfo.ticketTypesParticipants[ticketType.id][
            ParticipantStatus.REJECTED
          ],
        )
      })

      setHasTicketsRequest(newHasTicketsRequest)
      setParticipantsIdByStatus(newParticipantsIdByStatus)
    }
  }, [adminEventInfo])

  useEffect(() => {
    if (!hasTicketsRequest) {
      setActiveSection(ParticipantStatus.ACCEPTED)
    }
  }, [hasTicketsRequest])

  const updateParticipantStatus = async (
    participantId: string,
    newStatus: ParticipantStatus,
    prevStatus: ParticipantStatus,
    ticketTypeId: string,
  ) => {
    return await dispatch(
      updateParticipantStatusAction({
        participantId,
        newStatus,
        prevStatus,
        ticketTypeId,
      }),
    ).unwrap()
  }

  const acceptParticipant = (participant: TParticipantDetailModel) => {
    if (totalAvailableTickets) {
      setConfirmModal({
        text: '¿Querés aceptar a este usuario?',
        confirmFunc: async () => {
          await updateParticipantStatus(
            participant.participantId,
            eventIsFree
              ? ParticipantStatus.WITH_TICKET
              : ParticipantStatus.ACCEPTED,
            participant.status,
            participant.ticketTypeId,
          ),
            Vibration.vibrate()
        },
      })
    } else {
      setConfirmModal({
        text: 'No tenés más cupos disponibles!',
        confirmFunc: async () => {
          Linking.openURL(
            `https://wa.me/${'5492214773939'}?text=${encodeURIComponent(`Hola! Me gustaría obtener más cupos para mi evento creado con Vincufy: ${adminEventInfo?.eventName}. Ya no puedo aceptar más participantes porque llegué al límite.`)}`,
          )
        },
        oneButton: true,
        primaryButtonText: 'Aumentar cupos',
      })
    }
  }

  const rejectParticipant = (participant: TParticipantDetailModel) => {
    setConfirmModal({
      text: '¿Querés rechazar a este usuario?',
      confirmFunc: async () =>
        await updateParticipantStatus(
          participant.participantId,
          ParticipantStatus.REJECTED,
          participant.status,
          participant.ticketTypeId,
        ),
    })
  }

  const AcceptBtn = ({
    participant,
  }: {
    participant: TParticipantDetailModel
  }) => {
    return (
      <CustomPressable onPress={() => acceptParticipant(participant)}>
        <Image source={accept} style={{ padding: 13 }} />
      </CustomPressable>
    )
  }
  const RejectBtn = ({
    participant,
  }: {
    participant: TParticipantDetailModel
  }) => {
    return (
      <CustomPressable onPress={() => rejectParticipant(participant)}>
        <Image source={deny} style={{ padding: 13 }} />
      </CustomPressable>
    )
  }
  const ScannedAtIcon = ({
    participant,
  }: {
    participant: TParticipantDetailModel
  }) => {
    return (
      <CustomPressable disabled={true}>
        <Image
          source={participant.ticketScannedAt ? enter : enterDisabled}
          style={{ padding: 13 }}
        />
      </CustomPressable>
    )
  }

  const goToParticipantDetail = (participantId: string) => {
    navigateTo('AdminPanelParticipant', {
      eventId: adminEventInfo.eventId,
      participantId,
    })
  }

  return (
    <View>
      <View style={styles.buttonStatusContainer}>
        {hasTicketsRequest && (
          <SectionButton
            title="Pendientes"
            isActive={activeSection === ParticipantStatus.PENDING}
            onPress={() => setActiveSection(ParticipantStatus.PENDING)}
          />
        )}
        <SectionButton
          title="Confirmados"
          isActive={activeSection === ParticipantStatus.ACCEPTED}
          onPress={() => setActiveSection(ParticipantStatus.ACCEPTED)}
        />
        <SectionButton
          title="Rechazados"
          isActive={activeSection === ParticipantStatus.REJECTED}
          onPress={() => setActiveSection(ParticipantStatus.REJECTED)}
        />
      </View>
      {activeSection === ParticipantStatus.PENDING ? (
        <ParticipantList
          participantsId={participantsIdByStatus.PENDING}
          emptyMessage="Sin solicitudes pendientes."
          // primaryButtonImage={quantityAvailable > 0 ? accept : acceptDisabled}
          // primaryAction={(guest) =>
          //   quantityAvailable > 0
          //     ? updateParticipantStatus({ guest, value: 'accept' })
          //     : alert(
          //         'Te quedaste sin cupos restantes, comunicate con nosotros para habilitar más',
          //       )
          // }
          primaryButton={AcceptBtn}
          secondaryButton={RejectBtn}
          goToDetailFunc={goToParticipantDetail}
        />
      ) : activeSection === ParticipantStatus.ACCEPTED ? (
        <ParticipantList
          participantsId={participantsIdByStatus.ACCEPTED || []}
          emptyMessage="Sin participantes confirmados."
          primaryButton={RejectBtn}
          secondaryButton={ScannedAtIcon}
          goToDetailFunc={goToParticipantDetail}
        />
      ) : (
        activeSection === ParticipantStatus.REJECTED && (
          <ParticipantList
            participantsId={participantsIdByStatus.REJECTED || []}
            emptyMessage="Sin participantes rechazados."
            primaryButton={AcceptBtn}
            goToDetailFunc={goToParticipantDetail}
            // primaryButtonImage={quantityAvailable > 0 ? accept : acceptDisabled}
            // primaryAction={(guest) =>
            //   quantityAvailable > 0
            //     ? updateParticipantStatus({ guest, value: 'accept' })
            //     : alert(
            //         'Te quedaste sin cupos restantes, comunicate con nosotros para habilitar más',
            //       )
            // }
          />
        )
      )}
      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        oneButton={confirmModal?.oneButton}
        primaryButtonText={confirmModal?.primaryButtonText}
      />
    </View>
  )
}
const styles = StyleSheet.create({
  buttonStatusContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: 30,
    paddingBottom: 15,
    marginHorizontal: 10,
    borderBottomWidth: 1.5,
    borderBottomColor: '#FFFFFF',
  },
})

export default ParticipantsTicketControl
