import { StyleSheet } from 'react-native-web'

export default StyleSheet.create({
  cardContainer: {
    flex: 1,
  },
  btnsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '15px',
    paddingTop: '20px',
    paddingBottom: '10px',
    marginBottom: '5px',
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: 'black',
  },
  editIcon: {
    height: 16,
    width: 16,
    marginLeft: -10,
  },
  settingsIcon: {
    height: 16,
    width: 16,
  },
})
