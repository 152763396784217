import { StyleSheet } from 'react-native-web'

export default StyleSheet.create({
  container: {
    marginVertical: 20,
    borderWidth: 1,
    borderColor: '#999999',
    borderRadius: 8,
    height: 45,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 50,
    backgroundColor: '#1E1E1E', // Fondo oscuro para contraste
  },
  inputDisabled: {
    marginVertical: 20,
    borderWidth: 1,
    borderColor: 'red', // Borde rojo para indicar error
    borderRadius: 8,
    height: 45,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 50,
    backgroundColor: '#2E2E2E', // Fondo ligeramente más claro para indicar deshabilitado
  },
  searchInput: {
    color: 'white',
    fontSize: 16,
    textAlign: 'center',
    paddingHorizontal: 10, // Espaciado interno
    width: '85%', // Toma el 85% del contenedor
    outlineStyle: 'none', // Para React Native Web: quita el borde al seleccionar
    borderWidth: 0, // Elimina cualquier borde adicional
  },
  iconButton: {
    backgroundColor: '#8811c8', // Fondo púrpura para botón activo
    height: '100%',
    width: 45,
    borderBottomRightRadius: 8,
    borderTopRightRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButtonDisabled: {
    backgroundColor: '#807884', // Fondo gris para botón deshabilitado
    height: '100%',
    width: 45,
    borderBottomRightRadius: 8,
    borderTopRightRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 20, // Tamaño del ícono
    height: 20,
  },
})
