import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import { firebaseStorage } from './firebaseConfig'
import * as ImagePicker from 'expo-image-picker'
import { ENVIRONMENT } from '@env'
import envs from './envs'

export const pickImage = async (): Promise<string> => {
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
  if (status !== 'granted') throw new Error('PERMISSION_NOT_GRANTED')

  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    aspect: [4, 3],
    quality: 1,
  })

  if (!result || result.canceled) throw new Error('NO_IMAGE_SELECTED')

  return result.assets[0].uri
}

export const uploadImageToStorage = async (
  fileCategory: string, // ej: event_pics, profile_pics
  fileItemId: string, // ej: eventId, userProfileId
  fileUri: string,
): Promise<string> => {
  try {
    // Convertir la URI a Blob
    const response = await fetch(fileUri)
    const blob = await response.blob()

    // Crear nombre único para el archivo
    const fileName = `image_${Date.now()}.${blob.type.split('/')[1]}`

    const envFolder =
      ENVIRONMENT === envs.ENVIRONMENTS.PRODUCTION ? 'prod' : 'dev'

    // Crear referencia en Firebase Storage
    const storageRef = ref(
      firebaseStorage,
      `${envFolder}/${fileCategory}/${fileItemId}/${fileName}`,
    )

    // Subir el archivo
    await uploadBytes(storageRef, blob)

    // Obtener URL pública del archivo
    const downloadURL = await getDownloadURL(storageRef)

    // console.log('Imagen subida:', downloadURL)
    return downloadURL
  } catch (error) {
    console.error('Error al subir la imagen a Firebase:', error)
    throw error
  }
}

export const deleteImageFromStorage = async (
  downloadURL: string,
): Promise<boolean> => {
  try {
    // Extraer la ruta del archivo desde la URL de descarga
    const path = decodeURIComponent(
      new URL(downloadURL).pathname.split('/o/')[1],
    )

    // Crear referencia en Firebase Storage
    const storageRef = ref(firebaseStorage, path)

    // Eliminar la imagen
    await deleteObject(storageRef)
    return true
  } catch (error) {
    console.error('Error al eliminar la imagen de Firebase:', error)
    return false
  }
}
