import { StyleSheet, Dimensions } from 'react-native'

const { width } = Dimensions.get('window')
const isLargeScreen = width >= 768

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: isLargeScreen ? width * 0.5 : width - 30,
    minHeight: 210,
    borderRadius: 7,
    overflow: 'hidden',
    marginBottom: 20,
    padding: 15,
  },
  bckgImg: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 7,
  },
  content: {
    gap: 10,
    margin: 'auto',
  },
  editInfo: {
    height: 30,
    width: 30,
    position: 'absolute',
    right: 15,
    top: 15,
  },
  name: {
    color: 'white',
    fontSize: 24,
    fontWeight: '500',
    textAlign: 'center',
  },
  state: {
    color: 'white',
    fontSize: 17,
    fontWeight: '400',
    textAlign: 'center',
  },
  location: {
    color: 'white',
    fontSize: 16,
    fontWeight: '400',
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    color: 'white',
    fontSize: 16,
    fontWeight: '200',
    lineHeight: 23,
  },
})
