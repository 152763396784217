import { View, Text, Modal } from 'react-native'
import styles from './SetContactMethodModalStyles'
import { CustomPressable } from '@/components'
import { theme } from '@/theme'
import { navigateTo } from 'routes/utils'

export default function SetContactMethodModal({
  isOpenContactModal,
  setIsOpenContactModal,
}) {
  const closeModal = () => setIsOpenContactModal(false)

  const goToSetContactMethodsScreen = () => {
    navigateTo('ContactMethodScreen', {
      origin: 'EventVirtualRoom',
    })
    closeModal()
  }

  return (
    <Modal
      transparent={true}
      visible={isOpenContactModal}
      animationType="fade"
      onRequestClose={closeModal} // Permite que se cierre al presionar el botón de retroceso en dispositivos Android
    >
      <View style={[styles.background]}>
        <View style={[styles.modal, theme.bgGradientRight]}>
          <Text style={styles.text}>
            Upss! primero agreguemos un método de contacto para que tus nuevos
            vínculos puedan hablarte por ahí.
          </Text>
          <View>
            <CustomPressable
              style={styles.btn}
              onPress={goToSetContactMethodsScreen}
            >
              <Text style={styles.btnText}>Cargar método de contacto</Text>
            </CustomPressable>
          </View>
        </View>
      </View>
    </Modal>
  )
}
