import { View, Text } from 'react-native'
import styles from './CommunityInfoStyles'

export const CommunityInfo = ({ description }) => {
  return (
    <View style={styles.contentInfoRows}>
      <Text style={styles.label}>Descripción</Text>
      <View style={styles.descriptionRow}>
        <Text style={styles.descriptionText}>{description}</Text>
      </View>
    </View>
  )
}
