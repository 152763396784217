import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { Login, Register } from '@/views'

export type AuthNavigatorParamList = {
  Login: {}
  Register: {}
}

const AuthStack = createNativeStackNavigator<AuthNavigatorParamList>()

export const AuthNavigator = () => {
  return (
    <AuthStack.Navigator
      initialRouteName={'Login'}
      screenOptions={{ headerShown: false }}
    >
      <AuthStack.Screen
        name="Login"
        component={Login}
        options={{ title: 'Logueo - Vincufy' }}
      />
      <AuthStack.Screen
        name="Register"
        component={Register}
        options={{ title: 'Registro - Vincufy' }}
      />
    </AuthStack.Navigator>
  )
}
