import { StyleSheet } from 'react-native-web'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: '25px',
    gap: '20px',
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    backgroundColor: '#8811c8',
    borderRadius: 3,
    height: 40,
    minWidth: 180,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnText: {
    color: 'white',
    fontSize: 15,
    fontWeight: '500',
    textAlign: 'center',
  },
})

export default styles
