// import { RefreshControl } from 'react-native'
import { RefreshControl } from 'react-native-web-refresh-control' // Importa el nuevo refresh control
import { useRefreshController } from './useRefreshController'

const CustomRefreshControl = ({
  onRefresh,
  isLoadingFetch = false,
  ...props
}) => {
  const [refreshController, isLoadingRefresh] = useRefreshController({
    isLoadingFetch,
  })

  return (
    <RefreshControl
      {...props}
      refreshing={isLoadingRefresh}
      onRefresh={() => refreshController(onRefresh)}
      tintColor="white"
    />
  )
}

export default CustomRefreshControl
