import { HTTP_METHOD, vincufyApiCall } from '@/config/vincufyApi'
import {
  SET_DEVICE_NOTIFICATIONS_STATUS,
  SET_SHOULD_HIDE_TAB_BAR,
  SET_USER,
} from '../constants'
import { notisLog } from '@/utils/devLog'
import { createCustomAsyncThunk } from '../store/createCustomAsyncThunk'
import {
  deleteImageFromStorage,
  pickImage,
  uploadImageToStorage,
} from '@/config/imageStorage'
import { userInfoMock } from '@/mocks'
import { logged, setLoading } from '../actions'
import { TUserModel, TUserProfileModel } from '@/models'
import { userAuthMock } from '@/mocks/Users/userAuthMock'

export const completeRegister = createCustomAsyncThunk(
  'user/completeRegister',
  async ({ newUser }, { dispatch }) => {
    try {
      if (!(Object.keys(newUser).length > 0)) throw new Error('No newUser')

      const respBody = await vincufyApiCall({
        method: HTTP_METHOD.POST,
        endpoint: `/complete-register`,
        body: newUser,
        mock: { ...userInfoMock, userAuth: userAuthMock },
        forceMock: false,
      })

      dispatch(logged(respBody.userAuth))

      dispatch({
        type: SET_USER,
        payload: {
          user: respBody.user,
          userProfiles: respBody.userProfiles,
        },
      })

      return respBody.user
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn('ERROR completeRegister:', errorMsg)
      throw err
    }
  },
)

export const getUserDB = createCustomAsyncThunk(
  'user/getUserDB',
  async (_: void, { dispatch }) => {
    try {
      const respBody = await vincufyApiCall({
        method: HTTP_METHOD.GET,
        endpoint: `/user/full`,
        body: {},
        mock: userInfoMock,
        forceMock: false,
      })

      dispatch({
        type: SET_USER,
        payload: {
          user: respBody.user,
          userProfiles: respBody.userProfiles,
        },
      })
    } catch (err) {
      console.log('getUserDB ERROR', err)
      throw err
    }
  },
)

export const editUserProfileDB = createCustomAsyncThunk(
  'user/editUserProfileDB',
  async (
    newEditedUserProfile: Partial<TUserProfileModel>,
    { dispatch, getState },
  ) => {
    try {
      if (!(Object.keys(newEditedUserProfile).length > 0))
        throw new Error('No newEditedUserProfile')

      const newUserProfiles = getState().user.userProfiles
      const profileId = newUserProfiles[0].id
      if (!profileId) throw new Error('No profileId')

      const respBody = await vincufyApiCall<{ userProfile: TUserProfileModel }>(
        {
          method: HTTP_METHOD.PATCH,
          endpoint: `/user/profile/${profileId}`,
          body: newEditedUserProfile,
          mock: { userProfile: userInfoMock.userProfiles[0] },
          forceMock: false,
        },
      )

      dispatch({
        type: SET_USER,
        payload: {
          userProfiles: [respBody.userProfile],
        },
      })

      return respBody.userProfile
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn('ERROR editUserDB:', errorMsg)
      throw err
    }
  },
)

export const editUserDB = createCustomAsyncThunk(
  'user/editUserDB',
  async (newEditedUser: Partial<TUserModel>, { dispatch }) => {
    try {
      if (!(Object.keys(newEditedUser).length > 0))
        throw new Error('No newEditedUser')

      const respBody = await vincufyApiCall<{ user: TUserModel }>({
        method: HTTP_METHOD.PATCH,
        endpoint: `/user`,
        body: newEditedUser,
        mock: userInfoMock,
        forceMock: false,
      })

      dispatch({
        type: SET_USER,
        payload: {
          user: respBody.user,
        },
      })

      return respBody.user
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn('ERROR editUserDB:', errorMsg)
      throw err
    }
  },
)

export const setDeviceNotificationsStatus = createCustomAsyncThunk(
  'user/setDeviceNotificationsStatus',
  async (deviceNotificationsStatus, { dispatch }) => {
    try {
      dispatch({
        type: SET_DEVICE_NOTIFICATIONS_STATUS,
        payload: { deviceNotificationsStatus },
      })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.log('setDeviceNotificationsStatus error.', errorMsg)
    }
  },
)

export const updateNotificationToken = createCustomAsyncThunk(
  'user/updateNotificationToken',
  async (notificationToken, { dispatch }) => {
    try {
      // const storedToken = localStorage.getItem('vincufyNotificationToken');

      // if (storedToken !== notificationToken) {

      // Por ahora siempre actualizar el notificationToken en el backend
      const respBody = await vincufyApiCall({
        method: HTTP_METHOD.PATCH,
        endpoint: `/user/notification-token`,
        body: { notificationToken },
        mock: { user: userInfoMock['user'] },
        forceMock: false,
      })

      // localStorage.setItem('vincufyNotificationToken', response.data.notificationToken);
      notisLog('Token uploaded.')

      // }
      dispatch({
        type: SET_USER,
        payload: { user: respBody.user },
      })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      notisLog('Error -> Token upload error.', errorMsg)
    }
  },
)

export const setShouldHideTabBar = createCustomAsyncThunk(
  'user/setShouldHideTabBar',
  async (shouldHideTabBar, { dispatch }) => {
    try {
      dispatch({
        type: SET_SHOULD_HIDE_TAB_BAR,
        payload: { shouldHideTabBar },
      })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.log('setShouldHideTabBar error.', errorMsg)
    }
  },
)

export const pickProfileImage = createCustomAsyncThunk<number>(
  'user/pickProfileImage',
  async (position: number, { dispatch, getState }) => {
    try {
      const newPictureLocalUrl = await pickImage()
      if (!newPictureLocalUrl) return { success: false }

      const userProfile = getState().user?.userProfiles?.[0]

      dispatch(setLoading(true))
      const newPicturPublicUrl = await uploadImageToStorage(
        'profile_pics',
        userProfile.id,
        newPictureLocalUrl,
      )
      const newProfilePictures = [
        ...(userProfile.profilePictures || ['', '', '']),
      ]

      if (newProfilePictures[position]) {
        await deleteImageFromStorage(newProfilePictures[position])
      }

      newProfilePictures[position] = newPicturPublicUrl

      const editUserProfileDBResult = await dispatch(
        editUserProfileDB({ profilePictures: newProfilePictures }),
      ).unwrap()

      if (!editUserProfileDBResult) throw new Error('API ERROR')

      return { success: true }
    } catch (err) {
      dispatch(setLoading(false))
      const errorMsg = err instanceof Error ? err?.message : String(err)
      if (['PERMISSION_NOT_GRANTED', 'NO_IMAGE_SELECTED'].includes(errorMsg))
        return errorMsg
      console.log('Error pickProfileImage:', errorMsg)
      return 'ERROR'
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const deleteProfilePictureDB = createCustomAsyncThunk(
  'user/deleteProfilePictureDB',
  async (position: number, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true))

      const newProfilePictures = [
        ...(getState().user?.userProfiles?.[0]?.profilePictures || [
          '',
          '',
          '',
        ]),
      ]

      await deleteImageFromStorage(newProfilePictures[position])

      for (let i = position; i < newProfilePictures.length; i++) {
        if (i === newProfilePictures.length - 1) {
          newProfilePictures[i] = ''
        } else {
          newProfilePictures[i] = newProfilePictures[i + 1]
        }
      }

      const editUserProfileDBResult = await dispatch(
        editUserProfileDB({ profilePictures: newProfilePictures }),
      ).unwrap()

      if (!editUserProfileDBResult) throw new Error('API ERROR')

      return { success: true }
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      throw new Error(errorMsg)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

// export const getAllUsers = async (dispatch) => {
//   try {
//     const user = await vincufyApi.get(`${URL_API_VINCULANDO}/users`)
//     dispatch({
//       type: GET_ALL_USERS,
//       payload: user.data,
//     })
//   } catch (err) {
//     dispatch({
//       type: GET_ALL_USERS,
//       payload: error,
//     })
//   }
// }

// export const sendEmailPassword = async (email) => {
//   try {
//     await vincufyApi.get(`${URL_API_VINCULANDO}/users/mail`, {
//       email: email,
//     });
//     // dispatch({
//     //   type: SEND_EMAIL,
//     //   payload: "Email enviado con éxito",
//     // });
//   } catch (err) {
//     console.log(error, "Error al enviar el email");
//   }
// };

// export const resetPassword = async (email, password) => {
//   try {
//     await vincufyApi.post(`${URL_API_VINCULANDO}/reset`, {
//       email: email,
//       password: password,
//     });
//   } catch (err) {
//     console.warn(errorMsg)
//   }
// };
