import { Text, View } from 'react-native'
import styles from './SectionButtonStyles'
import { CustomPressable } from '@/components'
import { theme } from '@/theme'

const SectionButton = ({ title, isActive, onPress }) => (
  <CustomPressable
    style={isActive ? styles.activeButton : styles.inactiveButton}
    onPress={onPress}
  >
    {isActive ? (
      <View style={[styles.buttonBackground, theme.bgGradientCenter]}>
        <Text style={styles.buttonText}>{title}</Text>
      </View>
    ) : (
      <Text style={styles.buttonText}>{title}</Text>
    )}
  </CustomPressable>
)

export default SectionButton
