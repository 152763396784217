import { useState, useEffect } from 'react'
import { View, Text, Image, TextInput, ScrollView } from 'react-native'
import styles from './SelectContactMethodTypeStyles'
import instagramIcon from '../../../assets/People/instagram.png'
import whatsappIcon from '../../../assets/People/whatsapp.png'
import linkedinIcon from '../../../assets/People/linkedin.png'
import gmailIcon from '../../../assets/People/gmail.png'
import signoMas from '../../../assets/People/mas.png'
import trashIcon from '../../../assets/People/trashcan.png'
import ModalItems from './ModalItems/ModalItems'
import { editUserProfileDB } from '@/redux/thunks'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { CustomPressable } from '@/components'
import { theme } from '@/theme'
import { TContactMethods } from '@/models'
import { goBackTo } from 'routes/utils'

const SelectContactMethodType = ({ showModalSelect, setShowModalSelect }) => {
  const dispatch = useAppDispatch()
  const userProfiles = useAppSelector((state) => state.user.userProfiles)
  const currentUserProfile = userProfiles[0]

  const initialContactMethods: TContactMethods = {
    instagram: null,
    whatsapp: null,
    linkedin: null,
    email: null,
  }

  const initialStatus = [
    { label: 'Instagram', value: 'instagram', icon: instagramIcon },
    { label: 'WhatsApp', value: 'whatsapp', icon: whatsappIcon },
    { label: 'Linkedin', value: 'linkedin', icon: linkedinIcon },
    { label: 'Mail', value: 'email', icon: gmailIcon },
  ]

  const prefixes = {
    instagram: '@',
    whatsapp: '+549',
    linkedin: '/ini/',
    email: '',
  }

  const [originalContacts, setOriginalContacts] = useState<TContactMethods>(
    initialContactMethods,
  )
  const [selectedContacts, setSelectedContacts] = useState<TContactMethods>(
    initialContactMethods,
  )
  const [status, setStatus] = useState(initialStatus)
  const [errors, setErrors] = useState({})
  const userContactMethods = currentUserProfile?.contactMethods

  useEffect(() => {
    if (userContactMethods) {
      setOriginalContacts(userContactMethods)
      setSelectedContacts(userContactMethods)

      const updatedStatus = initialStatus.filter(
        (item) => !Object.keys(userContactMethods).includes(item.value),
      )
      setStatus(updatedStatus)
    }
  }, [])

  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  const handleInputChange = (newInput: string, contactType: string) => {
    let newValue: string = newInput.toLowerCase?.() || ''
    if (contactType === 'whatsapp') {
      newValue = newValue.replace(/[^0-9]/g, '')
    }
    const strippedValue = newValue.replace(prefixes[contactType], '')
    let errorMessage = ''

    switch (contactType) {
      case 'instagram':
        const instagramRegex = /^[a-z0-9_]{3,30}$/
        if (!instagramRegex.test(strippedValue)) {
          errorMessage =
            'Solo se permiten minúsculas, números y no más de 30 caracteres.'
        }
        break

      case 'whatsapp':
        const whatsappRegex = /^(?!15|0|54|549)([0-9]{10})$/
        if (!whatsappRegex.test(strippedValue)) {
          errorMessage =
            'El número de WhatsApp debe ser de 10 dígitos y no puede comenzar con 15, 0, 54 o 549.'
        }
        break

      case 'linkedin':
        const linkedinRegex = /^[a-z0-9-]{3,30}$/
        if (!linkedinRegex.test(strippedValue)) {
          errorMessage =
            'Solo se permiten minúsculas, números y no más de 30 caracteres.'
        }
        break

      case 'email':
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/
        if (!emailRegex.test(strippedValue)) {
          errorMessage = 'El correo electrónico debe tener un formato válido.'
        }
        break

      default:
        break
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [contactType]: errorMessage || undefined,
    }))
    setSelectedContacts((prevState) => ({
      ...prevState,
      [contactType]: newValue || null,
    }))
  }

  const handleRemoveContact = (contactType) => {
    setSelectedContacts((prevState) => {
      const updatedContacts = { ...prevState }
      delete updatedContacts[contactType]
      return updatedContacts
    })
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }
      delete updatedErrors[contactType]
      return updatedErrors
    })

    const restoredContact = initialStatus.find(
      (item) => item.value === contactType,
    )
    if (restoredContact)
      setStatus((prevStatus) => [...prevStatus, restoredContact])
  }

  const buttonStyle = [
    styles.openButton,
    {
      marginBottom: 25,
      opacity: Object.keys(selectedContacts).length !== 4 ? 1 : 0.5,
    },
  ]

  const isButtonDisabled =
    Object.values(selectedContacts).some((contact) => contact === '') ||
    Object.values(errors).some((error) => error !== undefined) ||
    isEqual(selectedContacts, originalContacts)

  const handleSubmit = () => {
    dispatch(editUserProfileDB({ contactMethods: selectedContacts }))
    // if (origin === 'MyProfile') {
    //   navigateTo('MyProfile', {})
    // } else if (origin === 'EventVirtualRoom') {
    //   if (navigationRef.canGoBack()) {
    //     navigationRef.goBack()
    //   } else {
    //     navigateTo('MyEvents', {})
    //   }
    // }
    goBackTo('MyProfile', {})
  }

  return (
    <>
      <CustomPressable
        style={buttonStyle}
        onPress={() => setShowModalSelect(true)}
        disabled={
          status.length === 0 || Object.keys(selectedContacts).length === 4
        }
      >
        <View style={[styles.buttonBackground, theme.bgGradientRight]}>
          <View style={styles.openButtonContent}>
            <Text style={styles.openButtonText}>Agregar</Text>
            <Image source={signoMas} style={styles.shareIcon} />
          </View>
        </View>
      </CustomPressable>

      <ModalItems
        showModalSelect={showModalSelect}
        setShowModalSelect={setShowModalSelect}
        status={status}
        setStatus={setStatus}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
      />

      <ScrollView
        style={styles.scrollView}
        showsVerticalScrollIndicator={false}
      >
        {Object.keys(selectedContacts).map((contactType) => {
          const contactItem = initialStatus.find(
            (item) => item.value === contactType,
          )

          const placeholders = {
            instagram: 'miusuario',
            whatsapp: 'Ej: 3515555555',
            linkedin: 'miusuario',
            mail: 'email@ejemplo.com',
          }

          return (
            <View key={contactType} style={styles.inputBox}>
              <View style={styles.inputLabelContainer}>
                <View style={styles.inputLabelIconText}>
                  {contactItem?.icon && (
                    <Image
                      source={contactItem.icon}
                      style={styles.contactIcon}
                    />
                  )}
                  <Text style={styles.inputLabel}>{contactItem?.label}</Text>
                </View>
                {/* {(contactType === 'instagram' ||
                  contactType === 'linkedin') && (
                  <Text style={styles.helper}>¿Cuál es mi usuario?</Text>
                )} */}
              </View>
              <View style={styles.inputContainer}>
                <View style={styles.inputAndErrorsBox}>
                  <View style={styles.inputContainer2}>
                    <View style={styles.inputWithIcon}>
                      <Text style={styles.prefix}>{prefixes[contactType]}</Text>
                      <TextInput
                        style={styles.input}
                        placeholder={placeholders[contactType]}
                        placeholderTextColor="darkgray"
                        value={selectedContacts[contactType]}
                        onChangeText={(v) => handleInputChange(v, contactType)}
                        textAlign="center"
                        autoCapitalize="none"
                        maxLength={30}
                        inputMode={
                          contactType === 'whatsapp' ? 'numeric' : 'text'
                        }
                      />
                    </View>
                    <CustomPressable
                      style={styles.trashButton}
                      onPress={() => handleRemoveContact(contactType)}
                    >
                      <Image source={trashIcon} style={styles.trashIcon} />
                    </CustomPressable>
                  </View>
                  {errors[contactType] && (
                    <View style={styles.errorBox}>
                      <Text style={styles.errorText}>
                        {errors[contactType]}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          )
        })}
        {Object.keys(selectedContacts).length > 0 && (
          <View style={styles.buttonContainer}>
            <CustomPressable
              style={[
                styles.btn,
                isButtonDisabled ? styles.btnDisabled : styles.btnEnabled,
              ]}
              disabled={isButtonDisabled}
              onPress={handleSubmit}
            >
              <Text style={styles.btnText}>Guardar y volver</Text>
            </CustomPressable>
          </View>
        )}
      </ScrollView>
    </>
  )
}

export default SelectContactMethodType
