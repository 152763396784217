import axios from 'axios'
import envs from './envs'
import { apiLog } from '@/utils/devLog'
import { store } from '@/redux/store/store'
import { cleanStore } from '@/redux/thunks'
import { ENVIRONMENT } from '@env'

export const vincufyApi = axios.create({
  baseURL: `${envs.URL_API_VINCUFY}/api`,
  withCredentials: true,
  headers: {
    'ngrok-skip-browser-warning': 'true',
  },
})

vincufyApi.interceptors.response.use(
  // Interceptor del response para actualizar el Access Token
  (response) => {
    apiLog('RESP', response)

    if (!response?.data?.success) {
      throw new Error('Http Error in VincufyApi: failed')
    }
    const respBody = response.data.data
    if (!respBody) {
      throw new Error('Http Error in VincufyApi: no body')
    }

    const newAccessToken = respBody.accessToken
    if (newAccessToken) {
      // Actualizar el token global de Axios
      vincufyApi.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`
    }

    return response
  },
  // Interceptor del responseError para desloguear
  async (error) => {
    apiLog('RESP_ERROR', error)

    if (
      error.response?.data?.data?.type === 'MsAuthGuardException' &&
      error.config?.url !== '/auth/verify-session' // El verify-session no desloguea en error
    ) {
      store.dispatch(cleanStore())
    }

    return Promise.reject(error)
  },
)

export type HTTP_METHOD = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE'
export const HTTP_METHOD = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
} as const

export const vincufyApiCall = async <TResp = any>({
  method: selectedMethod,
  endpoint,
  body,
  mock,
  forceMock = false,
}: {
  method: HTTP_METHOD
  endpoint: string
  body: object
  mock: TResp | boolean
  forceMock: boolean
}): Promise<TResp> => {
  let resp: {
    data: { success: boolean; token: string; data: TResp }
  }
  if (ENVIRONMENT === envs.ENVIRONMENTS.FRONT_ONLY || forceMock) {
    if (typeof mock === 'boolean') throw new Error('Empty required mock')

    resp = {
      data: {
        success: true,
        token: 'mock-token',
        data: mock,
      },
    }
  } else if (selectedMethod === HTTP_METHOD.DELETE) {
    resp = await vincufyApi.delete(endpoint, body ? { data: body } : undefined)
  } else if (selectedMethod === HTTP_METHOD.PATCH) {
    resp = await vincufyApi.patch(endpoint, body)
  } else if (selectedMethod === HTTP_METHOD.PUT) {
    resp = await vincufyApi.put(endpoint, body)
  } else if (selectedMethod === HTTP_METHOD.POST) {
    resp = await vincufyApi.post(endpoint, body)
  } else {
    resp = await vincufyApi.get(endpoint)
  }

  return resp.data.data
}

export default vincufyApi
