import { navigationLog } from '@/utils'
import { navigationRef } from 'routes/Navigation'
import { PAGES_NAV, TAllPageNavigatorsParams } from './pages-nav'
import { updateCurrentRoute } from './url-functions'

export const navigateTo = <PageName extends keyof TAllPageNavigatorsParams>(
  pageName: PageName,
  params: TAllPageNavigatorsParams[PageName],
  navType?: 'reset' | 'initial' | 'force',
  // reset: borra historial de navegacion (no goBack)
  // initial: luego de navegar no cambia la url
  // force: cambia la url aunque no navegue
): boolean => {
  const navReady = navigationRef?.isReady()
  const previousPage = {
    name: navigationRef?.getCurrentRoute()?.name,
    params: navigationRef?.getCurrentRoute()?.params,
  }
  const navExecuted = navReady && previousPage.name !== pageName
  navigationLog('navigateTo:', {
    pageName,
    params,
    navExecuted,
    previousPage,
    navType,
  })

  if (navExecuted) {
    const options = PAGES_NAV[pageName].navStructure(params)

    if (navType) {
      navigationRef.reset({
        index: 0,
        routes: [options],
      })
    } else {
      navigationRef.navigate(options)
    }

    if (navType !== 'initial') updateCurrentRoute(pageName, params)

    return true
  }
  if (navType === 'force') updateCurrentRoute(pageName, params)
  return false
}
