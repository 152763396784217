import {
  AdminPanelNavigatorParamList,
  AuthNavigatorParamList,
  BondsNavigatorParamList,
  EventDetailNavigatorParamList,
  EventsNavigatorParamList,
  OnboardingNavigatorParamList,
  ProfileNavigatorParamList,
  VirtualRoomNavigatorParamList,
} from 'routes/Navigators'
import { RootNavigatorParamList } from 'routes/Navigation'

export type TAllPageNavigatorsParams = AuthNavigatorParamList &
  OnboardingNavigatorParamList &
  Pick<EventsNavigatorParamList, 'MyEvents'> &
  EventDetailNavigatorParamList &
  VirtualRoomNavigatorParamList &
  AdminPanelNavigatorParamList &
  BondsNavigatorParamList &
  ProfileNavigatorParamList

const formatNavStructure = <
  RootNavName extends keyof RootNavigatorParamList,
  CurrentRootNavName extends keyof RootNavigatorParamList,
>(structure: {
  name: CurrentRootNavName
  params: RootNavigatorParamList[CurrentRootNavName]
}) =>
  structure as unknown as RootNavName extends unknown
    ? {
        name: RootNavName
        params: RootNavigatorParamList[RootNavName]
      }
    : never

export type TPAGE_NAV<PageName extends keyof TAllPageNavigatorsParams> = {
  name: PageName
  regex: RegExp
  hashUrl: (params: TAllPageNavigatorsParams[PageName]) => string
  navStructure: <RootNavName extends keyof RootNavigatorParamList>(
    params: TAllPageNavigatorsParams[PageName],
  ) => RootNavName extends unknown
    ? {
        name: RootNavName
        params: RootNavigatorParamList[RootNavName]
      }
    : never
  isRedirectPage: boolean
}

export const PAGES_NAV: {
  [PageName in keyof TAllPageNavigatorsParams]: TPAGE_NAV<PageName>
} = {
  Login: {
    name: 'Login',
    regex: /^\/login$/,
    hashUrl: () => `/#/login`,
    isRedirectPage: true,
    navStructure: (params) =>
      formatNavStructure({
        name: 'AuthNavigator',
        params: {
          screen: 'Login',
          params,
        },
      }),
  },
  Register: {
    name: 'Register',
    regex: /^\/registro$/,
    hashUrl: () => `/#/registro`,
    isRedirectPage: true,
    navStructure: (params) =>
      formatNavStructure({
        name: 'AuthNavigator',
        params: {
          screen: 'Register',
          params,
        },
      }),
  },
  CompleteRegister: {
    name: 'CompleteRegister',
    regex: /^\/completar-registro$/,
    hashUrl: () => `/#/completar-registro`,
    isRedirectPage: true,
    navStructure: (params) =>
      formatNavStructure({
        name: 'OnboardingNavigator',
        params: {
          screen: 'CompleteRegister',
          params,
        },
      }),
  },
  AllowNotifications: {
    name: 'AllowNotifications',
    regex: /^\/permitir-notificaciones$/,
    hashUrl: () => `/#/permitir-notificaciones`,
    isRedirectPage: true,
    navStructure: (params) =>
      formatNavStructure({
        name: 'OnboardingNavigator',
        params: {
          screen: 'AllowNotifications',
          params,
        },
      }),
  },
  MyEvents: {
    name: 'MyEvents',
    regex: /^\/mis-eventos$/,
    hashUrl: () => `/#/mis-eventos`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'EventsNavigator',
          params: {
            screen: 'MyEvents',
            params,
          },
        },
      }),
  },
  EventDetail: {
    name: 'EventDetail',
    regex: /^\/evento\/([0-9a-fA-F-]{36})$/,
    hashUrl: (params) => `/#/evento/${params?.eventId}`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'EventsNavigator',
          params: {
            screen: 'EventDetailNavigator',
            params: {
              screen: 'EventDetail',
              params,
            },
          },
        },
      }),
  },
  MyTickets: {
    name: 'MyTickets',
    regex: /^\/evento\/([0-9a-fA-F-]{36})\/tickets$/,
    hashUrl: (params) => `/#/evento/${params?.eventId}/tickets`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'EventsNavigator',
          params: {
            screen: 'EventDetailNavigator',
            params: {
              screen: 'MyTickets',
              params,
            },
          },
        },
      }),
  },
  TransitionScreen: {
    name: 'TransitionScreen',
    regex: /^\/evento\/([0-9a-fA-F-]{36})\/tickets\/resultado$/,
    hashUrl: (params) => `/#/evento/${params?.eventId}/tickets/resultado`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'EventsNavigator',
          params: {
            screen: 'EventDetailNavigator',
            params: {
              screen: 'TransitionScreen',
              params,
            },
          },
        },
      }),
  },
  EventVirtualRoom: {
    name: 'EventVirtualRoom',
    regex: /^\/evento\/([0-9a-fA-F-]{36})\/sala-virtual$/,
    hashUrl: (params) => `/#/evento/${params?.eventId}/sala-virtual`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'EventsNavigator',
          params: {
            screen: 'VirtualRoomNavigator',
            params: {
              screen: 'EventVirtualRoom',
              params,
            },
          },
        },
      }),
  },
  EventVirtualRoomParticipant: {
    name: 'EventVirtualRoomParticipant',
    regex:
      /^\/evento\/([0-9a-fA-F-]{36})\/sala-virtual\/participante\/([0-9a-fA-F-]{36})$/,
    hashUrl: (params) =>
      `/#/evento/${params?.eventId}/sala-virtual/participante/${params?.participantId}`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'EventsNavigator',
          params: {
            screen: 'VirtualRoomNavigator',
            params: {
              screen: 'EventVirtualRoomParticipant',
              params,
            },
          },
        },
      }),
  },
  EventBonds: {
    name: 'EventBonds',
    regex: /^\/evento\/([0-9a-fA-F-]{36})\/sala-virtual\/solicitudes$/,
    hashUrl: (params) =>
      `/#/evento/${params?.eventId}/sala-virtual/solicitudes`,
    isRedirectPage: false,
    navStructure: (params: TAllPageNavigatorsParams['EventBonds']) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'EventsNavigator',
          params: {
            screen: 'VirtualRoomNavigator',
            params: {
              screen: 'EventBonds',
              params,
            },
          },
        },
      }),
  },
  EventBondsParticipant: {
    name: 'EventBondsParticipant',
    regex:
      /^\/evento\/([0-9a-fA-F-]{36})\/sala-virtual\/solicitudes\/participante\/([0-9a-fA-F-]{36})$/,
    hashUrl: (params) =>
      `/#/evento/${params?.eventId}/sala-virtual/solicitudes/participante/${params?.participantId}`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'EventsNavigator',
          params: {
            screen: 'VirtualRoomNavigator',
            params: {
              screen: 'EventBondsParticipant',
              params,
            },
          },
        },
      }),
  },
  AdminPanel: {
    name: 'AdminPanel',
    regex: /^\/evento\/admin\/([0-9a-fA-F-]{36})$/,
    hashUrl: (params) => `/#/evento/admin/${params?.eventId}`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'EventsNavigator',
          params: {
            screen: 'AdminPanelNavigator',
            params: {
              screen: 'AdminPanel',
              params,
            },
          },
        },
      }),
  },
  AdminPanelParticipant: {
    name: 'AdminPanelParticipant',
    regex:
      /^\/evento\/admin\/([0-9a-fA-F-]{36})\/participante\/([0-9a-fA-F-]{36})$/,
    hashUrl: (params) =>
      `/#/evento/admin/${params?.eventId}/participante/${params?.participantId}`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'EventsNavigator',
          params: {
            screen: 'AdminPanelNavigator',
            params: {
              screen: 'AdminPanelParticipant',
              params,
            },
          },
        },
      }),
  },
  TicketControl: {
    name: 'TicketControl',
    regex: /^\/evento\/admin\/([0-9a-fA-F-]{36})\/scan-ticket$/,
    hashUrl: (params) => `/#/evento/admin/${params?.eventId}/scan-ticket`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'EventsNavigator',
          params: {
            screen: 'AdminPanelNavigator',
            params: {
              screen: 'TicketControl',
              params,
            },
          },
        },
      }),
  },
  TicketControlParticipant: {
    name: 'TicketControlParticipant',
    regex:
      /^\/evento\/admin\/([0-9a-fA-F-]{36})\/scan-ticket\/participante\/([0-9a-fA-F-]{36})$/,
    hashUrl: (params) =>
      `/#/evento/admin/${params?.eventId}/scan-ticket/participante/${params?.participantId}`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'EventsNavigator',
          params: {
            screen: 'AdminPanelNavigator',
            params: {
              screen: 'TicketControlParticipant',
              params,
            },
          },
        },
      }),
  },
  MyBonds: {
    name: 'MyBonds',
    regex: /^\/mis-vinculos$/,
    hashUrl: () => `/#/mis-vinculos`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'BondsNavigator',
          params: {
            screen: 'MyBonds',
            params,
          },
        },
      }),
  },
  BondParticipant: {
    name: 'BondParticipant',
    regex:
      /^\/mis-vinculos\/evento\/([0-9a-fA-F-]{36})\/participante\/([0-9a-fA-F-]{36})$/,
    hashUrl: (params) =>
      `/#/mis-vinculos/evento/${params?.eventId}/participante/${params?.participantId}`,
    isRedirectPage: false,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'BondsNavigator',
          params: {
            screen: 'BondParticipant',
            params,
          },
        },
      }),
  },
  MyProfile: {
    name: 'MyProfile',
    regex: /^\/mi-perfil$/,
    isRedirectPage: false,
    hashUrl: () => `/#/mi-perfil`,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'ProfileNavigator',
          params: {
            screen: 'MyProfile',
            params,
          },
        },
      }),
  },
  ChangeInfoSettings: {
    name: 'ChangeInfoSettings',
    regex: /^\/mi-perfil\/editar-info$/,
    isRedirectPage: false,
    hashUrl: () => `/#/mi-perfil/editar-info`,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'ProfileNavigator',
          params: {
            screen: 'ChangeInfoSettings',
            params,
          },
        },
      }),
  },
  ContactMethodScreen: {
    name: 'ContactMethodScreen',
    regex: /^\/mi-perfil\/metodos-contacto$/,

    isRedirectPage: false,
    hashUrl: () => `/#/mi-perfil/metodos-contacto`,
    navStructure: (params) =>
      formatNavStructure({
        name: 'MyTabsNavigator',
        params: {
          screen: 'ProfileNavigator',
          params: {
            screen: 'ContactMethodScreen',
            params,
          },
        },
      }),
  },
} as const
