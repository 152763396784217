import { navigationLog } from '@/utils'
import { useEffect, useState } from 'react'
import { navigationRef } from 'routes/Navigation'
import { getCurrentRoute } from '../utils/url-functions'

export const useRouteListener = () => {
  const [currentRoute, setCurrentRoute] = useState<string | null>(null)
  const [lastHistoryLength, setLastHistoryLength] = useState(history.length)

  // Navigation Listeners
  useEffect(() => {
    const handleLocationChange = (listenerMethod: string) => {
      const newCurrentRoute = getCurrentRoute()
      const newNavState = navigationRef?.isReady()
        ? navigationRef.getState()
        : null

      navigationLog('ROUTE_LISTENER', {
        hashRoute: newCurrentRoute,
        listener: listenerMethod,
        newNavState,
        completeRoute: window.location.href,
      })

      setCurrentRoute(newCurrentRoute)
    }

    handleLocationChange('initial')

    // LISTENERS
    window.addEventListener('hashchange', () =>
      handleLocationChange('hashchange'),
    )

    const pushState = history.pushState
    history.pushState = function (state, title, url) {
      handleLocationChange('pushState')
      return pushState.apply(this, [...arguments] as [
        any,
        string,
        (string | URL | null | undefined)?,
      ])
    }

    const replaceState = history.replaceState
    history.replaceState = function (state, title, url) {
      handleLocationChange('replaceState')
      return replaceState.apply(this, [...arguments] as [
        any,
        string,
        (string | URL | null | undefined)?,
      ])
    }

    navigationRef.addListener('state', () => {
      handleLocationChange('navigationStateUpdate')
    })

    const handlePopstate = (event: PopStateEvent) => {
      const newHistoryLength = history.length
      if (newHistoryLength < lastHistoryLength) {
        setLastHistoryLength(newHistoryLength)
        event.preventDefault()

        // const noReturnPages = [
        //   '/login',
        //   '/mis-eventos',
        //   '/mis-vinculos',
        //   '/mi-perfil',
        // ]
        if (navigationRef.canGoBack()) {
          navigationLog('GO_BACK OKEY')
          navigationRef.goBack()
        } else {
          navigationLog('GO_BACK RELOAD:', window.location.href)
          window.history.replaceState(null, '', window.location.href)
        }
      } else {
        handleLocationChange('popstate')
      }
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('hashchange', () =>
        handleLocationChange('hashchange'),
      )
      navigationRef.removeListener('state', () => {
        handleLocationChange('navigationStateUpdate')
      })
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  return {
    currentRoute,
  }
}
