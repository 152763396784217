import { useState } from 'react'
import { View, TextInput, Image } from 'react-native'
import styles from './TicketSearchBarStyles'
import lupaIcon from '@/assets/Profile/lupa.png'
import CustomPressable from '../CustomPressable/CustomPressable'

const TicketSearchBar = ({
  placeHolder,
  searchTerm,
  setSearchTerm,
  onSearch,
  isLoading = false,
}) => {
  const [isValid, setIsValid] = useState(true)
  const CODE_NUM_LENGTH = 3
  const CODE_LENGTH = CODE_NUM_LENGTH + 1

  const validateInput = (text) => {
    const formattedText = text.replace(/\s+/g, '').toUpperCase()
    setSearchTerm(formattedText)
    if (formattedText.length === CODE_LENGTH) {
      const isValidFormat = /^[0-9]{3}[A-Z]$/.test(formattedText)
      setIsValid(isValidFormat)
    } else {
      setIsValid(true)
    }
  }

  return (
    <View style={isValid ? styles.container : styles.inputDisabled}>
      <TextInput
        style={styles.searchInput}
        placeholder={placeHolder}
        value={searchTerm}
        onChangeText={validateInput}
        autoComplete="off"
        spellCheck={false}
        maxLength={CODE_LENGTH}
      />
      <CustomPressable
        onPress={() => onSearch(searchTerm)}
        style={
          isValid && searchTerm.length === CODE_LENGTH
            ? styles.iconButton
            : styles.iconButtonDisabled
        }
        disabled={!isValid || searchTerm.length !== CODE_LENGTH}
        isLoading={isLoading}
      >
        <Image source={lupaIcon} style={styles.icon} resizeMode="contain" />
      </CustomPressable>
    </View>
  )
}

export default TicketSearchBar
