export const browsers = {
  CHROME: 'CHROME',
  SAFARI: 'SAFARI',
  FIREFOX: 'FIREFOX',
  SAMSUNG_INTERNET: 'SAMSUNG_INTERNET',
  OTHER: 'OTHER',
} as const

export const getBrowserType = () => {
  const userAgent = navigator.userAgent.toLowerCase()

  if (/chrome/.test(userAgent) && /android/.test(userAgent))
    return browsers.CHROME

  if (/safari/.test(userAgent) && /iphone|ipad/.test(userAgent))
    return browsers.SAFARI

  if (/firefox/.test(userAgent)) return browsers.FIREFOX

  if (/samsungbrowser/.test(userAgent)) return browsers.SAMSUNG_INTERNET

  return browsers.OTHER
}
