import { View, Text, Image } from 'react-native'
import styles from './EventCardStyles'
import EventLabel from '../EventLabel/EventLabel'
import { useAppDispatch } from '@/redux/store'
import { SET_SELECTED_EVENT_ID } from '@/redux/constants'
import { ParticipantStatus, TUserEventModel, UserEventRole } from '@/models'
import { CustomPressable } from '@/components'
import defaultEventPic from '@/assets/MyEvents/defaultPicEvent.png'
import { theme } from '@/theme'
import { navigateTo } from 'routes/utils'

const EventCard = ({ userEvent }: { userEvent: TUserEventModel }) => {
  const dispatch = useAppDispatch()

  const eventPicSource = userEvent?.flyer ? userEvent.flyer : defaultEventPic
  const isEventTeam = userEvent.role === UserEventRole.EVENT_TEAM

  const showVirtualRoomButton =
    userEvent.participantStatus === ParticipantStatus.WITH_TICKET
  // Con la premisa de que los miembro del equipo no pueden ingresar sin ser participantes
  //  || isEventTeam

  const opStatus = isEventTeam
    ? UserEventRole.EVENT_TEAM
    : userEvent.participantStatus
  return (
    <View
      style={[
        styles.backgroundImage,
        { borderRadius: 5 },
        theme.bgGradientCenter,
      ]}
    >
      <View style={styles.cardEvent} key={userEvent.eventId}>
        {/* <Image
          source={eventPicSource}
          style={styles.imageEventImg}
          resizeMode="cover"
          defaultSource={defaultEventPic}
        /> */}
        <img
          src={eventPicSource}
          alt="Foto del evento"
          style={styles.imageEventImg}
          loading="lazy"
        />
        <View style={styles.content}>
          <Text style={styles.eventName}>{userEvent?.eventName}</Text>
          <View style={styles.buttonDiv}>
            <CustomPressable
              onPress={() => {
                navigateTo(
                  'EventVirtualRoom',
                  {
                    eventId: userEvent.eventId,
                  },
                  // 'reset',
                )
              }}
              disabled={!showVirtualRoomButton}
              style={[
                styles.buttonVirtualRoom,
                !showVirtualRoomButton && styles.buttonDisabled,
              ]}
            >
              <Text style={styles.buttonText}>Sala virtual</Text>
            </CustomPressable>
            {isEventTeam ? (
              <CustomPressable
                onPress={() => {
                  navigateTo('AdminPanel', {
                    eventId: userEvent?.eventId,
                  })
                }}
                style={styles.buttonDetails}
              >
                <Text style={styles.buttonText}>Administrar</Text>
              </CustomPressable>
            ) : (
              <CustomPressable
                onPress={() => {
                  navigateTo('EventDetail', {
                    eventId: userEvent?.eventId,
                  })
                  dispatch({
                    type: SET_SELECTED_EVENT_ID,
                    payload: userEvent?.eventId,
                  })
                }}
                style={styles.buttonDetails}
              >
                <Text style={styles.buttonText}>Detalles</Text>
              </CustomPressable>
            )}
          </View>
        </View>
        <EventLabel opStatus={opStatus} />
      </View>
    </View>
  )
}

export default EventCard
