import { View, Text, Image, ActivityIndicator } from 'react-native'
import styles from './TicketCardStyles'
import { theme } from '@/theme'
import { TTicketDetailModel } from '@/models/ticket-detail.model'
import defaultEventPic from '@/assets/MyEvents/defaultPicEvent.png'

const TicketCard = ({
  ticketDetail,
}: {
  ticketDetail?: TTicketDetailModel
}) => {
  return (
    <>
      <View style={[styles.cardHeader, theme.bgGradientViolet]} />
      <View style={styles.cardBody}>
        <View style={[styles.backgroundImage, theme.bgGradientCenter]}>
          <View style={styles.imgContainer}>
            <Image
              source={
                ticketDetail?.eventFlyer
                  ? {
                      uri: ticketDetail?.eventFlyer,
                    }
                  : defaultEventPic
              }
              style={styles.flyer}
            />
          </View>
          {!ticketDetail?.eventName ? (
            <ActivityIndicator
              size="large"
              color="#ffffff"
              style={{ margin: 20 }}
            />
          ) : (
            <>
              <Text style={styles.titleEvent}>{ticketDetail?.eventName}</Text>
              {/* {ticketDetail?.eventProducers?.map?.((eventProducer) => (
                <Text style={styles.eventProducerName}>{eventProducer.name}</Text>
              ))} */}
              {/* <Text style={styles.producerName}>
                {ticketDetail?.eventProducers}
              </Text> */}
              {ticketDetail?.code && (
                <View style={styles.codeContainer}>
                  <Text style={styles.codeText}>{ticketDetail?.code}</Text>
                </View>
              )}
            </>
          )}
        </View>
      </View>
    </>
  )
}

export default TicketCard
