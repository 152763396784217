import { View, Text } from 'react-native'
import styles from './InfoCapsuleStyles'

const InfoCapsule = ({
  totalPendingParticipants,
  totalConfirmedTickets,
  totalAvailableTickets,
}: {
  totalPendingParticipants: number | null
  totalConfirmedTickets: number
  totalAvailableTickets: number
}) => {
  const showPendings = totalPendingParticipants !== null
  return (
    <>
      <View style={styles.labelInfoTickets}>
        {showPendings && <Text style={styles.labelText}>Pendientes</Text>}
        <Text style={styles.labelText}>Confirmados</Text>
        <Text style={styles.labelText}>Cupos Disp.</Text>
      </View>
      <View style={styles.infoTickets}>
        {showPendings && (
          <>
            <Text style={styles.currency}>{totalPendingParticipants}</Text>
            <View style={styles.line} />
          </>
        )}
        <Text style={styles.currency}>{totalConfirmedTickets}</Text>
        <View style={styles.line} />
        <Text style={styles.quantity}>{totalAvailableTickets}</Text>
      </View>
    </>
  )
}

export default InfoCapsule
