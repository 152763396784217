import {
  ScreenLayout,
  PeopleList,
  SmallVincufyButton,
  Loader,
} from '@/components'
import { useEventBondsController } from './controllers/useEventBondsController'
import { VirtualRoomNavigatorParamList } from 'routes/Navigators'
import { TParticipantDetailModel } from '@/models'
import { navigateTo, useAppRouteParams, goBackTo } from 'routes/utils'

const EventBonds = () => {
  const params = useAppRouteParams<
    VirtualRoomNavigatorParamList,
    'EventBonds'
  >()

  const {
    eventBondsRequest,
    eventBondsLoading,
    getEventBonds,
    acceptBondRequest,
  } = useEventBondsController(params?.eventId)

  if (eventBondsLoading || !params?.eventId) return <Loader />

  const goToParticipantDetail = (participantId: string) => {
    navigateTo('AdminPanelParticipant', {
      eventId: params.eventId,
      participantId,
    })
  }

  return (
    <ScreenLayout
      goBackFunc={() =>
        goBackTo('EventVirtualRoom', { eventId: params.eventId })
      }
    >
      <PeopleList
        participants={eventBondsRequest ?? []}
        empty={'Ninguna solicitud de vinculación pendiente'}
        onRefresh={getEventBonds}
        goToDetailFunc={goToParticipantDetail}
        PrimaryButton={({
          participant,
        }: {
          participant: TParticipantDetailModel
        }) => (
          <SmallVincufyButton
            buttonType={'Aceptar'}
            participant={participant}
            onClick={(participant: TParticipantDetailModel) =>
              acceptBondRequest(participant.bond?.id)
            }
          />
        )}
      />
    </ScreenLayout>
  )
}

export default EventBonds
