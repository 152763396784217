import { isProdEnv } from '@/config/envs'

const HIDE_DEVLOGS = {
  // Setear a gusto
  ALL: false,
  API_MIDDLEWARE: false,
  NAVIGATION: false,
  I_AUTH: false,
  I_NOTIS: false,
  I_FETCH: false,
}

export const devLog = (...message) => {
  if (HIDE_DEVLOGS.ALL) return
  if (!isProdEnv()) {
    console.log('LOG', ...message)
  }
}

export const apiLog = (...message) => {
  if (HIDE_DEVLOGS.API_MIDDLEWARE) return
  devLog('API', ...message)
}
export const navigationLog = (...message) => {
  if (HIDE_DEVLOGS.NAVIGATION) return
  devLog('NAV', ...message)
}
export const notisLog = (...message) => {
  if (HIDE_DEVLOGS.I_NOTIS) return
  devLog('I_NOTIS', ...message)
}
export const fetchLog = (...message) => {
  if (HIDE_DEVLOGS.I_FETCH) return
  devLog('I_FETCH', ...message)
}
export const authLog = (...message) => {
  if (HIDE_DEVLOGS.I_AUTH) return
  devLog('I_AUTH', ...message)
}
