import { useEffect } from 'react'
import { StatusBar } from 'expo-status-bar'
import Navigation from './routes/Navigation'
import { Provider } from 'react-redux'
import { store } from '@/redux/store'
import { ModalProvider } from '@/components/Modals/context/ModalContext'
import qrVincufy from 'assets/qrVincufy2.PNG'
import logoVincufy from 'assets/logoHeaderVincufy.png'
import { isDesktop } from '@/utils'

// Importar HashRouter de react-router-dom
import { HashRouter } from 'react-router-dom'

const App = () => {
  // const linking = {
  //   prefixes: ['https://vincufy.com', 'http://localhost:19006'],
  //   config: {
  //     screens: {
  //       EventsNavigator: {
  //         screens: {
  //           EventDetail: 'evento/:eventId',
  //           MyTickets: 'evento/:eventId/tickets',
  //           TransitionScreen: 'evento/:eventId/tickets/resultado',
  //           EventVirtualRoom: 'evento/:eventId/sala-virtual',
  //           EventBonds: 'evento/:eventId/sala-virtual/solicitudes',
  //           AdminPanel: 'evento/admin/:eventId',
  //           TicketControll: 'evento/admin/:eventId/scan-ticket',
  //           ParticipantDetail: 'participante/:participantId',
  //         },
  //       },
  //       MyEvents: '/',
  //     },
  //   },
  // }

  useEffect(() => {
    if (isDesktop()) {
      // Si es una computadora, muestra un mensaje o redirige
      document.body.innerHTML = `
        <div style="display: flex; font-family: Verdana, Geneva, sans-serif;justify-content: center; align-items: center; height: 100vh; width: 100vw; text-align: center; flex-direction: column;">
          <div style="background-color:black; width:100%;height:100vh">
            <img src="${logoVincufy}" alt="Acceso Restringido" style="height: 8%; margin-bottom: 60px;margin-top:130px">
            <h1 style="font-size: 1.6rem; color:white; margin-bottom: 20px;">Red social para eventos</h1>
            <p style="font-size: 1.15rem;color:white;">Hagamos juntos tu evento realidad, probá nuestra ticketera y salas virtuales.</p>
            <p style="font-size: 1.15rem;color:white; margin-bottom: 30px;">Continuá desde tu celular escaneando este QR.</p>
            <img src="${qrVincufy}" alt="Acceso Restringido" style="max-width: 300px; height: 20%; border-radius: 5px; margin-bottom: 20px;">
          </div>  
        </div>`
    }
  }, [])

  return (
    <Provider store={store}>
      <ModalProvider>
        <StatusBar style="light" />
        <HashRouter>
          <Navigation />
        </HashRouter>
      </ModalProvider>
    </Provider>
  )
}

export default App
