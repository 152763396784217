import { View, Text, Image } from 'react-native-web'
import styles from './EventCardStyles'
import { TUserEventModel } from '@/models'
import { theme } from '@/theme'
import defaultEventPic from '@/assets/MyEvents/defaultPicEvent.png'

const EventCard = ({ userEvent }: { userEvent?: TUserEventModel }) => {
  return (
    <View style={styles.container}>
      <View style={[styles.backgroundImage, theme.bgGradientRight]}>
        <View style={styles.flyerDiv}>
          <Image
            source={{ uri: userEvent?.flyer || defaultEventPic }}
            style={styles.flyerImage}
          />
        </View>
        <Text style={styles.title}>{userEvent?.eventName}</Text>
      </View>
    </View>
  )
}

export default EventCard
