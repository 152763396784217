import { View, Text, Modal } from 'react-native'
import styles from './ConfirmModalStyles'
import CustomPressable from '@/components/CustomPressable/CustomPressable'
import { useState } from 'react'
import { theme } from '@/theme'

export type TConfirmModal = {
  text: string
  confirmFunc: () => Promise<void>
  oneButton?: boolean
  primaryButtonText?: string
} | null

export const ConfirmModal = ({
  confirmModal,
  setConfirmModal,
  oneButton = false,
  primaryButtonText = 'Aceptar',
}: {
  confirmModal: TConfirmModal
  setConfirmModal: (v: TConfirmModal) => void
  oneButton?: boolean
  primaryButtonText?: string
}) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Modal
      transparent={true}
      visible={confirmModal !== null}
      animationType="fade"
    >
      <View style={styles.background}>
        <View style={[styles.modal, theme.bgGradientCenter]}>
          <Text style={styles.text}>{confirmModal?.text}</Text>
          <View style={styles.buttonContainer}>
            <CustomPressable
              style={styles.btn}
              onPress={async () => {
                setIsLoading(true)
                await confirmModal?.confirmFunc?.()
                setConfirmModal(null)
                setIsLoading(false)
              }}
              isLoading={isLoading}
            >
              <Text style={styles.btnText}>{primaryButtonText}</Text>
            </CustomPressable>
            {!oneButton && (
              <CustomPressable
                style={styles.btnCancel}
                onPress={() => setConfirmModal(null)}
              >
                <Text style={styles.btnText}>Cancelar</Text>
              </CustomPressable>
            )}
          </View>
        </View>
      </View>
    </Modal>
  )
}
