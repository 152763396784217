import { StyleSheet, Dimensions } from 'react-native-web'
const { width, height } = Dimensions.get('window')

export default StyleSheet.create({
  carouselContainer: {
    height: height / 2.5,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative', // Agregar esto para asegurar que los elementos internos se posicionen correctamente
    width: width,
    backgroundColor: '#7f7f7f',
  },
  imageContainer: {
    width: Dimensions.get('window').width, // Asegura que cada imagen ocupe el ancho de la pantalla
    height: height / 2.5,
    position: 'relative',
  },

  image: {
    width: Dimensions.get('window').width, // Define explícitamente el ancho
    height: '100%',
  },

  buttonsContainer: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
    zIndex: 1,
  },
  editButton: {
    width: 40,
    height: 40,
    marginRight: 10,
  },
  indicatorContainer: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 10,
  },
  indicator: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: '#888',
    marginHorizontal: 5,
  },
  activeIndicator: {
    backgroundColor: '#333',
  },
  picCounter: {
    position: 'absolute',
    top: 10,
    right: 15,
    zIndex: 99999,
    color: 'white',
  },
})
