import { setDeviceNotificationsStatus } from '@/redux/thunks'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { useState } from 'react'
import { allowNotificationsScreens, deviceNotificationsStatuses, notificationPermission } from '@/models/notification.model'
import { notisLog } from '@/utils/devLog'

export const useAllowNotificationsController = () => {
    const dispatch = useAppDispatch()
    const setupStatus = useAppSelector((state) => state.user.deviceNotificationsStatus)

    const [isLoadingAllowNotifications, setIsLoadingAllowNotifications] = useState(false)
    const [allowNotificationsError, setAllowNotificationsError] = useState(null)

    const screenToShowLogic = () => {
        if (setupStatus === deviceNotificationsStatuses.ERROR) return allowNotificationsScreens.NO_NOTIFICATIONS
        if (setupStatus === deviceNotificationsStatuses.PERMISSION_GRANTED) return allowNotificationsScreens.PERMISSION_GRANTED
        if (setupStatus === deviceNotificationsStatuses.NEED_PERMISSION) {
            if (Notification.permission === notificationPermission.GRANTED) dispatch(setDeviceNotificationsStatus(deviceNotificationsStatuses.PERMISSION_GRANTED))
            if (Notification.permission === notificationPermission.DENIED) return allowNotificationsScreens.ASK_PERMISSION_INSTRUCTIONS
            return allowNotificationsScreens.ASK_PERMISSION_BUTTON
        }
        return allowNotificationsScreens.PERMISSION_GRANTED
    }

    let screenToShow = screenToShowLogic()

    const handleClickContinueWithNoNotifications = () => {
        dispatch(setDeviceNotificationsStatus(deviceNotificationsStatuses.FINISHED))
    }

    const handleClickAskForAllowNotifications = async () => {
        setIsLoadingAllowNotifications(true)
        setAllowNotificationsError(null)
        try {
            if (Notification.permission === notificationPermission.DENIED) {
                throw new Error()
            }

            const permission = await Notification.requestPermission();

            if (permission === notificationPermission.GRANTED) {
                dispatch(setDeviceNotificationsStatus(deviceNotificationsStatuses.PERMISSION_GRANTED))
                return
            }

            throw new Error()
        } catch (error) {
            setAllowNotificationsError("Permiso no concedido, reintente...")
            notisLog("Error allowing notifications.", error)
            screenToShow = screenToShowLogic()
        } finally {
            setIsLoadingAllowNotifications(false)
        }
    }


    return {
        screenToShow,
        handleClickContinueWithNoNotifications,
        handleClickAskForAllowNotifications,
        isLoadingAllowNotifications,
        allowNotificationsError,
    }
}
