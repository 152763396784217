import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  contentInfoRows: {
    flexDirection: 'column',
    gap: 10,
    width: '90%',
    paddingBottom: 40,
  },
  label: {
    color: 'white',
    fontSize: 17,
  },
  descriptionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'rgb(32, 32, 32)',
    borderRadius: 8,
    minHeight: 65,
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  descriptionText: {
    color: 'white',
    fontWeight: '200',
    lineHeight: 25,
    fontSize: 17,
  },
})

export default styles
