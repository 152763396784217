import { useState, useCallback } from 'react'
import { useFocusEffect } from '@react-navigation/native' // Asegúrate de importar esto
import styles from './ParticipantsGridStyles'
import { useParticipantsGridController } from './controllers/useParticipantsGridController'
import { CustomRefreshControl } from '@/components'
import { SetContactMethodModal } from '@/components'
import {
  View,
  FlatList,
  ActivityIndicator,
  Text,
  NativeSyntheticEvent,
  NativeScrollEvent,
} from 'react-native'
import { TParticipantDetailModel } from '@/models'
import ProfilePic from '../ProfilePic/ProfilePic'
import { navigateTo } from 'routes/utils'

const ParticipantsGrid = ({ eventId }: { eventId: string }) => {
  const {
    participantsId,
    participantsError,
    userProfile,
    isLoading,
    isLoadingMore,
    fetchMoreParticipants,
    refreshParticipants,
  } = useParticipantsGridController(eventId)

  const [isAtBottom, setIsAtBottom] = useState(false)
  const [isOpenContactModal, setIsOpenContactModal] = useState(false)

  useFocusEffect(
    useCallback(() => {
      if (
        userProfile?.contactMethods &&
        Object.keys(userProfile?.contactMethods)?.length === 0
      ) {
        setIsOpenContactModal(true)
      } else {
        setIsOpenContactModal(false)
      }
    }, [userProfile]),
  )

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent

    const isBottom =
      layoutMeasurement.height + contentOffset.y >= contentSize.height - 20
    if (isBottom && !isAtBottom) {
      setIsAtBottom(true)
      fetchMoreParticipants()
    } else if (!isBottom) {
      setIsAtBottom(false)
    }
  }

  const goToDetail = (
    participantId: TParticipantDetailModel['participantId'],
  ) => {
    navigateTo('EventVirtualRoomParticipant', {
      eventId,
      participantId,
    })
  }

  const getContent = () => {
    // if (isLoading) return <ActivityIndicator size="large" color="#ffffff" />

    if (participantsError && !isLoading && !isLoadingMore)
      return <Text style={styles.error}>{participantsError}</Text>

    if (!participantsId?.length && !isLoading && !isLoadingMore)
      return (
        <Text style={styles.text}>No hay participantes del evento aún.</Text>
      )

    return (
      <>
        <FlatList
          data={participantsId}
          renderItem={({ item }) => (
            <ProfilePic participantId={item} goToDetail={goToDetail} />
          )}
          keyExtractor={(item) => item}
          numColumns={3}
          refreshControl={
            <CustomRefreshControl
              onRefresh={refreshParticipants}
              isLoadingFetch={isLoading}
            />
          }
          onScroll={handleScroll}
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={() =>
            !isLoadingMore ? (
              <View style={styles.footerList} />
            ) : (
              <ActivityIndicator
                style={styles.footerListLoading}
                size="small"
                color="#ffffff"
              />
            )
          }
          initialNumToRender={16}
        />
        <SetContactMethodModal
          isOpenContactModal={isOpenContactModal}
          setIsOpenContactModal={setIsOpenContactModal}
        />
      </>
    )
  }

  return <View style={styles.container}>{getContent()}</View>
}

export default ParticipantsGrid
