import { View, Text, Modal, Image } from 'react-native-web'
import { theme } from '@/theme'
import { CustomPressable } from '@/components'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import styles from './AuthModalStyles'
import { useState } from 'react'
import LoginForm from '../../AuthForm/LoginForm'
import FullRegisterForm from '../../AuthForm/FullRegisterForm'
import logoVincufy from '@/assets/Header/logoVincufy.png'
import { TLoggedOrigin } from '@/redux/reducers/auth.reducer'

const AuthModal = ({
  isOpen,
  close,
  fromFunctionality,
}: {
  isOpen: boolean
  close: () => void
  fromFunctionality?: TLoggedOrigin
}) => {
  const [showRegister, setShowRegister] = useState(true) // or Login

  return (
    <Modal transparent={true} visible={isOpen} animationType="fade">
      <View style={styles.background}>
        <View style={[styles.modal, theme.bgGradientCenter]}>
          <CustomPressable style={styles.closeButton} onPress={close}>
            <Text style={styles.closeButtonText}>✕</Text>
          </CustomPressable>
          <Image
            source={logoVincufy}
            style={styles.logoVincufy}
            resizeMode="contain"
          />
          <form style={styles.form}>
            <KeyboardAwareScrollView
              contentContainerStyle={styles.scroll}
              keyboardShouldPersistTaps="handled"
            >
              {showRegister ? (
                <FullRegisterForm
                  goToLogin={() => setShowRegister(false)}
                  fromFunctionality={fromFunctionality}
                />
              ) : (
                <LoginForm
                  goToRegister={() => setShowRegister(true)}
                  fromFunctionality={fromFunctionality}
                />
              )}
            </KeyboardAwareScrollView>
          </form>
        </View>
      </View>
    </Modal>
  )
}

export default AuthModal
