import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  passwordContainer: {
    width: '100%',
    maxWidth: 343,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  showHideButton: {
    position: 'absolute',
    right: 15,
    height: 20,
    bottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    height: 20,
    width: 20,
  },
  iconErrors: {
    height: 20,
    width: 20,
    tintColor: 'red',
  },
})
