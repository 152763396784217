import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { setShouldHideTabBar, getAdminEventInfo } from '@/redux/thunks'
import { TAdminEventInfoModel } from '@/models/admin-event-info.model'
import { TUserEventModel, UserEventRole } from '@/models'
import { AdminPanelNavigatorParamList } from 'routes/Navigators'
import { getUrlParams, useAppRouteParams } from 'routes/utils'

export const useAdminPanelController = () => {
  const dispatch = useAppDispatch()
  const navParams = useAppRouteParams<
    AdminPanelNavigatorParamList,
    'AdminPanel'
  >()
  const [routeParams, setRouteParams] = useState<
    Partial<AdminPanelNavigatorParamList['AdminPanel']>
  >({})
  const params = {
    eventId: navParams?.eventId || routeParams?.eventId || '',
  }
  useEffect(() => {
    const [urlParamEventId] = getUrlParams('AdminPanel')

    setRouteParams({
      eventId: urlParamEventId,
    })
  }, [])

  const userEventInfo = useAppSelector((state) =>
    !params.eventId
      ? undefined
      : state.event.userEvents.find(
          (event) => event.eventId === params.eventId,
        ),
  )
  const adminEventInfo = useAppSelector((state) => state.event.adminEventInfo)

  const [eventHeaderInfo, setEventHeaderInfo] =
    useState<TUserEventModel | null>(null)
  const [finalAdminEventInfo, setFinalAdminEventInfo] =
    useState<TAdminEventInfoModel | null>(null)

  const [totalPendingParticipants, setTotalPendingParticipants] = useState<
    number | null
  >(0) // PENDING Participants
  const [totalConfirmedTickets, setTotalConfirmedTickets] = useState(0) // Tickets given with Buyer with WITH_TICKET
  const [totalAvailableTickets, setTotalAvailableTickets] = useState(0)

  useEffect(() => {
    dispatch(setShouldHideTabBar(true))
  }, [])

  useEffect(() => {
    if (
      params.eventId &&
      userEventInfo?.eventId === params.eventId &&
      !eventHeaderInfo?.eventName
    ) {
      setEventHeaderInfo(userEventInfo)
    }
  }, [params.eventId, userEventInfo, eventHeaderInfo])

  useEffect(() => {
    if (!params.eventId) return
    if (adminEventInfo?.eventId !== params.eventId) {
      dispatch(getAdminEventInfo(params.eventId))
    } else {
      if (!eventHeaderInfo?.eventName) {
        setEventHeaderInfo({
          ...adminEventInfo,
          role: UserEventRole.EVENT_TEAM,
          participantStatus: null,
        })
      }

      let newTotalPendingParticipants = 0
      let newTotalConfirmedTickets = 0
      let hasTicketsRequest = false

      adminEventInfo.eventTicketTypes.forEach((ticketType) => {
        if (ticketType.hasTicketsRequest) hasTicketsRequest = true
        newTotalPendingParticipants +=
          adminEventInfo.ticketTypesParticipants[ticketType.id].PENDING
            .length || 0
        newTotalConfirmedTickets += ticketType.ticketsGiven || 0
      })

      setTotalPendingParticipants(
        !hasTicketsRequest ? null : newTotalPendingParticipants,
      )
      setTotalConfirmedTickets(newTotalConfirmedTickets)
      setTotalAvailableTickets(
        adminEventInfo.ticketsLimit - newTotalConfirmedTickets,
      )

      setFinalAdminEventInfo(adminEventInfo)
    }
  }, [params.eventId, adminEventInfo])

  const refreshAdminEventInfo = () => {
    if (params.eventId) {
      dispatch(getAdminEventInfo(params.eventId))
    }
  }

  return {
    params,
    eventHeaderInfo,
    adminEventInfo: finalAdminEventInfo,
    totalPendingParticipants,
    totalConfirmedTickets,
    totalAvailableTickets,
    refreshAdminEventInfo,
  }
}
