import { View, Text } from 'react-native'
import { ScreenLayout, Loader } from '@/components'
import { useMyTicketsController } from './controllers/useMyTicketsController'
import styles from './MyTicketsStyles'
import TicketCard from './TicketCard/TicketCard'
import { theme } from '@/theme'
import { goBackTo } from 'routes/utils'

const MyTickets = () => {
  const { userEventTickets, eventId, isLoading } = useMyTicketsController()
  return (
    <ScreenLayout goBackFunc={() => goBackTo('EventDetail', { eventId })}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <View style={styles.titleContainer}>
            <View style={[styles.bkgImage, theme.bgGradientRight]}>
              <Text style={styles.titleText}>Entradas del evento</Text>
            </View>
          </View>
          <View style={styles.cardContainer}>
            <TicketCard ticketDetail={userEventTickets[0]} />
          </View>
        </>
      )}
    </ScreenLayout>
  )
}

export default MyTickets
