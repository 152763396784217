import { StyleSheet, Dimensions } from 'react-native'
const { width } = Dimensions.get('window')

const styles = StyleSheet.create({
  cardEvent: {
    borderBottomLeftRadius: 15,
    borderTopLeftRadius: 15,
    minHeight: 100,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  backgroundImage: {
    minHeight: 100,
    overflow: 'hidden',
  },
  content: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
  },
  imageEventImg: {
    height: 80,
    minHeight: 80,
    width: 80,
    minWidth: 80,
    backgroundColor: '#383838',
    borderRadius: 5,
    marginLeft: 11,
  },
  eventName: {
    color: 'white',
    fontWeight: '200',
    fontSize: 16,
  },
  buttonDiv: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: width * 0.023,
  },
  buttonVirtualRoom: {
    backgroundColor: '#8811c8',
    borderRadius: 7,
    padding: 8,
    width: width * 0.23,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonDetails: {
    backgroundColor: 'black',
    borderRadius: 7,
    padding: 8,
    width: width * 0.23,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: 12,
  },
  buttonDisabled: {
    backgroundColor: '#807884',
    borderRadius: 7,
    padding: 8,
    width: width * 0.23,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default styles
