import styles from './TransitionScreenStyles'
import PendingFeedback from './PendingFeedback/PendingFeedback'
import { useEffect, useState } from 'react'
import { setShouldHideTabBar } from '@/redux/thunks'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { View, Text } from 'react-native'
import { CustomPressable, Loader } from '@/components'
import {
  getUrlParams,
  navigateTo,
  PAGES_NAV,
  useAppRouteParams,
} from 'routes/utils'
import { fetchStatus, ParticipantStatus } from '@/models'
import { navigationRef } from 'routes/Navigation'
import { EventDetailNavigatorParamList } from 'routes/Navigators'
import { resetNavTo } from 'routes/utils/resetNavTo'

const TransitionScreen = () => {
  const dispatch = useAppDispatch()
  const navParams = useAppRouteParams<
    EventDetailNavigatorParamList,
    'TransitionScreen'
  >()
  const [routeParams, setRouteParams] = useState<
    Partial<EventDetailNavigatorParamList['TransitionScreen']>
  >({})
  const params = {
    eventId: navParams?.eventId || routeParams?.eventId || '',
  }
  useEffect(() => {
    const [urlParamEventId] = getUrlParams('TransitionScreen')

    setRouteParams({
      eventId: urlParamEventId,
    })
  }, [])

  const requestFreeTicket = useAppSelector(
    (state) => state.event.requestFreeTicket,
  )
  const requestFreeTicketError = useAppSelector(
    (state) => state.event.requestFreeTicketError,
  )
  const requestFreeTicketStatus = useAppSelector(
    (state) => state.event.requestFreeTicketStatus,
  )

  useEffect(() => {
    dispatch(setShouldHideTabBar(true))
  }, [])

  const enterToApp = () => {
    navigateTo('MyEvents', {}, 'reset')
  }

  const handleGoBack = () => {
    if (navigationRef.canGoBack()) {
      navigationRef.goBack()
    } else {
      // navigateTo('MyEvents', {})
      resetNavTo(
        {
          index: 0,
          routes: [
            {
              name: 'MyTabsNavigator',
              params: {
                screen: 'EventsNavigator',
                state: {
                  index: 1,
                  routes: [
                    {
                      name: PAGES_NAV.MyEvents.name,
                      params: undefined,
                    },
                    {
                      name: PAGES_NAV.EventDetail.name,
                      params: {
                        eventId: params.eventId,
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
        'EventDetail',
        {
          eventId: params.eventId,
        },
      )
    }
  }

  if (requestFreeTicketStatus === fetchStatus.LOADING) return <Loader />
  if (!!requestFreeTicketError || !requestFreeTicket)
    return (
      <View style={styles.container}>
        <Text style={styles.btnText}>
          Ya solicitaste tu ticket! ingresá con tu usuario para continuar
        </Text>
        <CustomPressable
          onPress={() => navigateTo('Login', {}, 'force')}
          style={styles.btn}
        >
          <Text style={styles.btnText}>Iniciar sesión</Text>
        </CustomPressable>
      </View>
    )

  const { participant, tickets, receipt } = requestFreeTicket

  return (
    <View style={styles.container}>
      {participant.status === ParticipantStatus.PENDING ||
      participant.status === ParticipantStatus.REJECTED ? (
        <PendingFeedback />
      ) : (
        <Text style={styles.btnText}>Ya tienes acceso!</Text>
      )}
      <CustomPressable onPress={() => enterToApp()} style={styles.btn}>
        <Text style={styles.btnText}>Continuar</Text>
      </CustomPressable>
    </View>
  )
}

export default TransitionScreen
