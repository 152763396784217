import { StyleSheet, Dimensions } from 'react-native'

const { width } = Dimensions.get('window') // Obtener dimensiones de la pantalla

export default StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    width: width * 0.9,
    minHeight: '22%',
    padding: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 45,
  },
  backgroundImage: {
    borderRadius: 10,
  },
  text: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
    lineHeight: 25,
  },
  btn: {
    backgroundColor: '#9459CE',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    width: 110,
    alignItems: 'center',
  },
  btnText: {
    color: 'white',
    fontSize: 14,
  },
  noContactText: {
    color: 'white',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 15,
    zIndex: 1,
  },
  closeButtonText: {
    fontSize: 25,
    fontWeight: '200',
    color: 'white',
  },
  contactMethod: {
    alignItems: 'center',
    marginVertical: 10,
    width: '45%',
    marginBottom: 20,
  },
  icon: {
    width: 50,
    height: 50,
    marginBottom: 5,
  },
  methodText: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
    marginTop: 5,
  },
  contactMethodsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  },
})
