import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { MyEvents } from '@/views'
import { NavigatorScreenParams } from '@react-navigation/native'
import {
  EventDetailNavigator,
  EventDetailNavigatorParamList,
} from './pages/EventDetailNavigator'
import {
  VirtualRoomNavigator,
  VirtualRoomNavigatorParamList,
} from './pages/VirtualRoomNavigator'
import {
  AdminPanelNavigator,
  AdminPanelNavigatorParamList,
} from './pages/AdminPanelNavigator'

export type EventsNavigatorParamList = {
  MyEvents: {}
  EventDetailNavigator: NavigatorScreenParams<EventDetailNavigatorParamList>
  VirtualRoomNavigator: NavigatorScreenParams<VirtualRoomNavigatorParamList>
  AdminPanelNavigator: NavigatorScreenParams<AdminPanelNavigatorParamList>
}

const EventsStack = createNativeStackNavigator<EventsNavigatorParamList>()

export const EventsNavigator = () => {
  return (
    <EventsStack.Navigator
      initialRouteName={'MyEvents'}
      screenOptions={{ headerShown: false }}
    >
      <EventsStack.Screen
        name="MyEvents"
        component={MyEvents}
        options={{ title: 'Eventos - Vincufy' }}
      />
      <EventsStack.Screen
        name="EventDetailNavigator"
        component={EventDetailNavigator}
      />
      <EventsStack.Screen
        name="VirtualRoomNavigator"
        component={VirtualRoomNavigator}
      />
      <EventsStack.Screen
        name="AdminPanelNavigator"
        component={AdminPanelNavigator}
      />
    </EventsStack.Navigator>
  )
}
