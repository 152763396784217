import { useState } from 'react'
import { View, Text, FlatList, Platform } from 'react-native'
import styles from './SelectRelationStatusStyle'
import useKeyboardVisible from '@/utils/UseKeyboardVisible'
import { CustomModal, CustomPressable } from '@/components'
import { theme } from '@/theme'

const SelectRelationStatus = ({
  sentimentalSituation,
  setSentimentalSituation,
}) => {
  const { isKeyboardVisible } = useKeyboardVisible()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const status = [
    { label: 'Soltero/a', value: 'single' },
    { label: 'Relación abierta', value: 'open' },
    { label: 'En pareja', value: 'couple' },
    { label: 'Casado/a', value: 'married' },
    { label: 'No mostrar estado sentimental', value: 'hide' },
  ]

  const buttonStyle = [
    theme.input,
    {
      marginBottom: Platform.OS === 'ios' && (!isKeyboardVisible ? 25 : 285),
    },
  ]

  const [selectedStatus, setSelectedStatus] = useState(
    sentimentalSituation || status[status.length - 1],
  )

  const handleSelectStatus = (selectedItem) => {
    setSelectedStatus(selectedItem)
    setSentimentalSituation(selectedItem)
    setIsModalOpen(false)
  }

  const renderItem = ({ item }) => (
    <CustomPressable
      style={[
        styles.statusItem,
        {
          backgroundColor:
            item.value === selectedStatus.value ? '#9459CE' : 'black',
        },
      ]}
      onPress={() => handleSelectStatus(item)}
    >
      <Text style={styles.statusText}>{item.label}</Text>
    </CustomPressable>
  )

  return (
    <>
      <CustomPressable onPress={() => setIsModalOpen(true)} style={buttonStyle}>
        <Text style={styles.openButtonText}>
          {selectedStatus.label || 'Situación sentimental'}
        </Text>
      </CustomPressable>
      <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <View style={styles.modalContent}>
          <FlatList
            data={status}
            renderItem={renderItem}
            keyExtractor={(item) => item.value}
            style={styles.flatList}
          />
        </View>
      </CustomModal>
    </>
  )
}

export default SelectRelationStatus
