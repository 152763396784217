import { useAppDispatch, useAppSelector } from '@/redux/store'
import { getUserEvents, setShouldHideTabBar } from '@/redux/thunks'
import { LOADING } from '@/redux/constants'
import { fetchStatus } from '@/models'
import { useEffect } from 'react'

export const useMyEventsModel = () => {
  const dispatch = useAppDispatch()
  const userEvents = useAppSelector((state) => state.event.userEvents || [])
  const userEventsStatus = useAppSelector(
    (state) => state.event.userEventsStatus,
  )
  const userEventsError = useAppSelector((state) => state.event.userEventsError)
  const isLoading = useAppSelector((state) => state.auth.isLoading)

  const fetchEventsData = async () => {
    if (userEventsStatus !== fetchStatus.LOADING) {
      await dispatch(getUserEvents())
    }
  }

  const setIsLoading = (state: boolean) => {
    dispatch({
      type: LOADING,
      state,
    })
  }

  useEffect(() => {
    dispatch(setShouldHideTabBar(false))
  }, [])

  return {
    userEvents,
    fetchEventsData,
    isLoading,
    setIsLoading,
    backError: userEventsError,
  }
}
