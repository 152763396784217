import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({
  placeholderText: {
    marginLeft: '10px',
    marginRight: '40px',
    flex: 1,
    fontWeight: '600',
  },
  searchIconContainer: {
    padding: 10,
    position: 'absolute',
    right: 0,
  },
  searchIcon: {
    width: 20,
    height: 20,
  },
  searchIconOpen: {
    width: 20,
    height: 20,
  },

  modalOverlay: {
    backgroundColor: 'black',
    alignItems: 'initial',
    justifyContent: 'initial',
  },
  searchbar: {
    marginTop: Platform.OS === 'ios' ? 70 : 40,
    paddingHorizontal: 15,
  },
  resultText: {
    color: 'red',
  },
});

const googleStyles = {
  textInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'black',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    position: 'relative',
    marginBottom: 0,
  },
  textInput: {
    flex: 1,
    height: 40,
    paddingLeft: '12px',
    paddingRight: '40px',
    margin: 0,
    color: 'white',
    fontWeight: '300',
    backgroundColor: 'transparent',
  },
  predefinedPlacesDescription: {
    color: 'white',
  },
}

export { googleStyles, styles }