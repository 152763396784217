//Response para la pantalla EventDetails
import defaultEventPic from '@/assets/MyEvents/defaultPicEvent.png'
import {
  EventType,
  EventVisualizationType,
  ParticipantStatus,
  UserEventRole,
} from '@/models'
import { TEventDetailModel } from '@/models'

export const eventDetailMock: TEventDetailModel = {
  eventProducers: [
    {
      id: '1', //Tabla EventProducer
      name: 'Sugus Producciones', //Tabla EventProducer
    },
  ],

  eventId: '820dbbf1-314a-4130-a249-a1bf3439eacc',
  eventName: 'Fiesta de Sugus',
  description:
    'Un evento de emprendedores tecnológicos de la ciudad de Córdoba, enfocada en conectar profesionales apasionados por la innovación y el desarrollo tecnológico. Aquí se comparten ideas, se generan colaboraciones y se promueve el crecimiento de startups locales a través de eventos, talleres y networking.',
  flyer: defaultEventPic,
  location: {
    id: '2',
    name: 'Cordoba',
  },
  type: EventType.EVENT,
  visualization: EventVisualizationType.PRIVATE,
  startDate: '2024-09-26T21:00:00.000',
  endDate: '2024-09-27T05:00:00.000',
  ticketsLimit: 100,
  userTicketsLimit: 1,

  virtualRoomStartAt: '2025-03-11T23:20:00.000', //Tabla virtual room
  virtualRoomEndAt: '2025-03-13T21:00:00.000', //Tabla virtual room

  eventTicketTypes: [
    {
      id: '1',
      ticketName: 'Ingreso Gratuito',
      description: 'Ten acceso a la mejor fiesta del año y su previa virtual.',
      price: '0',
      hasTicketsRequest: true,
      ticketsGiven: 3,
      ticketsLimit: 100,
      userTicketsLimit: 1,
    },
  ],

  participantStatus: null,
  role: UserEventRole.NOTHING,
}
