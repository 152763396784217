export const myBondsMock = [
  {
    eventName: 'Despedida Leonel',
    eventId: 1,
    bonds: [
      {
        userId: 2,
        name: 'root2',
        lastName: 'Root',
        description: 'Buscando amigos para salir de fiesta',
        age: 36,
        city: 'La Plata',
        profilePictures: [
          'https://firebasestorage.googleapis.com/v0/b/vincufy-34e1a.appspot.com/o/29e15344-f0cf-4f13-8a5c-f7caa29d3a54_photo_1729062411531.png?alt=media&token=be59cb8f-df15-48eb-ba72-381f73cf30a8',
        ],
        contactMethods: {
          mail: 'admin2@root.com',
        },
        Bond: {
          userId: 1,
          bondId: 2,
          createdAt: '2024-11-24T18:09:52.127Z',
          updatedAt: '2024-11-24T18:09:52.127Z',
        },
      },
    ],
  },
  {
    eventName: 'Poolparty José',
    eventId: 2,
    bonds: [
      {
        userId: 3,
        name: 'root3',
        lastName: 'Root',
        description: 'Buscando amigos para salir de fiesta',
        age: 36,
        city: 'La Plata',
        profilePictures: [
          'https://firebasestorage.googleapis.com/v0/b/vincufy-34e1a.appspot.com/o/29e15344-f0cf-4f13-8a5c-f7caa29d3a54_photo_1729062411531.png?alt=media&token=be59cb8f-df15-48eb-ba72-381f73cf30a8',
        ],
        contactMethods: {
          mail: 'admin3@root.com',
        },
        Bond: {
          userId: 1,
          bondId: 2,
          createdAt: '2024-11-24T18:09:52.127Z',
          updatedAt: '2024-11-24T18:09:52.127Z',
        },
      },
      {
        userId: 2,
        name: 'root2',
        lastName: 'Root',
        description: 'Buscando amigos para salir de fiesta',
        age: 36,
        city: 'La Plata',
        profilePictures: [
          'https://firebasestorage.googleapis.com/v0/b/vincufy-34e1a.appspot.com/o/29e15344-f0cf-4f13-8a5c-f7caa29d3a54_photo_1729062411531.png?alt=media&token=be59cb8f-df15-48eb-ba72-381f73cf30a8',
        ],
        contactMethods: {
          mail: 'admin2@root.com',
        },
        Bond: {
          userId: 1,
          bondId: 2,
          createdAt: '2024-11-24T18:09:52.127Z',
          updatedAt: '2024-11-24T18:09:52.127Z',
        },
      },
    ],
  },
]
