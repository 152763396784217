import { colors } from '@/theme'
import { StyleSheet } from 'react-native-web'

const styles = StyleSheet.create({
  title: {
    color: 'white',
    fontSize: 20,
    fontWeight: 'semibold',
    marginTop: '23vh',
    marginBottom: 30,
    textAlign: 'center',
    marginHorizontal: 50,
    lineHeight: 30,
  },
  cardHeader: {
    width: 230,
    height: 40,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    overflow: 'hidden',
  },
  headerBackground: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardBody: {
    backgroundColor: 'transparent',
    width: 230,
    height: 230,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 35,
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    overflow: 'hidden',
    gap: 10,
  },
  titleEvent: {
    color: 'white',
    fontSize: 19,
  },
  producerName: {
    color: 'silver',
    fontSize: 16,
  },
  imgContainer: {
    width: 80,
    height: 80,
    minHeight: 80,
    overflow: 'hidden',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
  },
  flyer: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
  },
  codeContainer: {
    backgroundColor: 'black',
    width: '50%',
    paddingVertical: 8,
    borderRadius: 8,
  },
  codeText: {
    color: 'white',
    fontSize: 16,
    textAlign: 'center',
    letterSpacing: 7,
  },
} as const)

export default styles
