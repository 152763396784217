import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 35,
    marginBottom: 15,
  },
  mainButtons: {
    height: 35,
    width: 150,
    borderRadius: 13,
    overflow: 'hidden',
  },
  buttonBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  buttonText: {
    color: 'white',
    fontSize: 14,
    marginBottom: 1.5,
  },
  icon: {
    width: 14.8,
    height: 14.8,
    marginRight: 16,
  },
})

export default styles
