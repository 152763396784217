import { HTTP_METHOD, vincufyApiCall } from '@/config/vincufyApi'
import {
  GET_BONDS,
  GET_BONDS_LOADING,
  GET_BONDS_ERROR,
  GET_EVENT_BONDS,
  GET_EVENT_BONDS_LOADING,
  GET_EVENT_BONDS_ERROR,
  GET_BOND,
  GET_BOND_LOADING,
  GET_BOND_ERROR,
  SEND_BOND_REQUEST,
  SEND_BOND_REQUEST_LOADING,
  SEND_BOND_REQUEST_ERROR,
  RESPOND_BOND_REQUEST,
  RESPOND_BOND_REQUEST_LOADING,
  RESPOND_BOND_REQUEST_ERROR,
  SET_PARTICIPANTS,
  UPDATE_PARTICIPANTS_BONDS,
} from '../constants'
import { bondMock, virtualRoomMock } from '@/mocks'
import { createCustomAsyncThunk } from '../store/createCustomAsyncThunk'
import { TParticipantDetailModel, TParticipantModel } from '@/models'
import { TBondModel } from '@/models/core/bond.model'
import { TBondDetailModel } from '@/models/bond-detail.model'

//Mis vinculos
export const getUserBondsAction = createCustomAsyncThunk(
  'event/getUserBondsAction',
  async (_: void, { dispatch }) => {
    try {
      dispatch({ type: GET_BONDS_LOADING })

      const respBody = await vincufyApiCall<{
        participants: TParticipantDetailModel[]
      }>({
        method: HTTP_METHOD.GET,
        endpoint: `/bond/all`,
        body: {},
        mock: {
          participants: virtualRoomMock.participants.filter(
            (part) => !!part.bond,
          ),
        },
        forceMock: false,
      })

      dispatch({
        type: SET_PARTICIPANTS,
        payload: { participants: respBody.participants },
      })
      dispatch({ type: GET_BONDS })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      dispatch({
        type: GET_BONDS_ERROR,
        payload: {
          error: errorMsg || '',
        },
      })
    }
  },
)

// Actualizar vinculos del evento
export const getEventBondsAction = createCustomAsyncThunk(
  'event/getEventBondsAction',
  async (eventId: string, { dispatch }) => {
    try {
      dispatch({ type: GET_EVENT_BONDS_LOADING })

      const respBody = await vincufyApiCall<{
        bonds: TBondDetailModel[]
      }>({
        method: HTTP_METHOD.GET,
        endpoint: `/bond/bonds-detail?eventId=${eventId}`,
        body: {},
        mock: { bonds: [] },
        forceMock: false,
      })

      dispatch({
        type: SET_PARTICIPANTS,
        payload: { participants: respBody.bonds || [] },
      })
      dispatch({ type: GET_EVENT_BONDS })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      dispatch({
        type: GET_EVENT_BONDS_ERROR,
        payload: {
          error: errorMsg || '',
        },
      })
    }
  },
)

// Actualización de un vinculo
export const getBondAction = createCustomAsyncThunk(
  'event/getBondAction',
  async ({ participantId }: { participantId: string }, { dispatch }) => {
    try {
      dispatch({ type: GET_BOND_LOADING })

      const respBody = await vincufyApiCall<TBondDetailModel>({
        method: HTTP_METHOD.GET,
        endpoint: `/bond/unique?participantId=${participantId}`,
        body: {},
        mock: {
          participantId: '2',
          userId: '2',
          bond: bondMock.CONTACT,
        },
        forceMock: false,
      })

      dispatch({
        type: SET_PARTICIPANTS,
        payload: { participants: [respBody] },
      })
      dispatch({ type: GET_BOND })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      dispatch({
        type: GET_BOND_ERROR,
        payload: {
          error: errorMsg || '',
        },
      })
    }
  },
)

//Enviar solicitud de vinculación
export const sendBondRequestAction = createCustomAsyncThunk(
  'event/sendBondRequestAction',
  async (
    {
      receiverId,
    }: {
      receiverId: TParticipantModel['id']
    },
    { dispatch },
  ) => {
    try {
      dispatch({
        type: SEND_BOND_REQUEST_LOADING,
        payload: { receiverId },
      })

      const respBody = await vincufyApiCall<TBondDetailModel>({
        method: HTTP_METHOD.POST,
        endpoint: `/bond`,
        body: { receiverId },
        mock: {
          participantId: receiverId,
          userId: '123',
          bond: bondMock.PENDING,
        },
        forceMock: false,
      })

      dispatch({
        type: UPDATE_PARTICIPANTS_BONDS,
        payload: { participants: [respBody] },
      })
      dispatch({ type: SEND_BOND_REQUEST })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      dispatch({
        type: SEND_BOND_REQUEST_ERROR,
        payload: {
          error: errorMsg || '',
        },
      })
    }
  },
)

// Responder solicitud de vinculación
export const respondBondRequestAction = createCustomAsyncThunk(
  'event/respondBondRequestAction',
  async (
    {
      bondId,
      status,
    }: {
      bondId: string
      status: TBondModel['status']
    },
    { dispatch },
  ) => {
    try {
      dispatch({
        type: RESPOND_BOND_REQUEST_LOADING,
        payload: { bondId },
      })

      const respBody = await vincufyApiCall<TBondDetailModel>({
        method: HTTP_METHOD.PATCH,
        endpoint: `/bond`,
        body: { bondId, status },
        mock: {
          participantId: '2',
          userId: '2',
          bond: bondMock.CONTACT,
        },
        forceMock: false,
      })

      dispatch({
        type: UPDATE_PARTICIPANTS_BONDS,
        payload: { participants: [respBody] },
      })
      dispatch({ type: RESPOND_BOND_REQUEST })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      dispatch({
        type: RESPOND_BOND_REQUEST_ERROR,
        payload: {
          error: errorMsg || '',
        },
      })
    }
  },
)
