import { View, Text } from 'react-native'
import styles from './InfoCardStyles'
import { TEditableUserInfo } from '@/views/MainSections/MyProfile/controllers/MyProfileController'
import { theme } from '@/theme'

const InfoCard = ({ userInfo = {} }: { userInfo: TEditableUserInfo }) => {
  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <View style={[styles.bckgImg, theme.bgGradientCenter]} />
        <View style={styles.content}>
          <Text style={styles.name}>
            {userInfo.name
              ? `${userInfo.name}${userInfo.lastName ? ' ' + userInfo.lastName : ''}${userInfo.age ? ', ' + userInfo.age : ''}`
              : ''}
          </Text>
          {/* {userInfo.sentimentalSituation &&
            userInfo.sentimentalSituation.value !== 'hide' && (
              <Text style={styles.state}>
                {userInfo.sentimentalSituation.label}
              </Text>
            )} */}
          <Text style={styles.location}>{userInfo.originLocation}</Text>
          <Text style={styles.description}>{userInfo.description}</Text>
        </View>
      </View>
    </View>
  )
}

export default InfoCard
