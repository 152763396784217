import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    marginTop: '20%',
  },
  title: {
    fontSize: 19,
    fontWeight:500,
    color: 'white',
    textAlign: 'center',
  },
})
