import { ScreenLoader } from '@/components'
import { StyleSheet, View } from 'react-native'
import {
  createNavigationContainerRef,
  NavigationContainer,
  NavigatorScreenParams,
} from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import {
  AuthNavigator,
  AuthNavigatorParamList,
  MyTabsNavigator,
  MyTabsNavigatorParamList,
  OnboardingNavigator,
  OnboardingNavigatorParamList,
} from './Navigators'
import { useRedirectLogic } from './useRedirectLogic'

export type RootNavigatorParamList = {
  AuthNavigator: NavigatorScreenParams<AuthNavigatorParamList>
  OnboardingNavigator: NavigatorScreenParams<OnboardingNavigatorParamList>
  MyTabsNavigator: NavigatorScreenParams<MyTabsNavigatorParamList>
}

export const navigationRef =
  createNavigationContainerRef<RootNavigatorParamList>()

const RootStack = createNativeStackNavigator<RootNavigatorParamList>()

const Navigation = () => {
  const { isLoadingPage } = useRedirectLogic()
  return (
    <NavigationContainer ref={navigationRef}>
      <View style={styles.container}>
        <ScreenLoader isVisible={isLoadingPage} />
        <RootStack.Navigator
          screenOptions={{ headerShown: false, gestureEnabled: false }}
          initialRouteName="AuthNavigator"
        >
          <RootStack.Screen name="AuthNavigator" component={AuthNavigator} />
          <RootStack.Screen
            name="OnboardingNavigator"
            component={OnboardingNavigator}
          />
          <RootStack.Screen
            name="MyTabsNavigator"
            component={MyTabsNavigator}
          />
        </RootStack.Navigator>
      </View>
    </NavigationContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
})

export default Navigation
