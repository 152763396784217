import { useCallback, useContext, useEffect, useState } from 'react'
import { ModalContext, TModalToOpen } from './ModalContext'
import { devLog } from '@/utils/devLog'

export const useModalContext = (modalName: string) => {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error("Modal is being used outside it's provider")
  }

  const {
    modalsToOpen = [],
    openSpecificModal = () => devLog('Empty openSpecificModal'),
    closeSpecificModal = () => devLog('Empty closeSpecificModal'),
  } = context

  const openModal = (prop?: { modalAction?: TModalToOpen['modalAction'] }) => {
    const newModalAction =
      prop?.modalAction || (() => devLog('Empty Modal Function'))
    openSpecificModal(modalName, newModalAction)
  }

  const closeModal = () => closeSpecificModal(modalName)

  const [isOpenModal, setIsOpenModal] = useState(false)
  const modalAction = useCallback(
    isOpenModal ? modalsToOpen[0]?.modalAction : () => {},
    [isOpenModal, modalsToOpen[0]?.modalAction],
  )

  useEffect(() => {
    const modalOpened = modalsToOpen[0]?.modalName === modalName
    setIsOpenModal(modalOpened)

    const handleEsc = (e) => {
      if (e.key === 'Escape') closeModal()
    }
    if (modalOpened) {
      document.addEventListener('keydown', handleEsc)
    }

    return () => {
      document.removeEventListener('keydown', handleEsc)
    }
  }, [modalsToOpen[0]?.modalName, closeModal])

  return { openModal, closeModal, isOpenModal, modalAction }
}
