import { useMemo } from 'react'
import { allowNotificationsScreens } from '@/models/notification.model'
import { useAllowNotificationsController } from './controllers/useAllowNotificationsController'
import NoNotifications from './screens/NoNotifications'
import PermissionGranted from './screens/PermissionGranted'
import AskPermissionButton from './screens/AskPermissionButton'
import AskPermissionInstructions from './screens/AskPermissionInstructions'

const AllowNotifications = () => {
  const {
    screenToShow,
    handleClickContinueWithNoNotifications,
    handleClickAskForAllowNotifications,
    isLoadingAllowNotifications,
    allowNotificationsError,
  } = useAllowNotificationsController()

  const renderContent = useMemo(() => {
    if (screenToShow === allowNotificationsScreens.NO_NOTIFICATIONS)
      return (
        <NoNotifications onContinue={handleClickContinueWithNoNotifications} />
      )
    if (screenToShow === allowNotificationsScreens.PERMISSION_GRANTED)
      return <PermissionGranted />
    if (screenToShow === allowNotificationsScreens.ASK_PERMISSION_BUTTON)
      return (
        <AskPermissionButton
          onAskPermission={handleClickAskForAllowNotifications}
          isLoading={isLoadingAllowNotifications}
          error={allowNotificationsError}
        />
      )
    if (screenToShow === allowNotificationsScreens.ASK_PERMISSION_INSTRUCTIONS)
      return <AskPermissionInstructions />

    return <AskPermissionInstructions />
  }, [
    screenToShow,
    handleClickContinueWithNoNotifications,
    handleClickAskForAllowNotifications,
    isLoadingAllowNotifications,
    allowNotificationsError,
  ])

  return renderContent
}

export default AllowNotifications
