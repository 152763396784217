import { View, ActivityIndicator } from 'react-native'
import styles from './ScreenLoaderStyles'

const ScreenLoader = ({ isVisible = true }: { isVisible: boolean }) => {
  if (!isVisible) return null
  return (
    <View style={styles.overlay}>
      <ActivityIndicator size="large" color="#ffffff" />
    </View>
  )
}

export default ScreenLoader
