import { useState, useEffect } from 'react'
import { Text, View, ScrollView } from 'react-native'
import { ScreenLayout, Collapse, Loader } from '@/components'
import { useBondsController } from './controller/useBondsController'
import { GetContactMethodModal } from '@/components'
import { TParticipantDetailModel } from '@/models'
import { StyleSheet } from 'react-native'
import { navigateTo } from 'routes/utils'

const MyBonds = () => {
  const { bonds, bondsLoading, getBonds } = useBondsController()
  const [isOpenContactModal, setIsOpenContactModal] = useState(false)
  const [selectedParticipant, setSelectedParticipant] =
    useState<TParticipantDetailModel | null>(null)
  const [openCollapse, setOpenCollapse] = useState({})

  useEffect(() => {
    if (bonds?.length > 0) {
      const lastEvent = bonds[bonds.length - 1] // Último evento de la lista
      setOpenCollapse({ [lastEvent.eventName]: true })
    }
  }, [bonds])

  const openModal = (participant: TParticipantDetailModel) => {
    setSelectedParticipant(participant)
    setIsOpenContactModal(true)
  }

  const handleToggleCollapse = (eventName: string) => {
    setOpenCollapse((prevState) => ({
      ...prevState,
      [eventName]: !prevState[eventName],
    }))
  }

  const goToParticipantDetail = (participantId: string, eventId: string) => {
    navigateTo('BondParticipant', {
      eventId,
      participantId,
    })
  }

  if (bondsLoading) return <Loader />

  return (
    <ScreenLayout>
      {bonds?.length ? (
        <ScrollView
          style={styles.scrollStyles}
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
        >
          {bonds.map((event) => (
            <Collapse
              key={event.eventName}
              title={event.eventName}
              participants={event.bonds}
              onRefresh={getBonds}
              openModal={openModal}
              isOpenCollapse={openCollapse[event.eventName]}
              setIsOpenCollapse={() => handleToggleCollapse(event.eventName)}
              goToDetailFunc={(partId) =>
                goToParticipantDetail(partId, event.eventId)
              }
            />
          ))}
        </ScrollView>
      ) : (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>
            Todavía no tenés ninguna vinculación
          </Text>
        </View>
      )}
      <GetContactMethodModal
        participantContactMethods={selectedParticipant?.contactMethods}
        isOpenContactModal={isOpenContactModal}
        setIsOpenContactModal={setIsOpenContactModal}
      />
    </ScreenLayout>
  )
}
const styles = StyleSheet.create({
  emptyContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  emptyText: {
    color: 'white',
    fontSize: 16,
  },
  scrollStyles: {
    flex: 1,
    marginBottom: 50,
  },
})

export default MyBonds
