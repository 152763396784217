import { useEffect, useState } from 'react'
import { View, Text, FlatList } from 'react-native'
import styles from './SelectGenderStyles'
import { CustomPressable, CustomModal } from '@/components'
import { theme } from '@/theme'
import { GenderType } from '@/models'

const SelectGender = ({
  gender,
  setGender,
  customStyles,
}: {
  gender?: GenderType
  setGender: (v: GenderType) => void
  customStyles: any
}) => {
  const customInputStyles = customStyles ? customStyles : theme.input
  const translateGender = {
    [GenderType.MALE]: 'Masculino',
    [GenderType.FEMALE]: 'Femenino',
    [GenderType.OTHER]: 'Otro',
  }

  const [selectedGender, setSelectedGender] = useState<GenderType | null>(
    gender || null,
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (gender && selectedGender !== gender) setSelectedGender(gender)
  }, [gender, selectedGender])

  const handleSelectGender = (newGender: GenderType) => {
    setSelectedGender(newGender)
    setGender?.(newGender)
    setIsModalOpen(false)
  }

  const renderItem = ({ item }: { item: GenderType }) => (
    <CustomPressable
      style={[
        styles.genderItem,
        { backgroundColor: item === selectedGender ? '#9459CE' : 'black' },
      ]}
      onPress={() => handleSelectGender(item)}
    >
      <Text style={styles.genderText}>{translateGender[item]}</Text>
    </CustomPressable>
  )

  return (
    <>
      <CustomPressable
        onPress={() => setIsModalOpen(true)}
        style={customStyles}
      >
        <Text style={styles.openButtonText}>
          {selectedGender ? translateGender[selectedGender] : 'Seleccioná tu género'}
        </Text>
      </CustomPressable>
      <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <View style={styles.modalContent}>
          <FlatList
            data={Object.values(GenderType)}
            renderItem={renderItem}
            keyExtractor={(item) => item}
            // style={styles.flatList}
          />
        </View>
      </CustomModal>
    </>
  )
}

export default SelectGender
