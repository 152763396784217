import { useMyTicketsModel } from '../models/useMyTicketsModel'

export const useMyTicketsController = () => {
  const { userEventTickets, eventId, isLoading } = useMyTicketsModel()
  return {
    userEventTickets,
    eventId,
    isLoading,
  }
}
