import { browsers, getBrowserType } from '@/utils/browser.util'

export const notificationPermission = {
  GRANTED: 'granted',
  DENIED: 'denied',
} as const

export type deviceNotificationsStatuses =
  | 'INITIAL_LOADING'
  | 'ERROR'
  | 'NEED_PERMISSION'
  | 'PERMISSION_GRANTED'
  | 'FINISHED'
export const deviceNotificationsStatuses = {
  INITIAL_LOADING: 'INITIAL_LOADING',
  ERROR: 'ERROR', // UNSUPPORTED or ERROR
  NEED_PERMISSION: 'NEED_PERMISSION',
  PERMISSION_GRANTED: 'PERMISSION_GRANTED',
  FINISHED: 'FINISHED',
} as const

export const allowNotificationsScreens = {
  NO_NOTIFICATIONS: 'NO_NOTIFICATIONS',
  ASK_PERMISSION_BUTTON: 'ASK_PERMISSION_BUTTON',
  ASK_PERMISSION_INSTRUCTIONS: 'ASK_PERMISSION_INSTRUCTIONS',
  PERMISSION_GRANTED: 'PERMISSION_GRANTED',
  LOADING: 'LOADING',
} as const

const browsersInstructionSteps = {
  [browsers.CHROME]: [
    'Haz clic en el menú de tres puntos en la esquina superior derecha.',
    'Selecciona "Configuración" > "Configuración del sitio" > "Notificaciones".',
    'Cambia el estado a "Permitir".',
    'Recarga la página de Vincufy.',
  ],
  [browsers.SAFARI]: [
    'Abre "Ajustes" en tu dispositivo.',
    'Desplázate hacia abajo y selecciona "Safari".',
    'Ve a "Notificaciones" y habilita las notificaciones para este sitio web.',
    'Recarga la página de Vincufy.',
  ],
  [browsers.FIREFOX]: [
    'Abre el menú de tres líneas > "Configuración".',
    'Ve a "Permisos" > "Notificaciones".',
    'Cambia el estado a "Permitir".',
    'Recarga la página de Vincufy.',
  ],
  [browsers.SAMSUNG_INTERNET]: [
    'Haz clic en el ícono de tres líneas en la esquina inferior derecha.',
    'Selecciona "Configuración" > "Sitios y descargas" > "Notificaciones".',
    'Cambia el estado a "Permitir".',
    'Recarga la página de Vincufy.',
  ],
  [browsers.OTHER]: null,
}

export const getBrowserInstructionSteps = () =>
  browsersInstructionSteps[getBrowserType()]
