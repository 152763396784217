import { BondStatus } from '@/models'

//Response para determinar el tipo de botón de la pantalla ParticipantDetail
export const bondMock = {
  REQUEST_BOND: null,
  ACCEPT_BOND: {
    id: '123',
    status: BondStatus.PENDING,
    requesterId: '2',
    receiverId: '1',
    currentUserIsRequester: false,
  },
  PENDING: {
    id: '123',
    status: BondStatus.PENDING,
    requesterId: '1',
    receiverId: '2',
    currentUserIsRequester: true,
  },
  CONTACT: {
    id: '123',
    status: BondStatus.ACCEPTED,
    requesterId: '2',
    receiverId: '1',
    currentUserIsRequester: false,
  },
}
