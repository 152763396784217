import { editUserDB } from '@/redux/thunks'
import { validateEmail } from '@/utils/validations.util'
import { getSingularOrPlural } from '@/utils/word.util'
import { useState } from 'react'
import { Alert } from 'react-native'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { navigateTo } from 'routes/utils'

export const useChangeInfoSettings = (isEmail) => {
  const dispatch = useAppDispatch()
  const currentEmail = useAppSelector((state) => state.auth.userAuth.email)
  const currentPhone = useAppSelector((state) => state.user.userInfo?.phone)

  const [email, setEmail] = useState('')
  const [areaCode, setAreaCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [errors, setErrors] = useState({
    email: '',
    areaCode: '',
    phoneNumber: '',
  })
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const handleChangeInput = (field, event) => {
    const newValue = event?.target?.value || ''

    if (field === 'email') {
      setEmail(newValue)

      setErrors((prev) => ({
        ...prev,
        email: validateEmail(newValue)
          ? ''
          : 'El formato correcto es correo@mail.com',
      }))
    } else if (field === 'areaCode') {
      setAreaCode(newValue)

      setErrors((prev) => ({
        ...prev,
        areaCode:
          newValue.length < 3
            ? `${getSingularOrPlural(3 - newValue.length, 'número')}`
            : '',
      }))
    } else if (field === 'phoneNumber') {
      setPhoneNumber(newValue)

      setErrors((prev) => ({
        ...prev,
        phoneNumber:
          newValue.length < 7
            ? `${getSingularOrPlural(7 - newValue.length, 'número')}`
            : '',
      }))
    }
  }

  const handleSubmit = async () => {
    setIsSubmitLoading(true)
    try {
      const editedValue = isEmail
        ? { email }
        : { phone: `${areaCode}${phoneNumber}` }
      await dispatch(editUserDB(editedValue)).unwrap()
      navigateTo('MyProfile', {})
      // Alert.alert(`Cambiaste tu ${item} con éxito!`)
    } catch {
      Alert.alert('Ups algo salió mal, intentalo de nuevo!')
    } finally {
      setIsSubmitLoading(false)
    }
  }

  const isSubmitDisabled = isEmail
    ? errors.email || !email
    : errors.areaCode ||
      errors.phoneNumber ||
      areaCode.length < 3 ||
      phoneNumber.length < 7

  return {
    currentValue: isEmail ? currentEmail : currentPhone,
    email,
    areaCode,
    phoneNumber,
    errors,
    handleChangeInput,
    handleSubmit,
    isSubmitDisabled,
    isSubmitLoading,
  }
}
