import { StyleSheet } from 'react-native-web'

const styles = StyleSheet.create({
  errorScreen: {
    with: '100%',
    height: '100%',
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icono: {
    height: 100,
    width: 100,
    // marginTop: '33vh',
    marginBottom: 30,
  },
  title: {
    color: 'white',
    fontSize: 20,
    fontWeight: 'semibold',
    textAlign: 'center',
    marginHorizontal: 30,
    lineHeight: 30,
  },
})

export default styles
