import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import {
  getMessaging,
  getToken,
  isSupported,
  Messaging,
} from 'firebase/messaging'
import {
  setDeviceNotificationsStatus,
  updateNotificationToken,
} from '@/redux/thunks'
import { deviceNotificationsStatuses } from '@/models/notification.model'
import { firebaseApp, firebaseConfig } from '../../src/config/firebaseConfig'
import { WEB_PUBLIC_FIREBASE_FCM_VAPID_KEY } from '@env'
import { notisLog } from '@/utils/devLog'

export const LOCALSTORAGE_NO_NOTIS_NOTIFIED = 'vincufyNoNotisNotified'

export const useInitialNotificationSetup = () => {
  const dispatch = useAppDispatch()
  const setupStatus = useAppSelector(
    (state) => state.user.deviceNotificationsStatus,
  )
  const [isPermissionGranted, setIsPermissionGranted] = useState(false)
  const [tokenRequirements, setTokenRequirements] = useState<null | {
    messaging: Messaging
    serviceWorkerRegistration: ServiceWorkerRegistration
  }>(null)

  const isNotificationSupportedInBrowser = async () => {
    const supported = await isSupported()
    if (supported && 'serviceWorker' in navigator && 'Notification' in window) {
      notisLog('Supported on this browser.')
      return true
    } else {
      throw new Error('Unsupported on this browser.')
    }
  }

  useEffect(() => {
    if (
      !isPermissionGranted &&
      setupStatus === deviceNotificationsStatuses.PERMISSION_GRANTED
    ) {
      setIsPermissionGranted(true)
    }
  }, [setupStatus])

  const checkNotificationPermission = async () => {
    if (Notification.permission === 'granted') {
      notisLog('Permission granted.')
      setIsPermissionGranted(true)
      // No mostrar pantalla PERMISSION_GRANTED para continuar viendo el loading principal
      // dispatch(setDeviceNotificationsStatus(deviceNotificationsStatuses.PERMISSION_GRANTED))
    } else {
      notisLog('Permission NOT granted.')
      dispatch(
        setDeviceNotificationsStatus(
          deviceNotificationsStatuses.NEED_PERMISSION,
        ),
      )
    }
  }

  const registerServiceWorker = async () => {
    notisLog('Starting Service Worker...')
    const messaging = getMessaging(firebaseApp)
    if (!messaging)
      throw new Error(
        "Error -> RegisterServiceWorker -> Invalid 'getMessaging(app)'.",
      )

    const serviceWorkerRegistration = await navigator.serviceWorker.register(
      '/firebase-messaging-sw.js',
    )
    if (!serviceWorkerRegistration)
      throw new Error(
        "Error -> RegisterServiceWorker -> Invalid 'navigator.serviceWorker.register('/firebase-messaging-sw.js')'.",
      )
    notisLog('Service Worker Registered.')

    // Manejo de activación del Service Worker con espera y timeout
    let timeoutHandle
    await new Promise((resolve, reject) => {
      const waitForActive = () => {
        if (serviceWorkerRegistration.active) {
          clearTimeout(timeoutHandle) // Cancelar el timeout si se activa
          resolve(true)
        }
      }

      // Revisar si el SW está activo al registrarse
      waitForActive()

      // Escuchar cambios de estado del SW para detectar activación
      serviceWorkerRegistration.installing?.addEventListener(
        'statechange',
        waitForActive,
      )
      serviceWorkerRegistration.waiting?.addEventListener(
        'statechange',
        waitForActive,
      )

      // Configurar timeout para rechazar la promesa si no se activa en el tiempo establecido
      timeoutHandle = setTimeout(() => {
        reject(
          new Error(
            'Error -> RegisterServiceWorker -> Service Worker Activation Timeout (15s).',
          ),
        )
      }, 15000)
    })

    if (!serviceWorkerRegistration.active)
      throw new Error(
        'Error -> RegisterServiceWorker -> Service Worker Activation Timeout (15s).',
      )
    notisLog('Service Worker Active.')

    setTokenRequirements({ messaging, serviceWorkerRegistration })
  }

  const handleErrorNotisStatus = () => {
    const lsNoNotisNotified = localStorage.getItem(
      LOCALSTORAGE_NO_NOTIS_NOTIFIED,
    )
    if (lsNoNotisNotified) {
      dispatch(
        setDeviceNotificationsStatus(deviceNotificationsStatuses.FINISHED),
      )
    } else {
      dispatch(setDeviceNotificationsStatus(deviceNotificationsStatuses.ERROR))
      localStorage.setItem(
        LOCALSTORAGE_NO_NOTIS_NOTIFIED,
        new Date().toISOString(),
      )
    }
  }

  const startInitialNotificationSetup = async () => {
    try {
      notisLog('Starting...')

      const supported = await isNotificationSupportedInBrowser()
      if (!supported) return null

      // Ejecutamos en paralelo
      const results = await Promise.allSettled([
        checkNotificationPermission(),
        registerServiceWorker(),
      ])

      // Verificamos los resultados de cada promesa
      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          const functionName =
            index === 0
              ? 'checkNotificationPermission'
              : 'registerServiceWorker'
          throw new Error(`Error in ${functionName}: ${result.reason}`)
        }
      })
    } catch (error) {
      notisLog('Error -> Notification Setup. ' + error)
      handleErrorNotisStatus()
    }
  }

  const getNotificationDeviceToken = async ({
    messaging,
    serviceWorkerRegistration,
  }) => {
    // Retry fetching the token if necessary. (up to 3 times)
    // This step is typical initially as the service worker may not be ready/installed yet.

    let currentToken = ''
    let retries = 0
    const MAX_LOAD_TOKEN_RETRIES = 3

    while (!currentToken && retries <= MAX_LOAD_TOKEN_RETRIES) {
      try {
        currentToken = await getToken(messaging, {
          vapidKey: WEB_PUBLIC_FIREBASE_FCM_VAPID_KEY,
          serviceWorkerRegistration,
        })
      } catch (err) {
        notisLog('Error -> getToken. ' + err)
      } finally {
        if (!currentToken) {
          notisLog(`Fail getToken try ${retries}.`)
          retries++
        }
      }
    }

    if (!currentToken) {
      notisLog('Unable to load token after 3 retries.')
      handleErrorNotisStatus()
      return null
    }

    notisLog('Device Token: ' + currentToken)
    dispatch(updateNotificationToken(currentToken))
    dispatch(setDeviceNotificationsStatus(deviceNotificationsStatuses.FINISHED))
    notisLog('Finished.')
  }

  useEffect(() => {
    if (tokenRequirements && isPermissionGranted) {
      getNotificationDeviceToken(tokenRequirements)
    }
  }, [isPermissionGranted, tokenRequirements])

  return {
    startInitialNotificationSetup,
    isLoadingInitialNotificationSetup:
      setupStatus === deviceNotificationsStatuses.INITIAL_LOADING,
    notificationSetupFinished:
      setupStatus === deviceNotificationsStatuses.FINISHED,
  }
}
