import { useEffect, useState } from 'react'
import { Alert } from 'react-native'
import {
  editUserDB,
  editUserProfileDB,
  getUserDB,
  setShouldHideTabBar,
} from '@/redux/thunks/user.thunk'
import { useModalContext } from '@/components/Modals/context'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { setLoading } from '@/redux/actions'
import { TUserModel, TUserProfileModel } from '@/models'

export type TEditableUserInfo = {
  name?: TUserModel['name']
  lastName?: TUserModel['lastName']
  age?: TUserModel['age']
  phone?: TUserModel['phone']
  birthDate?: TUserModel['birthDate']
  gender?: TUserModel['gender']
  originLocation?: TUserModel['originLocation']
  sentimentalSituation?: TUserModel['sentimentalSituation']
  description?: TUserProfileModel['description']
  profilePictures?: TUserProfileModel['profilePictures']
}

export const useMyProfileController = () => {
  const dispatch = useAppDispatch()
  const userInfo = useAppSelector((state) => state.user.userInfo)
  const userProfile = useAppSelector((state) => state.user.userProfiles[0])
  const isLoading = useAppSelector((state) => state.auth.isLoading)
  const {
    openModal: openExitWhileEditingProfileModal,
    closeModal: closeExitWhileEditingProfileModal,
  } = useModalContext('ExitWhileEditingProfileModal')
  const { openModal: openInfoModal } = useModalContext('InfoModal')

  const [editableUserInfo, setEditableUserInfo] = useState<TEditableUserInfo>(
    {},
  )
  const [edittedUserInfo, setEdittedUserInfo] = useState<
    Omit<TEditableUserInfo, 'description' | 'profilePictures'>
  >({})
  const [edittedUserProfile, setEdittedUserProfile] = useState<
    Pick<TEditableUserInfo, 'description' | 'profilePictures'>
  >({})
  const [isEditingProfile, setIsEditingProfile] = useState(false)
  const inputChanged =
    Object.keys(edittedUserInfo).length > 0 ||
    Object.keys(edittedUserProfile).length > 0
  const [isSubmitingEditedUser, setIsSubmitingEditedUser] = useState(false)

  useEffect(() => {
    if (isEditingProfile) {
      dispatch(setShouldHideTabBar(true))
    } else {
      dispatch(setShouldHideTabBar(false))
    }
  }, [isEditingProfile])

  const restartForm = () => {
    setEditableUserInfo({
      profilePictures: userProfile?.profilePictures,
      name: userInfo?.name,
      lastName: userInfo?.lastName,
      // email: userInfo?.userInfo.email,
      age: userInfo?.age,
      phone: userInfo?.phone,
      // userName: userInfo?.userInfo.userName,
      birthDate: userInfo?.birthDate,
      description: userProfile?.description,
      gender: userInfo?.gender,
      originLocation: userInfo?.originLocation,
      sentimentalSituation: userInfo?.sentimentalSituation,
    })
    setEdittedUserInfo({})
    setEdittedUserProfile({})
  }

  const updateEditableUserInfo = (
    field: keyof TEditableUserInfo,
    value: TEditableUserInfo[keyof TEditableUserInfo],
  ) => {
    setEditableUserInfo((lastUserInfo: TEditableUserInfo) => ({
      ...lastUserInfo,
      [field]: value,
    }))

    if (field === 'description' || field === 'profilePictures') {
      if (userProfile?.[field] !== value) {
        setEdittedUserProfile((lastUserInfo: TEditableUserInfo) => ({
          ...lastUserInfo,
          [field]: value,
        }))
      } else {
        setEdittedUserProfile((lastUserInfo: TEditableUserInfo) => {
          const newUserInfo = { ...lastUserInfo }
          delete newUserInfo?.[field]
          return newUserInfo
        })
      }
    } else {
      if (userInfo?.[field] !== value) {
        setEdittedUserInfo((lastUserInfo: TEditableUserInfo) => ({
          ...lastUserInfo,
          [field]: value,
        }))
      } else {
        setEditableUserInfo((lastUserInfo: TEditableUserInfo) => {
          const newUserInfo = { ...lastUserInfo }
          delete newUserInfo?.[field]
          return newUserInfo
        })
      }
    }
  }

  useEffect(() => {
    if (userInfo && userProfile) {
      dispatch(setLoading(true))
      restartForm()
      dispatch(setLoading(false))
      if (
        userProfile.profilePictures &&
        !userProfile.profilePictures[0]?.length
      ) {
        setTimeout(() => {
          openInfoModal()
        }, 500)
      }
    }
  }, [userInfo, userProfile])

  const submitEditedUser = async () => {
    if (inputChanged) {
      setIsSubmitingEditedUser(true)
      try {
        if (Object.keys(edittedUserInfo).length > 0) {
          await dispatch(editUserDB(edittedUserInfo)).unwrap()
          setEdittedUserInfo({})
        }
        if (Object.keys(edittedUserProfile).length > 0) {
          await dispatch(editUserProfileDB(edittedUserProfile)).unwrap()
          setEdittedUserProfile({})
        }
        setIsEditingProfile(false)
      } catch {
        Alert.alert('Ups algo salió mal, intentalo de nuevo!')
        restartForm()
      } finally {
        setIsSubmitingEditedUser(false)
      }
    } else {
      setIsEditingProfile(false)
    }
  }

  const cancelModifiedInputs = () => {
    dispatch(setLoading(true))
    restartForm()
    setIsEditingProfile(false)
    closeExitWhileEditingProfileModal()
    dispatch(setLoading(false))
  }

  const editProfile = () => setIsEditingProfile(true)

  const cancelEditProfile = () => {
    if (inputChanged) {
      openExitWhileEditingProfileModal({ modalAction: cancelModifiedInputs })
    } else {
      cancelModifiedInputs()
    }
  }

  const refreshMyProfile = () => {
    dispatch(getUserDB())
  }
  return {
    userInfo,
    userProfile,
    editableUserInfo,
    updateEditableUserInfo,
    isEditingProfile,
    editProfile,
    isLoading,
    submitEditedUser,
    isSubmitingEditedUser,
    cancelEditProfile,
    refreshMyProfile,
  }
}
