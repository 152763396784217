import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 50,
    marginHorizontal: 20,
  },
  bkgImage: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 40,
    borderRadius: 10,
    overflow: 'hidden',
  },
  titleText: {
    fontSize: 14,
    color: 'white',
  },
  cardContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 50,
  },
})

export default styles
