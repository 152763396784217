import { useAppDispatch } from '@/redux/store'
import {
  validateConfirmPassword,
  validateEmail,
  validatePassword,
} from '@/models/auth.model'
import { fullRegister } from '@/redux/thunks'
import { useState } from 'react'
import { GenderType } from '@/models'
import { TLoggedOrigin } from '@/redux/reducers/auth.reducer'
import { formatNames } from '@/utils'

export type TFullRegisterForm = {
  email: string
  password: string
  confirmPassword: string
  name: string
  lastName: string
  dateOfBirth: string
  city: string
  gender?: GenderType
}

export type TFullRegisterFormErrors = {
  email?: string
  password?: string
  confirmPassword?: string
  name?: string
  lastName?: string
  dateOfBirth?: string
  city?: string
  gender?: string
}

export const useFullRegisterController = (
  fromFunctionality?: TLoggedOrigin,
) => {
  const dispatch = useAppDispatch()

  const [input, setInput] = useState<TFullRegisterForm>({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    lastName: '',
    dateOfBirth: '',
    city: '',
    gender: undefined,
  })
  const [formErrors, setFormErrors] = useState<TFullRegisterFormErrors>({})
  const [errorRegisterMessage, setErrorRegisterMessage] = useState('')
  const [isLoadingRegister, setIsLoadingRegister] = useState(false)

  const hasFormErrors =
    !!formErrors.email ||
    !!formErrors.password ||
    !!formErrors.confirmPassword ||
    !!formErrors.name ||
    !!formErrors.lastName ||
    !!formErrors.dateOfBirth ||
    !!formErrors.city ||
    !!formErrors.gender

  const hasInvalidSubmitFields =
    input.email.length === 0 ||
    input.password.length === 0 ||
    input.confirmPassword.length === 0 ||
    input.name.length === 0 ||
    input.lastName.length === 0 ||
    input.dateOfBirth.length === 0 ||
    input.city.length === 0 ||
    !input.gender

  const registerBtnDisabled =
    hasFormErrors || hasInvalidSubmitFields || isLoadingRegister

  const registerUser = async () => {
    await dispatch(
      fullRegister({
        newUser: {
          email: input.email,
          password: input.password,
          name: formatNames(input.name),
          lastName: formatNames(input.lastName),
          birthDate: new Date(input.dateOfBirth).toISOString(),
          gender: input.gender as GenderType,
          originLocation: input.city,
          location: input.city, // Optional
          // sentimentalSituation: SentimentalSituationType.SINGLE, // Optional
          // phone: '+541154689545', // Optional
          // instagramToken: '', // Optional
          // notificationToken: '', // Optional

          userProfileName: 'Perfil Principal',
          description: '',
          profilePictures: ['', '', ''],
          ageVisible: true,
          genderVisible: true,
          originLocationVisible: true,
          sentimentalSituationVisible: true,
          contactMethods: {},
        },
        fromFunctionality,
      }),
    ).unwrap()
  }

  const validateRegisterForm = (input: TFullRegisterForm) => {
    let errors: TFullRegisterFormErrors = {}
    errors.email = validateEmail(input.email) || undefined
    errors.password = validatePassword(input.password) || undefined
    errors.confirmPassword =
      validateConfirmPassword(input.confirmPassword, input.password) ||
      undefined
    errors.dateOfBirth = !input.dateOfBirth.length
      ? 'Ingresá una fecha válida'
      : ''
    return errors
  }

  const handleChange = (newValue: string, name: string) => {
    if (name === 'email') {
      newValue = newValue.toLocaleLowerCase()
    }
    const newInput = { ...input, [name]: newValue }
    setInput(newInput)
    setFormErrors(validateRegisterForm(newInput))
  }

  const handleSubmit = async () => {
    if (hasInvalidSubmitFields) return
    try {
      setIsLoadingRegister(true)
      setErrorRegisterMessage('')
      await registerUser()
    } catch (err) {
      console.warn(err)
      if ((err as any)?.statusCode === 405) {
        setErrorRegisterMessage('Este mail ya fue utilizado.')
      } else if ((err as any)?.message?.includes?.('Invalid time value')) {
        console.warn('Invalid date:', input.dateOfBirth)
        setErrorRegisterMessage('Error en la fecha de nacimiento.')
      } else {
        setErrorRegisterMessage(
          err instanceof Error ? err?.message : String(err) || 'Error',
        )
      }
    } finally {
      setIsLoadingRegister(false)
    }
  }

  return {
    handleChange,
    handleSubmit,
    registerBtnDisabled,
    input,
    formErrors,
    errorRegisterMessage,
    isLoadingRegister,
  }
}
