import { useEffect, useState } from 'react'
import { authLog } from '@/utils/devLog'
import { verifySession } from '@/redux/thunks'
import { useAppDispatch, useAppSelector } from '@/redux/store'

export const useInitialAuth = () => {
  // const currentRoute = useNavigationState((state) => {
  //   return state?.routes[state.index]?.name // Obtiene el nombre de la ruta actual
  // })
  const dispatch = useAppDispatch()
  const isLogged = useAppSelector((state) => state.auth.isLogged)
  const isPostLoggedFlow = useAppSelector((state) => state.auth.isPostLoggedFlow)
  const loggedOrigin = useAppSelector((state) => state.auth.loggedOrigin)
  const userId = useAppSelector((state) => state.auth.userAuth.userId)
  const [isLoadingInitialAuth, setIsLoadingInitialAuth] = useState(true)

  const checkIfHasActiveSession = async () => {
    setIsLoadingInitialAuth(true)
    try {
      authLog('Starting...')

      await dispatch(verifySession()).unwrap()

      authLog('Valid Session.')

      return true
    } catch (error) {
      authLog('Error -> Verifing Session.', error)
    } finally {
      authLog('Finished.')
      setIsLoadingInitialAuth(false)
    }
  }

  return {
    checkIfHasActiveSession,
    isLogged,
    isPostLoggedFlow,
loggedOrigin,
    userId,
    isLoadingInitialAuth,
  }
}
