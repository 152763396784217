import { memo, useState } from 'react'
import { View, Text, Image } from 'react-native-web'
import styles from '../AllowNotificationsStyles'
import logoVincufy from '@/assets/AllowNotifications/hablando.png'
import { getBrowserInstructionSteps } from '@/models/notification.model'
import { theme } from '@/theme'

const AskPermissionInstructions = () => {
  const [browserInstructionSteps] = useState(() => getBrowserInstructionSteps())

  const DecoratedItem = ({ stepNum, lastStep, stepInstruction }) => (
    <View style={styles.instructionsStep}>
      <View style={styles.instructionsStepDecoration}>
        <View style={styles.instructionsStepDecorationCircle}>
          <Text style={styles.instructionsStepDecorationCircleNum}>
            {stepNum}
          </Text>
        </View>
        {!lastStep && <View style={styles.instructionsStepDecorationLine} />}
      </View>
      <Text style={styles.instructionsStepText}>{stepInstruction}</Text>
    </View>
  )

  return (
    <View style={styles.containerDenied}>
      <Image source={logoVincufy} style={styles.image} />
      <Text style={styles.title}>Notificaciones</Text>
      <Text style={styles.description}>
        Te vamos a avisar cuando recibas solicitudes de vinculación, mensajes e
        invitaciones a eventos exclusivos !
      </Text>
      <View>
        {!browserInstructionSteps ? (
          <Text>
            Por favor, revisá la configuración de tu navegador para activar las
            notificaciones.
          </Text>
        ) : (
          <View style={[styles.bckgImage,theme.bgGradientCenter]}>
            <>
              {browserInstructionSteps.map((step, index) => (
                <DecoratedItem
                  key={`step-${index + 1}`}
                  stepNum={index + 1}
                  lastStep={browserInstructionSteps.length === index + 1}
                  stepInstruction={step}
                />
              ))}
            </>
          </View>
        )}
      </View>
    </View>
  )
}

export default memo(AskPermissionInstructions)
