import { StyleSheet, Dimensions } from 'react-native-web'

const { width } = Dimensions.get('window')
const isLargeScreen = width >= 768

export default StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 50000,
  },
  modal: {
    width: isLargeScreen ? width * 0.3 : width * 0.9,
    minHeight: '50%',
    maxHeight: '90vh',
    paddingTop: 20,
    paddingBottom: 15,
    paddingHorizontal: 5,
    borderRadius: 10,
  },
  backgroundImage: {
    borderRadius: 10,
  },
  form: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    maxWidth: '100vw',
    overflowY: 'scroll',
  },
  scroll: {
    width: '100%',
    paddingHorizontal: 15,
    paddingBottom: 10,
  },
  logoVincufy: {
    marginTop: 5,
    marginBottom: 5,
    height: 60,
    width: '100%', // Asegúrate de definir el ancho
  },
  inputContainer: {
    marginTop: 20,
    marginBottom: '4%',
    width: '100%',
  },
  loginButton: {
    width: '100%',
    maxWidth: 343,
    height: 45,
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    fontSize: 15,
    marginTop: 5,
  },
  error: {
    color: 'rgb(215, 64, 64)',
    marginTop: 8,
    marginBottom: -10,
  },
  link: {
    marginTop: 15,
  },
  span: {
    color: 'white',
    fontWeight: '500',
    textDecorationLine: 'underline',
    marginLeft: 5,
  },
  buttonText: {
    color: 'white',
  },
  closeButton: {
    flexDirection: 'row-reverse',
    marginRight: 10,
  },
  closeButtonText: {
    color: 'white',
    fontSize: 17,
  },
})
