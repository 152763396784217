import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: 150,
    height: 200,
  },
  flyerDiv: {
    height: '50%',
    width: '100%',
    marginVertical: 0,
    overflow: 'hidden',
  },
  flyerImage: {
    height: '100%',
    width: '100%',
  },
  title: {
    color: 'white',
    fontWeight: '500',
    marginTop: 40,
  },
  subtitle: {
    fontSize: 14,
    color: 'white',
    fontWeight: '300',
    marginBottom: 20,
    textAlign:'center'
  },
  backgroundImage: {
    height: '100%',
    alignItems: 'center',
    borderRadius: 10,
    overflow: 'hidden',
  },
});

export default styles;
