import logoVincufy from '@/assets/Header/logoHeaderVincufy.png'
import styles from './HeaderStyles'
import { View, Image } from 'react-native'
import backIcon from '@/assets/Section/goBack.png'
import { useAppDispatch } from '@/redux/store'
import { setShouldHideTabBar } from '@/redux/thunks'
import CustomPressable from '../CustomPressable/CustomPressable'
import { theme } from '@/theme'

const Header = ({
  goBackFunc = null,
}: {
  goBackFunc: null | (() => boolean)
}) => {
  const dispatch = useAppDispatch()

  const goBack = () => {
    if (goBackFunc) {
      goBackFunc()
    }
  }
  return (
    <View style={[styles.bckgImg, theme.bgGradientRight]}>
      {!!goBackFunc && (
        <CustomPressable
          style={styles.iconButton}
          onPress={() => {
            goBack()
            dispatch(setShouldHideTabBar(false))
          }}
        >
          <Image source={backIcon} style={styles.icon} />
        </CustomPressable>
      )}
      <View style={styles.background}>
        <Image source={logoVincufy} style={styles.logo} resizeMode="stretch" />
      </View>
    </View>
  )
}

export default Header
