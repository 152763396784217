import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import styles from './LoginStyles'
import { theme } from '@/theme'
import { useLoginController } from './controllers/useLoginController'
import { Text, TextInput, Image, View } from 'react-native-web'
import { CustomPressable, MessageModal, Password } from '@/components'
import logoVincufy from '@/assets/Header/logoVincufy.png'
import { useModalContext } from '@/components/Modals/context'

const Login = () => {
  const {
    handleChange,
    handleSubmit,
    loginBtnDisabled,
    input,
    formErrors,
    errorLoginMessage,
    isLoadingLogin,
  } = useLoginController()
  const { openModal: openMessageModal } = useModalContext('MessageModal_1')

  return (
    <View style={[styles.backgroundImage, theme.bgGradientCenter]}>
      <KeyboardAwareScrollView
        contentContainerStyle={styles.gral}
        keyboardShouldPersistTaps="handled"
      >
        <form style={styles.form}>
          <Image
            source={logoVincufy}
            style={styles.logoVincufy}
            resizeMode="contain"
          />
          <View style={styles.loginInputs}>
            <TextInput
              value={input.email}
              inputMode="email"
              textContentType="emailAddress"
              placeholder="Ingresá tu mail"
              placeholderTextColor="rgb(96, 96, 96)"
              style={theme.inputAuth}
              onChangeText={(e: string) => handleChange(e, 'email')}
              autoCapitalize="none"
              autoCorrect={false}
            />
            {!!formErrors.email && (
              <Text style={styles.error}>{formErrors.email}</Text>
            )}

            <Password
              value={input.password}
              onChange={(e) => handleChange(e, 'password')}
              hasError={!!formErrors.password}
              placeholder={'Ingresá tu contraseña'}
              autoComplete="password"
            />
            {!!formErrors.password && (
              <Text style={styles.error}>{formErrors.password}</Text>
            )}
          </View>

          <CustomPressable
            onPress={handleSubmit}
            style={styles.submitBtn}
            disabled={loginBtnDisabled}
            isLoading={isLoadingLogin}
          >
            <Text style={styles.buttonText}>Iniciar sesión</Text>
          </CustomPressable>
          {errorLoginMessage && (
            <Text style={styles.error}>{errorLoginMessage}</Text>
          )}
          <View style={styles.loginFooter}>
            <Text style={styles.text}>¿Todavía no creaste tu perfil? </Text>
            <CustomPressable onPress={openMessageModal}>
              <Text style={styles.span}>Participa de un evento</Text>
            </CustomPressable>
          </View>
        </form>
        <MessageModal
          message={
            'Si ya creaste tu perfil iniciá sesión con tu mail y contraseña. Si es la primera vez que ingresas a Vincufy, para poder crear tu cuenta, tenés que entrar desde el link del evento compartido por el organizador.'
          }
        />
      </KeyboardAwareScrollView>
    </View>
  )
}

export default Login
