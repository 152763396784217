import { View, Text, Modal, Linking } from 'react-native'
import styles from './MessageModalStyles'
import CustomPressable from '@/components/CustomPressable/CustomPressable'
import { theme } from '@/theme'
import { useModalContext } from '../context'

export const MessageModal = ({
  messageModalId = '1', // Necesary when multiple MessageModal in same page
  message,
}: {
  messageModalId?: string
  message: string
}) => {
  const { isOpenModal, closeModal } = useModalContext(
    'MessageModal_' + messageModalId,
  )

  const contactVincufy = () => {
    Linking.openURL(
      `https://wa.me/${'543512843532'}?text=${encodeURIComponent(`Hola! Vengo desde la app de Vincufy. Necesito ayuda con lo siguiente...`)}`,
    )
  }

  return (
    <Modal transparent={true} visible={isOpenModal} animationType="fade">
      <View style={styles.background}>
        <View style={[styles.modal, theme.bgGradientCenter]}>
          <Text style={styles.text}>{message}</Text>
          <View style={styles.buttonContainer}>
            <CustomPressable style={styles.btn} onPress={closeModal}>
              <Text style={styles.btnText}>Aceptar</Text>
            </CustomPressable>
            <CustomPressable style={styles.btnCancel} onPress={contactVincufy}>
              <Text style={styles.btnText}>Más ayuda</Text>
            </CustomPressable>
          </View>
        </View>
      </View>
    </Modal>
  )
}
