import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { ParticipantDetail, EventBonds, EventVirtualRoom } from '@/views'

export type VirtualRoomNavigatorParamList = {
  EventVirtualRoom: {
    eventId: string
  }
  EventVirtualRoomParticipant: {
    eventId: string
    participantId: string
  }
  EventBonds: {
    eventId: string
  }
  EventBondsParticipant: {
    eventId: string
    participantId: string
  }
}

const VirtualRoomStack =
  createNativeStackNavigator<VirtualRoomNavigatorParamList>()

export const VirtualRoomNavigator = () => {
  return (
    <VirtualRoomStack.Navigator
      initialRouteName={'EventVirtualRoom'}
      screenOptions={{ headerShown: false }}
    >
      <VirtualRoomStack.Screen
        name="EventVirtualRoom"
        component={EventVirtualRoom}
        options={{ title: 'Sala Virtual - Vincufy' }}
      />
      <VirtualRoomStack.Screen
        name="EventVirtualRoomParticipant"
        component={ParticipantDetail}
        options={{ title: 'Participante - Vincufy' }}
      />
      <VirtualRoomStack.Screen
        name="EventBonds"
        component={EventBonds}
        options={{ title: 'Solicitudes - Vincufy' }}
      />
      <VirtualRoomStack.Screen
        name="EventBondsParticipant"
        component={ParticipantDetail}
        options={{ title: 'Participante - Vincufy' }}
      />
    </VirtualRoomStack.Navigator>
  )
}
