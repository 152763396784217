import { useAppDispatch, useAppSelector } from '@/redux/store'
import { Text } from 'react-native'
import styles from './TicketSectionStyles'
import { AuthModal, ConfirmModal, CustomPressable } from '@/components'
import { EventType, ParticipantStatus, TEventTicketTypeModel } from '@/models'
import { getUserEvents, requestFreeTicket } from '@/redux/thunks'
import { useEffect, useState } from 'react'
import { customAlert } from '@/utils'
import { postLoggedFlowFinished } from '@/redux/actions'
import { TConfirmModal } from '@/components/Modals/ConfirmModal/ConfirmModal'
import { navigateTo } from 'routes/utils'

export const TicketSection = ({
  eventTicketTypes,
  participantStatus,
  eventId,
  eventType,
}: {
  eventTicketTypes: TEventTicketTypeModel[]
  participantStatus: ParticipantStatus | null
  eventId: string
  eventType: EventType
}) => {
  const dispatch = useAppDispatch()
  const isLogged = useAppSelector((state) => state.auth.isLogged)
  const currentUserProfileId = useAppSelector(
    (state) => state.user.userProfiles?.[0]?.id || null,
  )
  const loggedOrigin = useAppSelector((state) => state.auth.loggedOrigin)
  // Componente considerando que por ahora tomaremos un unico tipo de tickets
  // y que son todos gratis. Tambien utilizamos por defecto el primer userProfile
  const currentTicketType = eventTicketTypes[0] || {}

  const [isOpenAuthModal, setIsOpenAuthModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState<TConfirmModal>(null)
  const [isLoading, setIsLoading] = useState(false)
  const functionalityName = 'requestTickets'

  const requestTicket = async () => {
    if (!currentUserProfileId) {
      customAlert('Error, usuario no registrado.')
      return
    }

    setIsLoading(true)

    await dispatch(
      requestFreeTicket({
        eventId,
        eventTicketTypeId: currentTicketType.id,
        userProfileId: currentUserProfileId,
      }),
    ).unwrap()

    await dispatch(getUserEvents()).unwrap()

    dispatch(postLoggedFlowFinished())
    setIsLoading(false)
    setConfirmModal(null)

    navigateTo('TransitionScreen', { eventId })

    // if (requestResult.success) {
    //   navigation.navigate('TransitionScreen')
    // } else {
    //   console.warn('Error, vuelva a intentarlo más tarde.')
    //   Alert.alert('Error, vuelva a intentarlo más tarde.')
    // }
  }

  const getButtonConfig = (): {
    display: boolean
    text: string
    postAuthConfirmText: string
    action: null | (() => Promise<void>)
  } => {
    if (participantStatus === null) {
      if (currentTicketType.hasTicketsRequest) {
        return {
          display: true,
          text: 'Solicitar entrada',
          postAuthConfirmText: '¿Deseas solicitar una entrada?',
          action: requestTicket,
        }
      } else {
        return {
          display: true,
          text: eventType === EventType.EVENT ? 'Anotarme al evento' : 'Unirme',
          postAuthConfirmText: `¿Deseas unirte ${eventType === EventType.EVENT ? 'al evento' : 'a la comunidad'}?`,
          action: requestTicket,
        }
      }
    } else if (
      participantStatus == ParticipantStatus.PENDING ||
      participantStatus == ParticipantStatus.REJECTED
    ) {
      return {
        display: true,
        text: 'Entrada pendiente de confirmación',
        postAuthConfirmText: 'Entrada pendiente de confirmación',
        action: null,
      }
    } else if (
      (participantStatus == ParticipantStatus.ACCEPTED ||
        participantStatus == ParticipantStatus.WITH_TICKET) &&
      eventType === EventType.EVENT
    ) {
      return {
        display: true,
        text: 'Mis Tickets',
        postAuthConfirmText: 'Ir a mis Tickets',
        action: async () => {
          navigateTo('MyTickets', { eventId })
        },
      }
    } else {
      return {
        display: false,
        text: '',
        postAuthConfirmText: '',
        action: null,
      }
    }
  }

  const buttonConfig = getButtonConfig()

  useEffect(() => {
    if (loggedOrigin.functionality === functionalityName) {
      setIsOpenAuthModal(false)

      setConfirmModal({
        text: buttonConfig.postAuthConfirmText,
        confirmFunc:
          buttonConfig.action === null ? async () => {} : buttonConfig.action,
      })
    }
  }, [loggedOrigin])

  const handleCloseConfirmModal = (newValue: TConfirmModal) => {
    setConfirmModal(newValue)

    dispatch(postLoggedFlowFinished())
  }

  if (!buttonConfig.display) return <></>

  return (
    <>
      <CustomPressable
        onPress={
          isLogged ? buttonConfig.action : () => setIsOpenAuthModal(true)
        }
        style={!!buttonConfig.action ? styles.btnActive : styles.btnPending}
        disabled={buttonConfig.action === null}
        isLoading={isLoading}
      >
        <Text style={styles.btnText}>{buttonConfig.text}</Text>
      </CustomPressable>
      <AuthModal
        isOpen={isOpenAuthModal}
        close={() => setIsOpenAuthModal(false)}
        fromFunctionality={{
          functionality: functionalityName,
          params: {
            eventId,
          },
        }}
      />
      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={handleCloseConfirmModal}
      />
    </>
  )
}
