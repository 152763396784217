import { StyleSheet, Dimensions } from 'react-native'

const SCREEN_WIDTH = Dimensions.get('window').width
const NUM_COLUMNS = 3
const ITEM_SIZE = SCREEN_WIDTH / NUM_COLUMNS

export default StyleSheet.create({
  container: {
    width: ITEM_SIZE,
    height: ITEM_SIZE,
    margin: 1,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  containerName: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textName: {
    color: 'white',
    textAlign: 'center',
    fontSize: 13,
    marginBottom: 2,
  },
})
