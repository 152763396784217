import { View, Text } from 'react-native'
import styles from './ExitWhileEditingProfileModalStyles'
import { useModalContext } from '../context'
import { CustomModal, CustomPressable } from '@/components'
import { theme } from '@/theme'

const ExitWhileEditingProfileModal = () => {
  const { isOpenModal, modalAction, closeModal } = useModalContext(
    'ExitWhileEditingProfileModal',
  )

  const handleExitWithoutSave = () => {
    modalAction?.()
  }

  return (
    <CustomModal isOpen={isOpenModal} onClose={closeModal}>
      <View style={[styles.modal, theme.bgGradientRight]}>
        <Text style={styles.text}>Hay cambios sin guardar</Text>
        <View style={styles.buttonContainer}>
          <CustomPressable onPress={handleExitWithoutSave} style={styles.btn}>
            <Text style={styles.btnText}>Salir sin guardar</Text>
          </CustomPressable>
          <CustomPressable onPress={closeModal} style={styles.btn}>
            <Text style={styles.btnText}>Continuar editando</Text>
          </CustomPressable>
        </View>
      </View>
    </CustomModal>
  )
}

export default ExitWhileEditingProfileModal
