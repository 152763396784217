import { useEffect } from 'react'
import { fetchStatus } from '@/models/fetch.model'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { getEventBondsAction, getEventParticipantsAction } from '@/redux/thunks'
import { TEventParticipants } from '@/redux/reducers/event.reducer'

export const useParticipantsGridController = (eventId: string) => {
  const dispatch = useAppDispatch()
  const isLoadingAuth = useAppSelector((state) => state.auth.isLoading)
  const userProfile = useAppSelector((state) => state.user.userProfiles[0])
  const initialEventParticipants: TEventParticipants = {
    participantsId: [],
    cursor: undefined,
    hasMore: true,
    status: fetchStatus.TO_FETCH,
    error: null,
  }
  const eventParticipants = useAppSelector(
    (state) =>
      state.event.eventsParticipants[eventId] || initialEventParticipants,
  )

  const participantsNotFetched =
    !eventParticipants.status ||
    eventParticipants.status === fetchStatus.TO_FETCH
  const participantsLoading = eventParticipants.status === fetchStatus.LOADING
  const participantsSuccess = eventParticipants.status === fetchStatus.SUCCESS

  const totalParticipants = eventParticipants.participantsId?.length

  const isLoading =
    isLoadingAuth ||
    participantsNotFetched ||
    (participantsLoading && !totalParticipants)
  const isLoadingMore = participantsLoading && !!totalParticipants

  const getEventParticipants = (
    currentEventId: string,
    initialFetch: boolean,
  ) => {
    dispatch(getEventParticipantsAction({ currentEventId, initialFetch }))
  }

  useEffect(() => {
    if (eventId && participantsNotFetched) {
      getEventParticipants(eventId, true)
      // No porque se esta llamando en la página para poner el color en boton Mis Solicitudes
      // dispatch(getEventBondsAction(eventId))
    }
  }, [eventId, participantsNotFetched])

  const fetchMoreParticipants = () => {
    if (eventId && participantsSuccess && eventParticipants.hasMore) {
      getEventParticipants(eventId, false)
    }
  }

  const refreshParticipants = () => {
    if (eventId) {
      getEventParticipants(eventId, true)
    }
  }

  return {
    participantsId: eventParticipants.participantsId,
    participantsError: eventParticipants.error,
    userProfile,
    isLoading,
    isLoadingMore,
    fetchMoreParticipants,
    refreshParticipants,
  }
}
