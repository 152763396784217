import { Modal, Pressable } from 'react-native-web'
import styles from './CustomModalStyles'
import CustomPressable from '../CustomPressable/CustomPressable'

const CustomModal = ({
  overlayStyle = [],
  isOpen = false,
  onClose = () => {},
  children,
  ...props
}) => (
  <Modal
    visible={isOpen}
    transparent={true}
    onRequestClose={onClose}
    animationType="fade"
    {...props}
  >
    <Pressable
      style={() => {
        const baseStyle =
          typeof overlayStyle === 'function'
            ? overlayStyle(state)
            : overlayStyle
        return [
          styles.overlay,
          ...(Array.isArray(baseStyle) ? baseStyle : [baseStyle]),
        ]
      }}
      onPress={onClose}
    >
      <CustomPressable withFeedback={false}>{children}</CustomPressable>
    </Pressable>
  </Modal>
)

export default CustomModal
