import { createContext, useState } from 'react'

export type TModalToOpen = {
  modalName: string
  modalAction: (v?: any) => void
}

const ModalContext = createContext<{
  modalsToOpen: TModalToOpen[]
  openSpecificModal: (
    modalName: TModalToOpen['modalName'],
    modalAction: TModalToOpen['modalAction'],
  ) => void
  closeSpecificModal: (specificModalName: TModalToOpen['modalName']) => void
}>({
  modalsToOpen: [],
  openSpecificModal: (modalName, modalAction) => {},
  closeSpecificModal: (specificModalName: TModalToOpen['modalName']) => {},
})

const ModalProvider = ({ children }) => {
  const [modalsToOpen, setModalsToOpen] = useState<TModalToOpen[]>([])

  const openSpecificModal = (
    modalName: TModalToOpen['modalName'],
    modalAction: TModalToOpen['modalAction'],
  ) => {
    setModalsToOpen((lastModalsToOpen) => [
      ...lastModalsToOpen,
      { modalName, modalAction },
    ])
  }

  const closeSpecificModal = (specificModalName: TModalToOpen['modalName']) => {
    setModalsToOpen((lastModalsToOpen) =>
      lastModalsToOpen.filter(
        (modalToOpen) => modalToOpen.modalName !== specificModalName,
      ),
    )
  }

  return (
    <ModalContext.Provider
      value={{ modalsToOpen, openSpecificModal, closeSpecificModal }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export { ModalProvider, ModalContext }
