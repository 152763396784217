import { View, Text, Modal, Image, Linking } from 'react-native'
import styles from './GetContactMethodModalStyles'
import linkedinIcon from '../../../assets/People/linkedin.png'
import mailIcon from '../../../assets/People/gmail.png'
import whatsappIcon from '../../../assets/People/whatsapp.png'
import instagramIcon from '../../../assets/People/instagram.png'
import CustomPressable from '@/components/CustomPressable/CustomPressable'
import { TParticipantDetailModel } from '@/models'
import { theme } from '@/theme'

export default function GetContactMethodModal({
  participantContactMethods,
  isOpenContactModal,
  setIsOpenContactModal,
}: {
  participantContactMethods?: TParticipantDetailModel['contactMethods']
  isOpenContactModal: boolean
  setIsOpenContactModal: (v: boolean) => void
}) {
  const openLink = (type, value) => {
    if (value) {
      switch (type) {
        case 'Instagram':
        case 'LinkedIn':
          Linking.openURL(value)
          break
        case 'Mail':
          Linking.openURL(`mailto:${value}`)
          break
        case 'WhatsApp':
          Linking.openURL(`https://wa.me/${value}`)
          break
        default:
          break
      }
    }
  }

  const availableMethods = [
    {
      type: 'Instagram',
      value: participantContactMethods?.instagram
        ? `https://instagram.com/${participantContactMethods.instagram}`
        : null,
      icon: instagramIcon,
    },
    {
      type: 'LinkedIn',
      value: participantContactMethods?.linkedin
        ? `https://www.linkedin.com/in/${participantContactMethods.linkedin}`
        : null,
      icon: linkedinIcon,
    },
    {
      type: 'Mail',
      value: participantContactMethods?.email || null,
      icon: mailIcon,
    },
    {
      type: 'WhatsApp',
      value: participantContactMethods?.whatsapp || null,
      icon: whatsappIcon,
    },
  ].filter((method) => method.value)

  return (
    <Modal transparent={true} visible={isOpenContactModal} animationType="fade">
      <View style={styles.background}>
        <View style={[styles.modal, theme.bgGradientRight]}>
          <CustomPressable
            style={styles.closeButton}
            onPress={() => setIsOpenContactModal(false)}
          >
            <Text style={styles.closeButtonText}>✕</Text>
          </CustomPressable>
          {availableMethods.length > 0 ? (
            <>
              <Text style={styles.text}>Contactate conmigo por:</Text>
              <View style={styles.contactMethodsContainer}>
                {availableMethods.map((method) => (
                  <CustomPressable
                    key={method.type}
                    style={styles.contactMethod}
                    onPress={() => openLink(method.type, method.value)}
                  >
                    <Image source={method.icon} style={styles.icon} />
                    <Text style={styles.methodText}>{method.type}</Text>
                  </CustomPressable>
                ))}
              </View>
            </>
          ) : (
            <Text style={styles.noContactText}>
              No hay métodos de contacto disponibles.
            </Text>
          )}
        </View>
      </View>
    </Modal>
  )
}
