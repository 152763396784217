import { useEffect, useState } from 'react'
import { formatDateAndTime } from '@/utils'
import { View, Image, Text } from 'react-native'
import styles from './EventInfoStyles'
import clock from '@/assets/MyEvents/hora.png'
import calendar from '@/assets/MyEvents/fecha.png'
import location from '@/assets/MyEvents/ubicacion.png'
import { updateRemainingSeconds } from '@/utils'
import CounterDown from '@/views/SecondarySections/EventVirtualRoom/components/PreVirtualRoom/CounterDown/CounterDown'

export const EventInfo = ({
  startDate,
  endDate,
  virtualRoomStartAt,
  virtualRoomEndAt,
  locationName,
  description,
}) => {
  const formattedStartDate = formatDateAndTime(startDate)?.date
  const formattedEventTime = `${formatDateAndTime(startDate)?.time} - ${formatDateAndTime(endDate)?.time} hs`
  const [remainingSecondsToStart, setRemainingSecondsToStart] = useState(0)
  const [remainingSecondsToFinish, setRemainingSecondsToFinish] = useState(0)

  useEffect(() => {
    updateRemainingSeconds(virtualRoomStartAt, setRemainingSecondsToStart)
    updateRemainingSeconds(virtualRoomEndAt, setRemainingSecondsToFinish)

    const interval = setInterval(() => {
      if (remainingSecondsToStart > 0) {
        setRemainingSecondsToStart((prev) => (prev > 0 ? prev - 1 : 0))
      } else if (remainingSecondsToFinish > 0) {
        setRemainingSecondsToFinish((prev) => (prev > 0 ? prev - 1 : 0))
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [remainingSecondsToStart, remainingSecondsToFinish])

  return (
    <View style={styles.contentInfoRows}>
      <Text style={styles.label}>Información principal</Text>
      <View style={styles.dateInfoContainer}>
        <View style={styles.dateInfoRow}>
          <Image source={calendar} style={styles.icon} />
          <Text style={styles.eventInfoText}>{formattedStartDate}</Text>
        </View>
        <View style={styles.dateInfoRow}>
          <Image source={clock} style={styles.icon} />
          <Text style={styles.eventInfoText}>{formattedEventTime}</Text>
        </View>
        <View style={styles.dateInfoRow}>
          <Image source={location} style={styles.icon} />
          <Text style={styles.eventInfoText}>{locationName}</Text>
        </View>
      </View>
      <Text style={styles.label}>
        {remainingSecondsToStart > 0 ? 'Apertura' : 'Cierre'} de Sala Virtual
      </Text>
      <CounterDown
        remainingSecondsToStart={
          remainingSecondsToStart > 0
            ? remainingSecondsToStart
            : remainingSecondsToFinish
        }
      />
      <Text style={styles.label}>Descripción</Text>
      <View style={styles.descriptionRow}>
        <Text style={styles.descriptionText}>{description}</Text>
      </View>
    </View>
  )
}
