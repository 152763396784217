import {
  GET_PARTICIPANT_BY_CODE,
  GET_PARTICIPANT_BY_CODE_ERROR,
  GET_PARTICIPANT_BY_CODE_LOADING,
  GET_USER_EVENTS_TICKETS,
  REQUEST_FREE_TICKET,
  REQUEST_FREE_TICKET_ERROR,
  REQUEST_FREE_TICKET_LOADING,
  SCAN_TICKET,
  SCAN_TICKET_ERROR,
  SCAN_TICKET_LOADING,
  SET_PARTICIPANTS,
} from '../constants'
import { HTTP_METHOD, vincufyApiCall } from '@/config/vincufyApi'
import { usersTicketsMock } from '@/mocks'
import { createCustomAsyncThunk } from '../store/createCustomAsyncThunk'
import {
  ISODateString,
  ReceiptStatus,
  TParticipantDetailModel,
  TTicketDetailModel,
  TTicketsRequestModel,
} from '@/models'
import { participantsMock } from '@/mocks/VirtualRoom/participantsMock'

export const getUserEventTickets = createCustomAsyncThunk(
  'ticket/getUserEventTickets',
  async (eventId: string, { dispatch }) => {
    try {
      const respBody = await vincufyApiCall<{ tickets: TTicketDetailModel[] }>({
        method: HTTP_METHOD.GET,
        endpoint: `/event/tickets/${eventId}`,
        body: {},
        mock: { tickets: usersTicketsMock },
        forceMock: false,
      })

      dispatch({
        type: GET_USER_EVENTS_TICKETS,
        payload: respBody.tickets,
      })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
    }
  },
)

export const requestFreeTicket = createCustomAsyncThunk(
  'ticket/requestFreeTicket',
  async (
    {
      eventId,
      eventTicketTypeId,
      userProfileId,
    }: { eventId: string; eventTicketTypeId: string; userProfileId: string },
    { dispatch },
  ) => {
    try {
      dispatch({ type: REQUEST_FREE_TICKET_LOADING })

      const respBody = await vincufyApiCall<{
        ticketsRequest: TTicketsRequestModel
      }>({
        method: HTTP_METHOD.POST,
        endpoint: `/event/tickets`,
        body: {
          eventId,
          ownTicket: {
            eventTicketTypeId,
            userProfileId,
          },
          extraTickets: [],
        },
        mock: {
          ticketsRequest: {
            participant: {
              id: participantsMock[0].participantId,
              userId: participantsMock[0].userId,
              name: participantsMock[0].name,
              lastName: participantsMock[0].lastName,
              userProfileId: participantsMock[0].userProfileId,
              profilePicture: participantsMock[0].profilePictures[0],
              eventId: participantsMock[0].eventId,
              ticketId: participantsMock[0].ticketId,
              status: participantsMock[0].status,
            },
            tickets: [],
            receipt: {
              id: '123',
              userId: '123',
              status: ReceiptStatus.PAID,
              totalAmount: '0',
            },
          },
        },
        forceMock: false,
      })

      dispatch({
        type: REQUEST_FREE_TICKET,
        payload: { ticketsRequest: respBody.ticketsRequest },
      })

      return { success: true }
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      dispatch({
        type: REQUEST_FREE_TICKET_ERROR,
        payload: {
          error: errorMsg,
        },
      })

      return { success: false }
    }
  },
)

export const getParticipantByCode = createCustomAsyncThunk(
  'ticket/getParticipantByCode',
  async (
    { eventId, code }: { eventId: string; code: string },
    { dispatch },
  ) => {
    try {
      dispatch({ type: GET_PARTICIPANT_BY_CODE_LOADING })

      const respBody = await vincufyApiCall<{
        participant: TParticipantDetailModel
        wasFound: boolean
      }>({
        method: HTTP_METHOD.GET,
        endpoint: `/event/unique/${eventId}/participant/code/${code}`,
        body: {},
        mock: { participant: participantsMock[0], wasFound: true },
        forceMock: false,
      })

      if (!respBody.wasFound) {
        dispatch({
          type: GET_PARTICIPANT_BY_CODE_ERROR,
          payload: {
            participants: [respBody.participant],
          },
        })
        return
      }

      dispatch({
        type: SET_PARTICIPANTS,
        payload: {
          participants: [
            {
              ...respBody.participant,
              bond: undefined,
            },
          ],
        },
      })
      dispatch({
        type: GET_PARTICIPANT_BY_CODE,
        payload: {
          participantId: respBody.participant.participantId,
        },
      })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      dispatch({
        type: GET_PARTICIPANT_BY_CODE_ERROR,
        payload: {
          error: errorMsg,
        },
      })
    }
  },
)

export const scanTicket = createCustomAsyncThunk(
  'ticket/scanTicket',
  async (
    {
      eventId,
      ticketId,
      participantId,
    }: { eventId: string; ticketId: string; participantId: string },
    { dispatch },
  ) => {
    try {
      dispatch({ type: SCAN_TICKET_LOADING })

      const respBody = await vincufyApiCall<{
        scannedAt: ISODateString
      }>({
        method: HTTP_METHOD.PATCH,
        endpoint: `/event/unique/${eventId}/ticket/${ticketId}`,
        body: {},
        mock: { scannedAt: new Date().toISOString() },
        forceMock: false,
      })

      if (!respBody.scannedAt) {
        dispatch({
          type: SCAN_TICKET_ERROR,
          payload: {
            error: 'Scan failed',
          },
        })
        return
      }

      dispatch({
        type: SET_PARTICIPANTS,
        payload: {
          participants: [
            { participantId, ticketScannedAt: respBody.scannedAt },
          ],
        },
      })
      dispatch({ type: SCAN_TICKET })
    } catch (err) {
      const errorMsg = err instanceof Error ? err?.message : String(err)
      console.warn(errorMsg)
      dispatch({
        type: SCAN_TICKET_ERROR,
        payload: {
          error: errorMsg,
        },
      })
    }
  },
)

// export const createTickets = async (encryptedData) => {
//   try {
//     await vincufyApi.post(`/tickets`, {encryptedData});
//   } catch (err) {
//     const errorMsg = err instanceof Error ? err?.message : String(err)
//     console.log(error, "Error al crear los tickets");
//   }
// };

// export const transferTicket = async (
//   eventId,
//   ticketId,
//   userIdBuyer,
//   userIdReceiver
// ) => {
//   try {
//     await vincufyApi.put(
//       `/tickets/transfer/${eventId}/${ticketId}/${userIdBuyer}/${userIdReceiver}`
//     );
//   } catch (err) {
//     const errorMsg = err instanceof Error ? err?.message : String(err)
//     console.warn(error?.message)
//   }
// };
