import { View, Text, TextInput, Image } from 'react-native-web'
import { theme } from '@/theme'
import { CustomPressable, Password } from '@/components'
import logoVincufy from '@/assets/Header/logoVincufy.png'
import { useRegisterController } from './controllers/useRegisterController'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import styles from './RegisterStyles'
import { navigateTo } from 'routes/utils'

const Register = () => {
  const {
    handleChange,
    handleSubmit,
    registerBtnDisabled,
    input,
    formErrors,
    errorRegisterMessage,
    isLoadingRegister,
  } = useRegisterController()

  return (
    <View style={[styles.backgroundImage, theme.bgGradientCenter]}>
      <KeyboardAwareScrollView
        contentContainerStyle={styles.gral}
        keyboardShouldPersistTaps="handled"
      >
        <form style={styles.form}>
          <Image
            source={logoVincufy}
            style={styles.logoVincufy}
            resizeMode="contain"
          />
          <View style={styles.registerInputs}>
            <TextInput
              value={input.email}
              inputMode="email"
              textContentType="emailAddress"
              placeholder="Ingresá tu mail"
              placeholderTextColor="rgb(96, 96, 96)"
              style={theme.inputAuth}
              onChangeText={(e: string) => handleChange(e, 'email')}
              autoCapitalize="none"
              autoCorrect={false}
            />
            {!!formErrors.email && (
              <Text style={styles.error}>{formErrors.email}</Text>
            )}

            <Password
              value={input.password}
              onChange={(e) => handleChange(e, 'password')}
              hasError={!!formErrors.password}
              placeholder={'Ingresá tu contraseña'}
              autoComplete={'new-password'}
            />
            {!!formErrors.password && (
              <Text style={styles.error}>{formErrors.password}</Text>
            )}
            <Password
              value={input.confirmPassword}
              onChange={(e) => handleChange(e, 'confirmPassword')}
              hasError={!!formErrors.confirmPassword}
              placeholder={'Confirmá tu contraseña'}
              autoComplete={'new-password'}
            />
            {!!formErrors.confirmPassword && (
              <Text style={styles.error}>{formErrors.confirmPassword}</Text>
            )}
          </View>

          <CustomPressable
            onPress={handleSubmit}
            style={styles.submitBtn}
            disabled={registerBtnDisabled}
            isLoading={isLoadingRegister}
          >
            <Text style={styles.buttonText}>Continuar</Text>
          </CustomPressable>
          {errorRegisterMessage && (
            <Text style={styles.error}>{errorRegisterMessage}</Text>
          )}
          <View style={styles.registerFooter}>
            <Text style={styles.text}>¿Ya tenés una cuenta?</Text>
            <CustomPressable onPress={() => navigateTo('Login', {})}>
              <Text style={styles.span}>Iniciá sesión</Text>
            </CustomPressable>
          </View>

          {/* Modal para mostrar mensajes de error */}
          {/* <Modal
          visible={!!errorRegisterMessage}
          transparent={true}
          animationType="slide"
          onRequestClose={() => setIsOpenInfo(false)}
        >
          <View style={styles.modalContainer}>
            <Text>{errorRegisterMessage}</Text>
          </View>
        </Modal> */}
        </form>
      </KeyboardAwareScrollView>
    </View>
  )
}

export default Register
