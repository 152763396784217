import { View, Modal, Text, Image } from 'react-native'
import { styles, mail, password, phone } from './ModalProfileSettingsStyles'
import { useAppDispatch } from '@/redux/store'
import { logout as logoutThunk } from '@/redux/thunks'
import CustomPressable from '@/components/CustomPressable/CustomPressable'
import { theme } from '@/theme'
import { navigateTo } from 'routes/utils'

const ProfileSettings = ({ openSettingsModal, setOpenSettingsModal }) => {
  const dispatch = useAppDispatch()

  const handleModalClose = (e) => {
    if (e.target === e.currentTarget) {
      setOpenSettingsModal(false)
    }
  }

  const change = (payload) => {
    if (payload !== 'password') {
      navigateTo('ChangeInfoSettings', {
        item: payload,
      })
      setOpenSettingsModal(false)
    } else {
      // navigation.navigate(`/forgotPw2`)
    }
  }

  const logout = async () => {
    try {
      await dispatch(logoutThunk()).unwrap()
    } catch (error) {}
  }

  return (
    <>
      <Modal
        visible={openSettingsModal}
        animationType="fade"
        transparent={true}
        onRequestClose={handleModalClose}
      >
        <CustomPressable
          style={styles.modalOverlay}
          activeOpacity={1}
          onPress={handleModalClose}
          withFeedback={false}
        >
          <View style={[styles.modalContent, theme.bgGradientRight]}>
            {/* <Image
              source={backgroundImage}
              style={styles.bckgImg}
              resizeMode="stretch"
            /> */}
            <View style={styles.buttonsContainer}>
              {/* <CustomPressable
                style={styles.button}
                activeOpacity={1}
                onPress={() => change('mail')}
              >
                <Image source={mail} style={styles.icon} />
                <Text style={styles.text}>Cambiar mail</Text>
              </CustomPressable> */}
              {/* <CustomPressable
                style={styles.button}
                activeOpacity={1}
                onPress={() => change('password')}
              >
                <Image source={password} style={styles.icon} />
                <Text style={styles.text}>Cambiar contraseña</Text>
              </CustomPressable> */}
              {/* <CustomPressable
                style={styles.button}
                activeOpacity={1}
                onPress={() => change('teléfono')}
              >
                <Image source={phone} style={styles.icon} />
                <Text style={styles.text}>Cambiar teléfono</Text>
              </CustomPressable> */}
              <CustomPressable
                style={styles.logOut}
                activeOpacity={1}
                onPress={logout}
              >
                <Text style={styles.text}>Cerrar sesión</Text>
              </CustomPressable>
            </View>
          </View>
        </CustomPressable>
      </Modal>
    </>
  )
}

export default ProfileSettings
