import { useState, useEffect, memo } from 'react'
import { View, Text, Image, ActivityIndicator } from 'react-native'
import styles from './SmallVincufyButtonStyles'
import bondIcon from '@/assets/People/vinculo.png'
import { TParticipantDetailModel } from '@/models'
import CustomPressable from '../CustomPressable/CustomPressable'

const SmallVincufyButton = memo(
  ({
    buttonType,
    participant,
    onClick,
  }: {
    buttonType: string
    participant: TParticipantDetailModel
    onClick: (v: TParticipantDetailModel) => void
  }) => {
    const [buttonInfo, setButtonInfo] = useState<{
      buttonText: string
      circleColor: string
      buttonColor: string
      isLoading: boolean
    }>({
      buttonText: 'Cargando',
      circleColor: '#bdbdbd',
      buttonColor: '#e0e0e0',
      isLoading: true,
    })

    useEffect(() => {
      switch (buttonType) {
        case 'Aceptar':
          setButtonInfo({
            buttonText: 'Aceptar',
            circleColor: '#661099',
            buttonColor: '#8811c8',
            isLoading: false,
          })
          break
        case 'Contactar':
          setButtonInfo({
            buttonText: 'Contactar',
            circleColor: '#388E3C',
            buttonColor: '#4CAF50',
            isLoading: false,
          })
          break
        case 'Cargando':
        default:
          setButtonInfo({
            buttonText: 'Cargando',
            circleColor: '#bdbdbd',
            buttonColor: '#e0e0e0',
            isLoading: true,
          })
      }
    }, [buttonType])

    const handleClick = () => {
      if (buttonType === 'Aceptar') {
        setButtonInfo((lv) => ({
          buttonText: 'Aceptar',
          circleColor: '#bdbdbd',
          buttonColor: '#e0e0e0',
          isLoading: true,
        }))
      }
      onClick?.(participant)
    }

    return (
      <CustomPressable
        key={`vincufy-btn-${participant.participantId}`}
        onPress={handleClick}
        disabled={buttonInfo.isLoading}
      >
        <View
          style={[
            styles.thermometerButton,
            { backgroundColor: buttonInfo.buttonColor },
          ]}
        >
          <Text style={styles.buttonText}>{buttonInfo.buttonText}</Text>
          <View
            style={[styles.circle, { backgroundColor: buttonInfo.circleColor }]}
          >
            {buttonInfo.isLoading ? (
              <ActivityIndicator size="large" color="#ffffff" />
            ) : (
              <img src={bondIcon} alt="Vínculo" style={styles.icon} />
              // <Image source={bondIcon} style={styles.icon} />
            )}
          </View>
        </View>
      </CustomPressable>
    )
  },
)

export default SmallVincufyButton
