import { StyleSheet } from 'react-native-web'
import { colors } from '@/theme'

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    backgroundColor: 'black',
    zIndex: 99999,
  },
  containerDenied: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingTop: 24,
    backgroundColor: 'black',
    zIndex: 99999,
  },
  image: {
    height: 180,
    width: 180,
    marginBottom: 24,
  },
  imageError: {
    height: 140,
    width: 140,
    marginBottom: 24,
  },
  imageNotiGranted: {
    height: 180,
    width: 180,
    marginBottom: 30,
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 12,
    lineHeight: 32,
    color: 'white',
  },
  description: {
    fontSize: 17,
    textAlign: 'center',
    marginHorizontal: 15,
    color: 'white',
    marginBottom: 36,
  },
  welcomeDescription: {
    fontSize: 17.5,
    textAlign: 'center',
    marginHorizontal: 15,
    color: 'white',
    marginBottom: 36,
    marginTop: 15,
    letterSpacing: 2,
  },
  allowBtn: {
    backgroundColor: colors.primary,
    paddingHorizontal: 30,
    paddingVertical: 12,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  allowBtnText: {
    marginRight: 15,
    fontSize: 15.8,
    color: 'white',
    letterSpacing: 2,
  },
  allowBtnIcon: {
    marginTop: 2,
    padding: 8,
    size: 20,
    maxHeight: 16,
    maxWidth: 16,
  },
  errorText: {
    marginTop: 10,
    fontStyle: 'italic',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#5A45FFFF',
  },
  instructionsTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  bckgImage: {
    padding: 15,
    borderRadius: 8,
  },
  instructionsStep: {
    flexDirection: 'row',
  },
  instructionsStepDecoration: {
    alignItems: 'center',
    width: 24,
    marginRight: 15,
  },
  instructionsStepDecorationCircle: {
    backgroundColor: colors.primary,
    borderRadius: '50%',
    width: 24,
    height: 24,
  },
  instructionsStepDecorationCircleNum: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
  instructionsStepDecorationLine: {
    backgroundColor: colors.primary,
    flex: 1,
    width: 4,
  },
  instructionsStepText: {
    fontSize: 17,
    paddingBottom: 25,
    color: 'white',
  },
})
