import { View, Text, TextInput } from 'react-native-web'
import { theme } from '@/theme'
import { CustomPressable, Password, SelectGender } from '@/components'
import styles from './AuthFormStyles'
import { useFullRegisterController } from './controllers/useFullRegisterController'
import { TLoggedOrigin } from '@/redux/reducers/auth.reducer'
import DatePickerCalendarRegister from '@/views/SecondarySections/Register/components/Inputs/DatePickerCalendarRegister/DatePickerCalendarRegister'

const FullRegisterForm = ({
  goToLogin,
  fromFunctionality,
}: {
  goToLogin: () => void
  fromFunctionality?: TLoggedOrigin
}) => {
  const {
    handleChange,
    handleSubmit,
    registerBtnDisabled,
    input,
    formErrors,
    errorRegisterMessage,
    isLoadingRegister,
  } = useFullRegisterController(fromFunctionality)

  return (
    <>
      <View style={styles.inputs}>
        <TextInput
          value={input.email}
          inputMode="email"
          textContentType="emailAddress"
          placeholder="Ingresá tu mail"
          placeholderTextColor="rgb(96, 96, 96)"
          style={theme.inputAuth}
          onChangeText={(e: string) => handleChange(e, 'email')}
          autoCapitalize="none"
          autoCorrect={false}
          autoComplete="off"
        />
        {!!formErrors.email && (
          <Text style={styles.error}>{formErrors.email}</Text>
        )}

        <Password
          value={input.password}
          onChange={(e) => handleChange(e, 'password')}
          hasError={!!formErrors.password}
          placeholder={'Crea tu contraseña'}
          autoComplete={'new-password'}
        />
        {!!formErrors.password && (
          <Text style={styles.error}>{formErrors.password}</Text>
        )}
        <Password
          value={input.confirmPassword}
          onChange={(e) => handleChange(e, 'confirmPassword')}
          hasError={!!formErrors.confirmPassword}
          placeholder={'Confirmá tu contraseña'}
          autoComplete={'new-password'}
        />
        {!!formErrors.confirmPassword && (
          <Text style={styles.error}>{formErrors.confirmPassword}</Text>
        )}
        <TextInput
          value={input.name}
          placeholder="Ingresá tu nombre"
          maxLength={15}
          placeholderTextColor="rgb(96, 96, 96)"
          style={theme.inputAuth}
          onChangeText={(e) => handleChange(e, 'name')}
          autoCorrect={false}
        />
        <TextInput
          value={input.lastName}
          placeholder="Ingresá tu apellido"
          placeholderTextColor="rgb(96, 96, 96)"
          style={theme.inputAuth}
          maxLength={15}
          onChangeText={(e) => handleChange(e, 'lastName')}
          autoCorrect={false}
        />
        <DatePickerCalendarRegister
          userData={input}
          setUserData={(v) => handleChange(v, 'dateOfBirth')}
        />
            {!!formErrors.dateOfBirth && (
          <Text style={styles.error}>{formErrors.dateOfBirth}</Text>
        )}
        <TextInput
          value={input.city}
          placeholder="Ciudad de residencia"
          placeholderTextColor="rgb(96, 96, 96)"
          style={theme.inputAuth}
          maxLength={20}
          onChangeText={(e) => handleChange(e, 'city')}
          autoCorrect={false}
        />
        <SelectGender
          gender={input.gender}
          setGender={(v) => handleChange(v, 'gender')}
          customStyles={{
            ...theme.inputAuth,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </View>

      <CustomPressable
        onPress={handleSubmit}
        style={[
          styles.submitBtn,
          registerBtnDisabled ? styles.submitBtnDisabled : null,
        ]}
        disabled={registerBtnDisabled}
        isLoading={isLoadingRegister}
      >
        <Text style={styles.buttonText}>Crear Usuario</Text>
      </CustomPressable>
      {errorRegisterMessage && (
        <Text style={styles.error}>{errorRegisterMessage}</Text>
      )}
      <CustomPressable onPress={goToLogin} style={styles.footer}>
        <Text style={styles.text}>¿Ya tenés una cuenta?</Text>
        <Text style={styles.span}>Iniciá sesión</Text>
      </CustomPressable>
    </>
  )
}

export default FullRegisterForm
