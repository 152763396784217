import { Platform, StyleSheet } from 'react-native-web'

export const getScreenLayoutStyles = (shouldHideTabBar = false) =>
  StyleSheet.create({
    screenLayout: {
      flex: 1,
      width: '100%',
      paddingBottom: shouldHideTabBar ? 0 : Platform.OS === 'ios' ? 80 : 55,
      backgroundColor: 'black',
    },
  })
