import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  scrollViewContent: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentInfoRows: {
    flexDirection: 'column',
    marginHorizontal: 15,
    flex: 1,
    gap: 15,
  },
  container: {
  },
  label: {
    color: 'white',
    fontSize: 17,
  },
  dateInfoContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 15,
    paddingLeft: 20,
    backgroundColor: 'rgb(32, 32, 32)',
    borderRadius: 8,
    width: '100%',
    paddingVertical: 20,
  },
  dateInfoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  descriptionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'rgb(32, 32, 32)',
    borderRadius: 8,
    minHeight: 65,
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 20,
    marginBottom: 30,
  },
  eventInfoText: {
    color: 'white',
    fontSize: 15,
  },
  descriptionText: {
    color: 'white',
    fontWeight: '200',
    lineHeight: 25,
    fontSize: 17,
  },
  icon: {
    width: 17,
    height: 17,
  },
})

export default styles
