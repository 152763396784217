import { useEffect, useState } from 'react'
import { VirtualRoomNavigatorParamList } from 'routes/Navigators'
import {
  getCurrentRoute,
  getUrlParams,
  goBackTo,
  isCurrentPage,
  PAGES_NAV,
  useAppRouteParams,
} from 'routes/utils'

export const useParticipantDetailController = () => {
  const navParams = useAppRouteParams<
    VirtualRoomNavigatorParamList,
    'EventVirtualRoomParticipant'
  >()

  const [origin, setOrigin] = useState<null | keyof typeof PAGES_NAV>(null)
  const [currentPage, setCurrentPage] = useState<null | keyof typeof PAGES_NAV>(
    null,
  )
  const [routeParams, setRouteParams] = useState<
    Partial<VirtualRoomNavigatorParamList['EventVirtualRoomParticipant']>
  >({})
  const params = {
    eventId: navParams?.eventId || routeParams?.eventId || '',
    participantId: navParams?.participantId || routeParams?.participantId || '',
  }
  const getGoBackFunc = (): null | (() => boolean) => {
    if (origin === 'EventVirtualRoom') {
      if (!params?.eventId) return null
      return () =>
        goBackTo('EventVirtualRoom', {
          eventId: params.eventId,
        })
    } else if (origin === 'EventBonds') {
      if (!params?.eventId) return null
      return () => goBackTo('EventBonds', { eventId: params.eventId })
    } else if (origin === 'AdminPanel') {
      if (!params?.eventId) return null
      return () => goBackTo('AdminPanel', { eventId: params.eventId })
    } else if (origin === 'TicketControl') {
      if (!params?.eventId) return null
      return () =>
        goBackTo('TicketControl', {
          eventId: params.eventId,
        })
    } else if (origin === 'MyBonds') {
      return () => goBackTo('MyBonds', {})
    }
    return null
  }

  const goBackFunc: null | (() => boolean) = getGoBackFunc()

  useEffect(() => {
    const currentRoute = getCurrentRoute()

    let newOrigin: keyof typeof PAGES_NAV = PAGES_NAV.MyBonds.name
    let newCurrentPage: keyof typeof PAGES_NAV = PAGES_NAV.BondParticipant.name

    if (isCurrentPage('EventVirtualRoomParticipant', currentRoute)) {
      newOrigin = PAGES_NAV.EventVirtualRoom.name
      newCurrentPage = PAGES_NAV.EventVirtualRoomParticipant.name
    } else if (isCurrentPage('EventBondsParticipant', currentRoute)) {
      newOrigin = PAGES_NAV.EventBonds.name
      newCurrentPage = PAGES_NAV.EventBondsParticipant.name
    } else if (isCurrentPage('AdminPanelParticipant', currentRoute)) {
      newOrigin = PAGES_NAV.AdminPanel.name
      newCurrentPage = PAGES_NAV.AdminPanelParticipant.name
    } else if (isCurrentPage('TicketControlParticipant', currentRoute)) {
      newOrigin = PAGES_NAV.TicketControl.name
      newCurrentPage = PAGES_NAV.TicketControlParticipant.name
    }

    setOrigin(newOrigin)
    setCurrentPage(newCurrentPage)

    const [urlParamEventId, urlParamParticipantId] = getUrlParams(
      newCurrentPage,
      currentRoute,
    )

    setRouteParams({
      eventId: urlParamEventId,
      participantId: urlParamParticipantId,
    })
  }, [])

  return {
    params,
    goBackFunc,
    fromAdminPanel: origin ? origin === PAGES_NAV.AdminPanel.name : false,
  }
}
