import { View, TextInput, Text } from 'react-native'
import styles from './UserDataInputsStyles'
import SelectCity from '../SelectCity/SelectCity'
import SelectGender from '../SelectGender/SelectGender'
import SelectRelationStatus from '../SelectRelationStatus/SelectRelationStatus'
import { CustomPressable, DatePickerCalendar } from '@/components'
import { theme } from '@/theme'
import { TEditableUserInfo } from '@/views/MainSections/MyProfile/controllers/MyProfileController'
import { navigateTo } from 'routes/utils'
import { formatNames } from '@/utils'

const UserDataInputs = ({
  editableUserInfo,
  updateEditableUserInfo,
}: {
  editableUserInfo: TEditableUserInfo
  updateEditableUserInfo: <K extends keyof TEditableUserInfo>(
    field: K,
    value: TEditableUserInfo[K],
  ) => void
}) => {
  // const [showDatePicker, setShowDatePicker] = useState(false)

  const goToSetContactMethodsScreen = () => {
    navigateTo('ContactMethodScreen', {
      origin: 'MyProfile',
    })
  }

  return (
    <View style={styles.inputGroup}>
      <View style={styles.inputSmallGroup}>
        <TextInput
          style={[theme.input, styles.inputSmall]}
          value={editableUserInfo.name}
          onChangeText={(text) =>
            updateEditableUserInfo('name', formatNames(text))
          }
          placeholder="Nombre"
          placeholderTextColor="rgb(96, 96, 96)"
          maxLength={11}
        />
        <TextInput
          style={[theme.input, styles.inputSmall]}
          value={editableUserInfo.lastName}
          onChangeText={(text) =>
            updateEditableUserInfo('lastName', formatNames(text))
          }
          placeholder="Apellido"
          placeholderTextColor="rgb(96, 96, 96)"
          maxLength={20}
        />
      </View>
      <TextInput
        style={[theme.input, styles.inputDescription]}
        value={editableUserInfo.description || ''}
        onChangeText={(text) => updateEditableUserInfo('description', text)}
        placeholder="Descripción"
        placeholderTextColor="rgb(96, 96, 96)"
        multiline={true}
        // rows={5}
        maxLength={230}
      />
      <CustomPressable
        style={theme.input}
        onPress={goToSetContactMethodsScreen}
      >
        <Text style={styles.methodContactBtnText}>Métodos de contacto</Text>
      </CustomPressable>
      {/* <DatePickerCalendar
        currentDate={editableUserInfo.birthDate}
        setCurrentDate={(v) => updateEditableUserInfo('birthDate', v)}
      /> */}
      <SelectCity
        city={editableUserInfo.originLocation}
        setCity={(v) => updateEditableUserInfo('originLocation', v)}
      />
      <SelectGender
        gender={editableUserInfo.gender}
        setGender={(v) => updateEditableUserInfo('gender', v)}
        customStyles={{
          ...theme.input,
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      />
      <SelectRelationStatus
        sentimentalSituation={editableUserInfo.sentimentalSituation}
        setSentimentalSituation={(v) =>
          updateEditableUserInfo('sentimentalSituation', v)
        }
      />
    </View>
  )
}

export default UserDataInputs
