import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputGroup: {
    width: '100%',
    gap: '11px',
    padding: '20px',
    paddingTop: '0px',
  },
  inputSmallGroup: {
    flexDirection: 'row',
    gap: 11,
  },
  inputSmall: {
    paddingHorizontal: 10,
    flex: 2,
  },
  inputDescription: {
    height: 110,
    width: '100%',
    paddingHorizontal: 10,
    paddingVertical: 10,
    verticalAlign: 'top',
  },
  buttonInput: {
    height: 40,
    minHeight: 40,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    flex: 2,
    color: 'white',
    fontWeight: '300',
    justifyContent: 'center',
  },
  methodContactBtnText: {
    marginHorizontal: '10px',
    fontWeight: '600',
  },
})
