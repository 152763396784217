export type ParticipantStatus =
  | 'PENDING'
  | 'REJECTED'
  | 'ACCEPTED'
  | 'WITH_TICKET'
export const ParticipantStatus = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
  WITH_TICKET: 'WITH_TICKET',
} as const

export type TParticipantModel = {
  id: string
  userId: string
  name: string
  lastName: string
  userProfileId: string
  profilePicture: string
  eventId: string
  ticketId: string
  status: ParticipantStatus
}
