import { combineReducers } from 'redux'
import userReducer from './user.reducer'
import eventReducer from './event.reducer'
import authReducer from './auth.reducer'
import bondReducer from './bond.reducer'

const rootReducer = combineReducers({
  user: userReducer,
  event: eventReducer,
  auth: authReducer,
  bond: bondReducer,
})

export default rootReducer
