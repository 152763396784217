import { useState } from 'react'
import { View, Image, Text } from 'react-native'
import { styles, googleStyles } from './SelectCityStyles'
import lupa from '@/assets/Profile/lupa.png'
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete'
import { GOOGLE_API_KEY } from '@env' // Importa la variable de entorno
import { CustomModal, CustomPressable } from '@/components'
import { theme } from '@/theme'
import envs from '@/config/envs'

const SelectCity = ({ city, setCity }) => {
  const [modalInputValue, setModalInputValue] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onPressInput = () => {
    setIsModalOpen(true)
  }

  const onPressOption = (optionSelected) => {
    setCity(optionSelected.description)
    setModalInputValue(optionSelected.description)
    setIsModalOpen(false)
  }

  return (
    <CustomPressable
      style={theme.input}
      onPress={onPressInput}
      activeOpacity={0.8}
    >
      <Text style={styles.placeholderText}>
        {city || '¿En qué ciudad estás?'}
      </Text>
      <View style={styles.searchIconContainer}>
        <Image
          source={lupa}
          style={styles.searchIcon}
          resizeMode="contain"
          tintColor="black"
        />
      </View>
      <CustomModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        animationType="slide"
        overlayStyle={styles.modalOverlay}
      >
        <View style={styles.searchbar}>
          <GooglePlacesAutocomplete
            placeholder="¿En qué ciudad estás?"
            query={{
              key: GOOGLE_API_KEY,
              language: 'es',
              types: '(cities)',
              region: 'AR',
              components: 'country:AR',
            }}
            onPress={onPressOption}
            textInputProps={{
              placeholderTextColor: 'white',
              value: modalInputValue,
              onChange: (e) => setModalInputValue(e?.target?.value || ''),
            }}
            renderRightButton={() => (
              <View style={styles.searchIconContainer}>
                <Image
                  source={lupa}
                  style={styles.searchIconOpen}
                  resizeMode="contain"
                  tintColor="white"
                />
              </View>
            )}
            styles={googleStyles}
            requestUrl={{
              useOnPlatform: 'all',
              url: `${envs.URL_API_VINCUFY}/googleProxy`,
            }}
          />
        </View>
      </CustomModal>
    </CustomPressable>
  )
}

export default SelectCity
