export const colors = {
  primary: '#8810C7',
  primaryDarker: '#661099',
  secondary: '#FFB408',
  secondaryDarker: '#FF9F00',
  green: '#4CB050',
  greenDarker: '#398E3D',
  inputBg: '#bfbfbf',
  gray: '#807884',
}
