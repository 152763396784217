import { useEffect, useState } from 'react'
import styles from './CounterDownStyles'
import { View, Text } from 'react-native'

const CounterDown = ({
  remainingSecondsToStart,
}: {
  remainingSecondsToStart: number
}) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  useEffect(() => {
    if (remainingSecondsToStart <= 0) {
      // Si el tiempo restante es menor o igual a cero, detener el contador
      setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 })
      return // Salir de la función
    }

    const days = Math.floor(remainingSecondsToStart / 86400)
    const hours = Math.floor((remainingSecondsToStart % 86400) / 3600)
    const minutes = Math.floor((remainingSecondsToStart % 3600) / 60)
    const seconds = remainingSecondsToStart % 60
    setTimeRemaining({ days, hours, minutes, seconds })
  }, [remainingSecondsToStart])

  // Función para formatear los números a dos dígitos
  const formatNumber = (num: number): string => String(num).padStart(2, '0')

  return (
      <View style={styles.box}>
        <View style={styles.timeContainer}>
          <Text style={styles.time}>
            {formatNumber(timeRemaining.days)}{' '}
            <Text style={styles.label}>Días</Text>
          </Text>
          <Text style={styles.time}>
            {formatNumber(timeRemaining.hours)}{' '}
            <Text style={styles.label}>Horas</Text>
          </Text>
          <Text style={styles.time}>
            {formatNumber(timeRemaining.minutes)}{' '}
            <Text style={styles.label}>Minutos</Text>
          </Text>
          <Text style={styles.time}>
            {formatNumber(timeRemaining.seconds)}{' '}
            <Text style={styles.label}>Segundos</Text>
          </Text>
        </View>
      </View>
  )
}

export default CounterDown
