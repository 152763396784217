import { navigationLog } from '@/utils'
import { PAGES_NAV, TAllPageNavigatorsParams } from './pages-nav'

export const getCurrentRoute = () =>
  window.location.hash
    ? window.location.hash.replace(/^#/, '').replace(/\/$/, '')
    : '/'

export const updateCurrentRoute = <
  PageName extends keyof TAllPageNavigatorsParams,
>(
  pageName: PageName,
  params: TAllPageNavigatorsParams[PageName],
) => window.history.replaceState(null, '', PAGES_NAV[pageName].hashUrl(params))

export const isCurrentPage = (
  pageName: keyof TAllPageNavigatorsParams,
  currentRoute: string = getCurrentRoute(),
) => PAGES_NAV[pageName].regex.test(currentRoute)

export const getUrlParams = (
  pageName: keyof typeof PAGES_NAV,
  currentRoute: string = getCurrentRoute(),
) => {
  const match = currentRoute.match(PAGES_NAV[pageName].regex)
  const params = match ? match.slice(1) : []
  navigationLog('GET_URL_PARAMS', {
    pageName,
    currentRoute,
    match,
    params,
  })
  return params
}
