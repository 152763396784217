import { StyleSheet, View } from 'react-native'
import { Platform } from 'react-native'
import myProfile from '@/assets/Navbar/usuario.png'
import myEvent from '@/assets/Navbar/evento.png'
import myRelationships from '@/assets/Navbar/url.png'
import { theme } from '@/theme'

const styles = StyleSheet.create({
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  tabIcon: {
    marginTop: 10,
    marginBottom: 3,
    height: 20,
    width: 20,
  },
})

const screenOptions = (shouldHideTabBar = false) => ({
  tabBarLabelStyle: {
    fontSize: 12,
    color: '#ffffff',
    marginBottom: 10,
  },
  tabBarStyle: {
    display: shouldHideTabBar ? 'none' : 'flex',
    backgroundColor: 'transparent',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: Platform.OS === 'ios' ? 85 : 65,
    elevation: 0,
    borderTopWidth: 0,
  } as const,
  headerShown: false,
  tabBarHideOnKeyboard: true,
  tabBarBackground: () => (
    <View style={[styles.backgroundImage, theme.bgGradientRight]} />
  ),
})

export { styles, screenOptions, myEvent, myProfile, myRelationships }
