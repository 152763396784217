import { View, Text, TextInput } from 'react-native-web'
import { theme } from '@/theme'
import { CustomPressable, Password } from '@/components'
import styles from './AuthFormStyles'
import { useLoginController } from './controllers/useLoginController'
import { TLoggedOrigin } from '@/redux/reducers/auth.reducer'

const LoginForm = ({
  goToRegister,
  fromFunctionality,
}: {
  goToRegister: () => void
  fromFunctionality?: TLoggedOrigin
}) => {
  const {
    handleChange,
    handleSubmit,
    loginBtnDisabled,
    input,
    formErrors,
    errorLoginMessage,
    isLoadingLogin,
  } = useLoginController(fromFunctionality)

  return (
    <>
      <View style={styles.inputs}>
        <TextInput
          value={input.email}
          inputMode="email"
          textContentType="emailAddress"
          placeholder="Ingresá tu mail"
          placeholderTextColor="rgb(96, 96, 96)"
          style={theme.inputAuth}
          onChangeText={(e: string) => handleChange(e, 'email')}
          autoCapitalize="none"
          autoCorrect={false}
        />
        {!!formErrors.email && (
          <Text style={styles.error}>{formErrors.email}</Text>
        )}

        <Password
          value={input.password}
          onChange={(e) => handleChange(e, 'password')}
          hasError={!!formErrors.password}
          placeholder={'Ingresá tu contraseña'}
          autoComplete="password"
        />
        {!!formErrors.password && (
          <Text style={styles.error}>{formErrors.password}</Text>
        )}
      </View>

      <CustomPressable
        onPress={handleSubmit}
        style={styles.submitBtn}
        disabled={loginBtnDisabled}
        isLoading={isLoadingLogin}
      >
        <Text style={styles.buttonText}>Iniciar sesión</Text>
      </CustomPressable>
      {errorLoginMessage && (
        <Text style={styles.error}>{errorLoginMessage}</Text>
      )}
      <CustomPressable onPress={goToRegister} style={styles.footer}>
        <Text style={styles.text}>¿Todavía no creaste tu perfil? </Text>
        <Text style={styles.span}>Registrate</Text>
      </CustomPressable>
    </>
  )
}

export default LoginForm
