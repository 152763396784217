import { sortEvents } from '@/utils'
import { useMyEventsModel } from '../models/myEventsModel'
import { useEffect, useState } from 'react'

export const useMyEventsController = () => {
  const {
    userEvents,
    fetchEventsData,
    isLoading,
    setIsLoading,
    backError,
  } = useMyEventsModel()

  useEffect(() => {
    setIsLoading(true)
    if (!userEvents) {
      fetchEventsData()
    } else {
      // sortEvents(userEvents)
      setIsLoading(false)
    }
  }, [userEvents])

  return {
    userEvents,
    isLoading,
    fetchUserEvents: fetchEventsData,
    backError
  }
}
