import { fetchStatus } from '@/models/fetch.model'
import {
  GET_BONDS,
  GET_BONDS_LOADING,
  GET_BONDS_ERROR,
  GET_EVENT_BONDS,
  GET_EVENT_BONDS_LOADING,
  GET_EVENT_BONDS_ERROR,
  GET_BOND,
  GET_BOND_LOADING,
  GET_BOND_ERROR,
  RESPOND_BOND_REQUEST,
  RESPOND_BOND_REQUEST_ERROR,
  RESPOND_BOND_REQUEST_LOADING,
  SEND_BOND_REQUEST,
  SEND_BOND_REQUEST_ERROR,
  SEND_BOND_REQUEST_LOADING,
  BOND_LOGOUT,
} from '@/redux/constants'

export type TBondState = {
  bondsStatus: fetchStatus
  bondsError: null | string

  eventBondsStatus: fetchStatus
  eventBondsError: null | string

  bondStatus: fetchStatus
  bondError: null | string

  sendBondRequestStatus: fetchStatus
  sendBondRequestError: null | string

  respondBondRequestStatus: fetchStatus
  respondBondRequestError: null | string
}

const initialState = {
  bondsStatus: fetchStatus.TO_FETCH,
  bondsError: null,

  eventBondsStatus: fetchStatus.TO_FETCH,
  eventBondsError: null,

  bondStatus: fetchStatus.TO_FETCH,
  bondError: null,

  sendBondRequestStatus: fetchStatus.TO_FETCH,
  sendBondRequestError: null,

  respondBondRequestStatus: fetchStatus.TO_FETCH,
  respondBondRequestError: null,
}

const bondReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BONDS_LOADING:
      return {
        ...state,
        bondsStatus: fetchStatus.LOADING,
        bondsError: null,
      }
    case GET_BONDS:
      return {
        ...state,
        bondsStatus: fetchStatus.SUCCESS,
        bondsError: null,
      }
    case GET_BONDS_ERROR:
      return {
        ...state,
        bondsStatus: fetchStatus.ERROR,
        bondsError: action.payload.error as string,
      }

    case GET_EVENT_BONDS_LOADING:
      return {
        ...state,
        eventBondsStatus: fetchStatus.LOADING,
        eventBondsError: null,
      }
    case GET_EVENT_BONDS:
      return {
        ...state,
        eventBondsStatus: fetchStatus.SUCCESS,
        eventBondsError: null,
      }
    case GET_EVENT_BONDS_ERROR:
      return {
        ...state,
        eventBondsStatus: fetchStatus.ERROR,
        eventBondsError: action.payload.error as string,
      }

    case GET_BOND_LOADING:
      return {
        ...state,
        bondStatus: fetchStatus.LOADING,
        bondError: null,
      }
    case GET_BOND:
      return {
        ...state,
        bondStatus: fetchStatus.SUCCESS,
        bondError: null,
      }
    case GET_BOND_ERROR:
      return {
        ...state,
        bondStatus: fetchStatus.ERROR,
        bondError: action.payload.error as string,
      }

    case SEND_BOND_REQUEST_LOADING:
      return {
        ...state,
        sendBondRequestStatus: fetchStatus.LOADING,
        sendBondRequestError: null,
      }
    case SEND_BOND_REQUEST:
      return {
        ...state,
        sendBondRequestStatus: fetchStatus.SUCCESS,
        sendBondRequestError: null,
      }
    case SEND_BOND_REQUEST_ERROR:
      return {
        ...state,
        sendBondRequestStatus: fetchStatus.ERROR,
        sendBondRequestError: action.payload.error as string,
      }

    case RESPOND_BOND_REQUEST_LOADING:
      return {
        ...state,
        respondBondRequestStatus: fetchStatus.LOADING,
        respondBondRequestError: null,
      }
    case RESPOND_BOND_REQUEST:
      return {
        ...state,
        respondBondRequestStatus: fetchStatus.SUCCESS,
        respondBondRequestError: null,
      }
    case RESPOND_BOND_REQUEST_ERROR:
      return {
        ...state,
        respondBondRequestStatus: fetchStatus.ERROR,
        respondBondRequestError: action.payload.error as string,
      }
    case BOND_LOGOUT:
      return initialState
    default:
      return state
  }
}

export default bondReducer
