// export type RootStackParamList = {
//   Home: NavigatorScreenParams<HomeTabParamList>
//   PostDetails: { id: string }
//   NotFound: undefined
// }

// export type RootStackScreenProps<T extends keyof RootStackParamList> =
//   StackScreenProps<RootStackParamList, T>

// export type HomeTabParamList = {
//   Popular: undefined
//   Latest: undefined
// }

// export type HomeTabScreenProps<T extends keyof HomeTabParamList> =
//   CompositeScreenProps<
//     BottomTabScreenProps<HomeTabParamList, T>,
//     RootStackScreenProps<keyof RootStackParamList>
//   >

// declare global {
//   namespace ReactNavigation {
//     interface RootParamList extends RootStackParamList {}
//   }
// }

import {
  ParamListBase,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs'

export const useAppNavigation = <T extends ParamListBase>() => {
  return useNavigation<
    NativeStackNavigationProp<T> | BottomTabNavigationProp<T>
  >()
}

export const useAppRoute = <T extends ParamListBase>() => {
  return useRoute<RouteProp<T>>()
}

export const useAppRouteParams = <
  T extends ParamListBase,
  P extends keyof T,
>() => {
  const route = useRoute<RouteProp<T, P>>()
  return route.params
}
