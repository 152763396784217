import { StyleSheet } from 'react-native-web'

export default StyleSheet.create({
  logoVincufy: {
    marginTop: '5%',
    height: 60,
    width: '100%', // Asegúrate de definir el ancho
  },
  inputs: {
    // marginBottom: '4%',
    width: '100%',
    alignItems: 'center',
  },
  error: {
    color: 'rgb(215, 64, 64)',
    marginTop: 8,
    marginBottom: -10,
  },
  submitBtn: {
    width: '100%',
    maxWidth: 343,
    height: 45,
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    fontSize: 15,
    marginTop: 20,
  },
  submitBtnDisabled:{
    backgroundColor: 'rgb(20, 20, 20)',
  },
  buttonText: {
    color: 'white',
  },
  span: {
    color: 'white',
    fontWeight: '500',
    fontSize: 16,
    textDecorationLine: 'underline',
  },
  footer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(197, 197, 197, 0.116)',
    minHeight: 35,
    marginTop: 30,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 8,
    fontSize: 18,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 5,
  },
  text: {
    fontSize: 16,
    color: 'white',
  },
} as const)
