import { useAppDispatch } from '@/redux/store'
import { validateEmail, validatePassword } from '@/models/auth.model'
import { login } from '@/redux/thunks'
import { useState } from 'react'

export type TLoginForm = {
  email: string
  password: string
}

export type TLoginFormErrors = {
  email?: string
  password?: string
}

export const useLoginController = () => {
  const dispatch = useAppDispatch()
  const [input, setInput] = useState<TLoginForm>({ email: '', password: '' })
  const [formErrors, setFormErrors] = useState<TLoginFormErrors>({})
  const [errorLoginMessage, setErrorLoginMessage] = useState('')
  const [isLoadingLogin, setIsLoadingLogin] = useState(false)

  const loginBtnDisabled =
    !!formErrors.email ||
    !!formErrors.password ||
    input.email.length === 0 ||
    input.password.length === 0 ||
    isLoadingLogin

  const loginUser = async (input: TLoginForm) => {
    await dispatch(
      login({ email: input.email, password: input.password }),
    ).unwrap()
  }

  const validateLoginForm = (input: TLoginForm): TLoginFormErrors => {
    const errors: TLoginFormErrors = {}
    errors.email = validateEmail(input.email) || undefined
    errors.password = validatePassword(input.password) || undefined
    return errors
  }

  const handleChange = (newValue: string, name: 'email' | 'password') => {
    if (name === 'email') {
      newValue = newValue.toLocaleLowerCase()
    }
    const newInput = { ...input, [name]: newValue }
    setInput(newInput)
    setFormErrors(validateLoginForm(newInput))
  }

  const handleSubmit = async () => {
    try {
      setIsLoadingLogin(true)
      setErrorLoginMessage('')
      await loginUser(input)
    } catch (err) {
      console.warn('Login Error:', err, JSON.stringify(err))
      if ((err as any)?.statusCode === 400) {
        setErrorLoginMessage('Usuario o contraseña incorrectos')
      } else {
        const errorMsg = err instanceof Error ? err?.message : String(err)
        setErrorLoginMessage(errorMsg || 'Error')
      }
    } finally {
      setIsLoadingLogin(false)
    }
  }

  return {
    handleChange,
    handleSubmit,
    loginBtnDisabled,
    input,
    formErrors,
    errorLoginMessage,
    isLoadingLogin,
  }
}
