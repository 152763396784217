import { useState, useEffect, useRef } from 'react'
import { View, Text, Animated, ImageBackground } from 'react-native'
import styles from './ButtonListStyles'
import CustomPressable from '../CustomPressable/CustomPressable'
import { theme } from '@/theme'

const ButtonList = ({ text, options, isOpenCollapse, setIsOpenCollapse }) => {
  const [height] = useState(new Animated.Value(0)) // Valor inicial de la altura es 0
  const containerRef = useRef(null) // Ref para el contenedor de la lista

  useEffect(() => {
    const calculatedHeight = Math.max(40, options.length * 40)
    Animated.timing(height, {
      toValue: isOpenCollapse ? calculatedHeight : 0,
      duration: 300,
      useNativeDriver: false,
    }).start()
  }, [isOpenCollapse, options, height])

  const handlePressOutside = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setIsOpenCollapse(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handlePressOutside)
    return () => {
      document.removeEventListener('click', handlePressOutside)
    }
  }, [])

  return (
    <CustomPressable withFeedback={false} onPress={handlePressOutside}>
      <View style={styles.container} ref={containerRef}>
        <View style={[styles.header, theme.bgGradientCenter]}>
          <CustomPressable onPress={() => setIsOpenCollapse(!isOpenCollapse)}>
            <Text style={styles.headerText}>{text}</Text>
          </CustomPressable>
        </View>
        <Animated.View style={[styles.content, { height }]}>
          <View style={[styles.contentBackground, theme.bgGradientCenter]}>
            {options.map((option, index) => (
              <CustomPressable
                key={index}
                style={styles.option}
                onPress={option.action}
              >
                <Text style={styles.optionText}>{option.text}</Text>
              </CustomPressable>
            ))}
          </View>
        </Animated.View>
      </View>
    </CustomPressable>
  )
}

export default ButtonList
