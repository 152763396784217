import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/store'
// import { Vibration } from 'react-native'
import { sendBondRequestAction, respondBondRequestAction } from '@/redux/thunks'
import { fetchStatus, TParticipantDetailModel } from '@/models'
import { BondStatus } from '@/models/core/bond.model'

export const BondBtnActions = {
  REQUEST_BOND: 'REQUEST_BOND',
  ACCEPT_BOND: 'ACCEPT_BOND',
  PENDING: 'PENDING',
  CONTACT: 'CONTACT',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
} as const

export const useBondButtonController = ({
  participantId,
}: {
  participantId: TParticipantDetailModel['participantId']
}) => {
  const dispatch = useAppDispatch()
  const participantContactMethods = useAppSelector(
    (state) => state.event.participants[participantId]?.contactMethods,
  )
  const bond = useAppSelector(
    (state) => state.event.participants[participantId]?.bond,
  )
  const error = useAppSelector(
    (state) =>
      state.bond.respondBondRequestError || state.bond.sendBondRequestError,
  )
  const loading = useAppSelector(
    (state) =>
      state.bond.respondBondRequestStatus === fetchStatus.LOADING ||
      state.bond.sendBondRequestStatus === fetchStatus.LOADING,
  )

  const [isOpenContactModal, setIsOpenContactModal] = useState(false)
  const [isLoadingAction, setIsLoadingAction] = useState(false)

  const getBondBtnAction = () => {
    if (loading || isLoadingAction) return BondBtnActions.LOADING
    if (error) return BondBtnActions.ERROR

    if (!bond?.status) return BondBtnActions.REQUEST_BOND
    if (bond.status === BondStatus.ACCEPTED) return BondBtnActions.CONTACT
    if (bond.status === BondStatus.REJECTED) return BondBtnActions.PENDING
    if (bond.status === BondStatus.PENDING && bond.currentUserIsRequester)
      return BondBtnActions.PENDING
    if (bond.status === BondStatus.PENDING && !bond.currentUserIsRequester)
      return BondBtnActions.ACCEPT_BOND

    return BondBtnActions.ERROR
  }
  const bondBtnAction = getBondBtnAction()

  const sendBondRequest = async () => {
    setIsLoadingAction(true)
    await dispatch(
      sendBondRequestAction({ receiverId: participantId }),
    ).unwrap()
    // Vibration.vibrate()
    setIsLoadingAction(false)
  }

  const acceptBondRequest = async () => {
    if (!bond?.id) return
    setIsLoadingAction(true)
    await dispatch(
      respondBondRequestAction({
        bondId: bond.id,
        status: BondStatus.ACCEPTED,
      }),
    ).unwrap()
    // Vibration.vibrate()
    setIsLoadingAction(false)
  }

  // const rejectBondRequest = () => {
  //   if (!bond?.id) return
  //   respondBondRequestAction({ bondId: bond.id, status: BondStatus.REJECTED })
  //   Vibration.vibrate()
  // }

  return {
    bondBtnAction,
    sendBondRequest,
    acceptBondRequest,
    isOpenContactModal,
    setIsOpenContactModal,
    participantContactMethods,
  }
}
