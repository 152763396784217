import { StyleSheet, Dimensions } from 'react-native'

const { width } = Dimensions.get('window')
const isLargeScreen = width >= 768

export default StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    width: isLargeScreen ? width * 0.3 : width * 0.9,
    padding: 20,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
  },
  backgroundImage: {
    borderRadius: 10,
  },
  text: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
    lineHeight: 24,
    paddingHorizontal: 5,
    paddingTop: 15,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: 15,
  },
  btn: {
    backgroundColor: '#9459CE',
    paddingVertical: 10,
    borderRadius: 5,
    width: 110,
    alignItems: 'center',
  },
  btnCancel: {
    backgroundColor: 'black',
    paddingVertical: 10,
    borderRadius: 5,
    width: 110,
    alignItems: 'center',
  },
  btnText: {
    color: 'white',
    fontSize: 14,
  },
})
