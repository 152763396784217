import { Alert } from 'react-native'
import { isDesktop } from './device.util'

export const customAlert = (title: string, message?: string) => {
  if (isDesktop()) {
    alert(title)
  } else {
    Alert.alert(title, message || '')
  }
}
