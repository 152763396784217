import { useRef, useState } from 'react'

export const useRefreshController = ({ isLoadingFetch }) => {
  const refreshInterval = 10000
  const refreshLoadingMinTime = 2000

  const lastRefreshTime = useRef<number | null>(null)
  const [isLoadingRefreshMinTime, setIsLoadingRefreshMinTime] = useState(false)
  const isLoadingRefresh = isLoadingFetch || isLoadingRefreshMinTime

  const refreshController = (refreshFunction: () => void) => {
    // Only execute when nothing is loading
    if (isLoadingRefresh) return false

    // Show always Loader for {refreshLoadingMinTime}
    setIsLoadingRefreshMinTime(true)
    setTimeout(() => {
      setIsLoadingRefreshMinTime(false)
    }, refreshLoadingMinTime)

    // Check if really make a fetch
    const now = new Date().getTime()
    if (
      !lastRefreshTime.current ||
      now - lastRefreshTime.current >= refreshInterval
    ) {
      lastRefreshTime.current = now
      return refreshFunction()
    }
  }

  return [refreshController, isLoadingRefresh]
}
