import { memo } from 'react'
import { View, Text, Image, ActivityIndicator } from 'react-native-web'
import styles from '../AllowNotificationsStyles'
import logoVincufy from '@/assets/AllowNotifications/hablando.png'
import arrowrightIcon from '@/assets/AllowNotifications/notificacion.png'
import { CustomPressable } from '@/components'

const AskPermissionButton = ({ onAskPermission, isLoading, error }) => (
  <View style={styles.container}>
    <Image source={logoVincufy} style={styles.image} />
    <Text style={styles.title}>Notificaciones</Text>
    <Text style={styles.description}>
      Te vamos a avisar cuando recibas solicitudes de vinculación, mensajes e invitaciones a eventos exclusivos !
    </Text>
    <CustomPressable
      onPress={onAskPermission}
      disabled={isLoading}
      style={({ pressed }) => [styles.allowBtn, pressed && { opacity: 0.7 }]}
    >
      <Text style={styles.allowBtnText}>Permitir</Text>
      {isLoading ? (
        <ActivityIndicator size="small" color="#ffffff" />
      ) : (
        <Image
          source={arrowrightIcon}
          style={styles.allowBtnIcon}
          tintColor="white"
        />
      )}
    </CustomPressable>
    {error && <Text style={styles.errorText}>{error}</Text>}
  </View>
)

export default memo(AskPermissionButton)
