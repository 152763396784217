import { ISODateString } from '../utils.model'
import { TLocationModel } from './location.model'

export type GenderType = 'MALE' | 'FEMALE' | 'OTHER'
export const GenderType = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
} as const

export type SentimentalSituationType = 'SINGLE' | 'OPEN' | 'COUPLE' | 'MARRIED'
export const SentimentalSituationType = {
  SINGLE: 'SINGLE',
  OPEN: 'OPEN',
  COUPLE: 'COUPLE',
  MARRIED: 'MARRIED',
} as const

export type TUserModel = {
  userId: string
  name: string
  lastName: string
  birthDate: ISODateString
  gender: GenderType
  originLocation: string
  location: TLocationModel | null
  sentimentalSituation: SentimentalSituationType | null
  phone: string | null
  instagramToken: string | null
  notificationToken: string | null
  age: number
}
