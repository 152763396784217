//Response para la pantalla de Admin Panel
import defaultEventPic from '@/assets/MyEvents/defaultPicEvent.png'
import { EventType, EventVisualizationType, ParticipantStatus } from '@/models'
import { TAdminEventInfoModel } from '@/models/admin-event-info.model'
import { participantsMock } from '../VirtualRoom/participantsMock'

export const adminPanelMock: TAdminEventInfoModel = {
  eventProducers: [
    {
      id: '1', //Tabla EventProducer
      name: 'Sugus Producciones', //Tabla EventProducer
    },
  ],

  eventId: '820dbbf1-314a-4130-a249-a1bf3439eacc',
  eventName: 'Fiesta de Sugus',
  flyer: defaultEventPic,
  type: EventType.EVENT,
  visualization: EventVisualizationType.PRIVATE,
  startDate: '2024-09-26T21:00:00.000',
  endDate: '2024-09-27T05:00:00.000',
  ticketsLimit: 100,
  userTicketsLimit: 1,

  virtualRoomStartAt: '2024-09-26T20:00:00.000', //Tabla virtual room
  virtualRoomEndAt: '2024-09-27T21:00:00.000', //Tabla virtual room

  eventTicketTypes: [
    {
      id: '1',
      ticketName: 'Ingreso Gratuito',
      description: 'Ten acceso a la mejor fiesta del año y su previa virtual.',
      price: '0',
      hasTicketsRequest: true,
      ticketsGiven: 3,
      ticketsLimit: 100,
      userTicketsLimit: 1,
    },
  ],

  ticketTypesParticipants: {
    '1': {
      [ParticipantStatus.PENDING]: [
        participantsMock[0]?.participantId,
        participantsMock[1]?.participantId,
      ],
      [ParticipantStatus.ACCEPTED]: [
        participantsMock[2]?.participantId,
        participantsMock[3]?.participantId,
      ],
      [ParticipantStatus.REJECTED]: [
        participantsMock[4]?.participantId,
        participantsMock[5]?.participantId,
      ],
    },
  },

  // locationName: 'Santa Rosa 1288, Alberdi, Ciudad de Cordoba, Argentina',
  // eventId: 1,
  // type: 'EVENT',
  // visualization: 'PRIVATE',
  // flyer: defaultEventPic,
  // eventName: 'Event Default 1',
  // description:
  //   'Únete a nosotros para una noche inolvidable de música, baile y estilo en la Fiesta de Lanzamiento "Noches de Estilo". Este evento exclusivo reunirá a los amantes de la moda, la música y el buen ambiente en un espacio único donde podrás disfrutar de los mejores DJ sets, cocteles innovadores y una atmósfera vibrante.',
  // startDate: '2024-09-26T22:00:00.000',
  // endDate: '2024-12-27T06:00:00.000',
  // virtualRoomStartAt: '2024-09-26T20:00:00.000',
  // virtualRoomEndAt: '2024-09-27T21:00:00.000',
  // status: 'OWNER',
  // participants: [
  //   {
  //     profilePictures: [profilePic, profilePic, profilePic],
  //     name: 'Marcos',
  //     lastName: 'Juarez',
  //     status: 'PENDING',
  //     scanned_at: null,
  //   },
  //   {
  //     profilePictures: [profilePic, profilePic, profilePic],
  //     name: 'Agustin',
  //     lastName: 'Dalvit',
  //     status: 'PENDING',
  //     scanned_at: null,
  //   },
  //   {
  //     profilePictures: [profilePic, profilePic, profilePic],
  //     name: 'Pedro',
  //     lastName: 'Sanchez',
  //     status: 'ACCEPTED',
  //     scanned_at: null,
  //   },
  //   {
  //     profilePictures: [profilePic, profilePic, profilePic],
  //     name: 'Dani',
  //     lastName: 'Vinet',
  //     status: 'REJECTED',
  //     scanned_at: 'hola',
  //   },
  //   {
  //     profilePictures: [profilePic, profilePic, profilePic],
  //     name: 'Lucas',
  //     lastName: 'Marchi',
  //     status: 'REJECTED',
  //     scanned_at: null,
  //   },
  // ],
  // quantity: 22,
}
