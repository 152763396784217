import { View, Image, Text, ActivityIndicator } from 'react-native'
import bond from '@/assets/People/vinculo.png'
import styles from './BondButtonStyles'
import {
  BondBtnActions,
  useBondButtonController,
} from './controllers/useBondButtonController'
import { CustomPressable, GetContactMethodModal } from '@/components'
import { TParticipantDetailModel } from '@/models'

const BondButton = ({
  participantId,
}: {
  participantId: TParticipantDetailModel['participantId']
}) => {
  const {
    bondBtnAction,
    sendBondRequest,
    acceptBondRequest,
    isOpenContactModal,
    setIsOpenContactModal,
    participantContactMethods,
  } = useBondButtonController({ participantId })

  const getButtonDetails = () => {
    switch (bondBtnAction) {
      case BondBtnActions.REQUEST_BOND:
        return {
          text: 'Vincularme',
          backgroundColor: '#8811c8',
          circleColor: '#661099',
          icon: <Image source={bond} style={styles.icon} />,
          btnDisabled: false,
          action: sendBondRequest,
        }
      case BondBtnActions.ACCEPT_BOND:
        return {
          text: 'Aceptar',
          backgroundColor: '#8811c8',
          circleColor: '#661099',
          icon: <Image source={bond} style={styles.icon} />,
          btnDisabled: false,
          action: acceptBondRequest,
        }
      case BondBtnActions.PENDING:
        return {
          text: 'Pendiente',
          backgroundColor: '#FFB407',
          circleColor: '#FFA000',
          icon: <Image source={bond} style={styles.icon} />,
          btnDisabled: true,
          action: () => {},
        }
      case BondBtnActions.CONTACT:
        return {
          text: 'Contactar',
          backgroundColor: '#4CAF50',
          circleColor: '#388E3C',
          icon: <Image source={bond} style={styles.icon} />,
          btnDisabled: false,
          action: () => setIsOpenContactModal(true),
        }
      case BondBtnActions.ERROR:
        return {
          text: 'Error',
          backgroundColor: '#b00000',
          circleColor: '#a00000',
          icon: <Text>X</Text>,
          btnDisabled: true,
          action: () => {},
        }
      case BondBtnActions.LOADING:
      default:
        return {
          text: 'Cargando',
          backgroundColor: '#e0e0e0',
          circleColor: '#bdbdbd',
          icon: <ActivityIndicator size="large" color="#ffffff" />,
          btnDisabled: true,
          action: () => {},
        }
    }
  }

  const { text, backgroundColor, circleColor, icon, btnDisabled, action } =
    getButtonDetails()

  return (
    <View style={styles.buttonContainer}>
      <CustomPressable
        style={[styles.thermometerButton, { backgroundColor }]}
        onPress={action}
        disabled={btnDisabled}
      >
        <Text style={styles.buttonText}>{text}</Text>
        <View style={[styles.circle, { backgroundColor: circleColor }]}>
          {icon}
        </View>
      </CustomPressable>
      <GetContactMethodModal
        participantContactMethods={participantContactMethods}
        isOpenContactModal={isOpenContactModal}
        setIsOpenContactModal={setIsOpenContactModal}
      />
    </View>
  )
}

export default BondButton
