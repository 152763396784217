import { TUserModel, TUserProfileModel } from '@/models'
import { deviceNotificationsStatuses } from '@/models/notification.model'
import {
  USER_LOGOUT,
  SET_USER,
  SET_SHOULD_HIDE_TAB_BAR,
  SET_DEVICE_NOTIFICATIONS_STATUS,
} from '@/redux/constants'

const initialState: {
  userInfo?: TUserModel
  userProfiles: TUserProfileModel[]
  deviceNotificationsStatus: deviceNotificationsStatuses
  shouldHideTabBar: boolean
} = {
  userInfo: undefined,
  userProfiles: [],
  deviceNotificationsStatus: deviceNotificationsStatuses.INITIAL_LOADING,
  shouldHideTabBar: false,
}
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        userInfo:
          (action.payload.user as TUserModel) || state.userInfo || undefined,
        userProfiles:
          (action.payload.userProfiles as TUserProfileModel[]) ||
          state.userProfiles ||
          [],
      }
    case SET_DEVICE_NOTIFICATIONS_STATUS:
      return {
        ...state,
        deviceNotificationsStatus:
          action.payload?.deviceNotificationsStatus ??
          deviceNotificationsStatuses.ERROR,
      }
    case SET_SHOULD_HIDE_TAB_BAR:
      return {
        ...state,
        shouldHideTabBar: action.payload?.shouldHideTabBar,
      }
    case USER_LOGOUT:
      return initialState
    default:
      return state
  }
}

export default userReducer
