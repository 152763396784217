export type ReceiptStatus = 'PENDING' | 'PAID';
export const ReceiptStatus = {
  PENDING: 'PENDING',
  PAID: 'PAID',
} as const;

export type TReceiptModel = {
  id: string;
  userId: string;
  status: ReceiptStatus;
  totalAmount: string;
};
