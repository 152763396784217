import { StyleSheet } from 'react-native'
import mail from '@/assets/Profile/correo.png'
import password from '@/assets/Profile/contrasena.png'
import phone from '@/assets/Profile/telefono.png'

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: '100%',
    // height: '35%', //este estabamos usando para usar las opciones de cambiar telefono y cambiar mail
     height: '20%',
    backgroundColor: 'white',
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22,
    backgroundColor: 'transparent',
    justifyContent: 'center',
  },
  bckgImg: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  buttonsContainer: {
    justifyContent: 'center',
    gap: 28,
    alignItems: 'left',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 25,
    marginLeft: 45,
  },
  icon: {
    height: 20,
    width: 20,
  },
  text: {
    color: 'white',
  },
  logOut: {
    backgroundColor: '#9459CE',
    padding: 9,
    borderRadius: 5,
    width: '80%',
    alignItems: 'center',
    margin: 'auto',
    marginTop: 15,
  },
})

export { styles, mail, password, phone }
