//Response al hacer una búsqueda en la pantalla de control de ingreso

import { TUserProfileModel } from '@/models'
import {
  GenderType,
  SentimentalSituationType,
  TUserModel,
} from '@/models/core/user.model'

export const userInfoMock: {
  user: TUserModel
  userProfiles: TUserProfileModel[]
} = {
  user: {
    userId: '1',
    name: 'Sugus',
    lastName: 'Dalvit',
    age: 36,
    birthDate: '20/09/1997',
    gender: GenderType.MALE,
    originLocation: 'Cordoba',
    sentimentalSituation: SentimentalSituationType.SINGLE,
    phone: '3512843532',
    location: {
      id: '1',
      name: 'Cordoba',
    },
    instagramToken: null,
    notificationToken: null,
  },
  userProfiles: [
    {
      id: '2',
      userId: '2',
      userProfileName: '',
      description:
        'Entusiasta de la tecnología, la innovación y el aprendizaje constante. Me apasiona crear soluciones que impacten positivamente en la vida de las personas. Siempre buscando nuevos retos, colaboraciones y formas de crecer, tanto profesional como personalmente. 🚀✨',
      profilePictures: [
        'https://r-charts.com/es/miscelanea/procesamiento-imagenes-magick_files/figure-html/importar-imagen-r.png',
      ],
      ageVisible: true,
      genderVisible: true,
      originLocationVisible: true,
      sentimentalSituationVisible: true,
      contactMethods: {
        email: null,
        instagram: 'root2',
        whatsapp: '3512843532',
        linkedin: 'root2',
      },
    },
  ],
}
