import { ChangeEvent, useState } from 'react'
import { View, Text } from 'react-native'
import styles from './DatePickerCalendarRegisterStyles'

const DatePickerCalendarRegister = ({ userData, setUserData }) => {
  const initialDate = userData?.dateOfBirth
    ? new Date(
        typeof userData.dateOfBirth === 'string'
          ? userData.dateOfBirth.split('T')[0] + 'T00:00:00'
          : userData.dateOfBirth,
      )
    : new Date()

  const [date, setDate] = useState(initialDate)

  const handleDateChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value
    if (!selectedDate) return
    const newDate = new Date(selectedDate)
    if (isNaN(newDate.getTime())) return
    setDate(newDate)
    setUserData(selectedDate)
  }

  return (
    <View style={styles.datePickerContainer}>
      <View style={styles.datePickerInput}>
        <Text style={styles.datePickerLabel}>Fecha de nacimiento</Text>
        <View style={styles.datePickerDivider} />
        <input
          type="date"
          value={date.toISOString().split('T')[0]}
          onChange={handleDateChange}
          style={styles.datePickerInputField}
        />
      </View>
    </View>
  )
}

export default DatePickerCalendarRegister
