import { colors } from '@/theme'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  btnActive: {
    backgroundColor: colors.primary,
    borderRadius: 3,
    height: 40,
    minWidth: 180,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
  },
  btnPending: {
    backgroundColor: colors.secondaryDarker,
    borderRadius: 3,
    height: 40,
    minWidth: 180,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
  },
  btnText: {
    color: 'white',
    fontSize: 15,
    fontWeight: '500',
    textAlign: 'center',
  },
})

export default styles
