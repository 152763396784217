import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    overflow: 'hidden',
    borderRadius: 13,
    position:'relative',
  },
  header: {
    height: 35,
    width: 150,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1, // Asegura que el header esté encima del Animated.View
  },
  headerText: {
    fontSize: 14,
    color: 'white',
    marginBottom: 1.5,
  },
  content: {
    backgroundColor: 'transparent',
    borderBottomLeftRadius: 13,
    borderBottomRightRadius: 13,
    overflow: 'hidden',
    zIndex: 9999,
  },
  contentBackground: {
    flex: 1,
    backgroundColor: 'transparent', // Deja que el fondo del header se vea
  },
  option: {
    backgroundColor: 'transparent',
    padding: 10,
  },
  optionText: {
    textAlign: 'center',
    color: 'white',
  },
})
