import { useState, useEffect } from 'react'
import { View, Text } from 'react-native'
import styles from './EventLabelStyles'
import { ParticipantStatus, UserEventRole } from '@/models'

const EventLabel = ({ opStatus }) => {
  const [labelInfo, setLabelInfo] = useState({})

  useEffect(() => {
    switch (opStatus) {
      case UserEventRole.EVENT_TEAM:
        setLabelInfo({
          text: 'Tu evento',
          backgroundColor: '#8811c8',
          shadowColor: '#661099',
        })
        break
      case ParticipantStatus.PENDING:
        setLabelInfo({
          styles: styles.pendingStatus,
          text: 'Pendiente',
          backgroundColor: '#D08D08',
          shadowColor: '#AD7211',
        })
        break
      case ParticipantStatus.ACCEPTED:
        setLabelInfo({
          text: 'Solicitud Aceptada',
          backgroundColor: '#D08D08',
          shadowColor: '#AD7211',
        })
        break
      case ParticipantStatus.WITH_TICKET:
        setLabelInfo({
          text: 'Confirmado',
          backgroundColor: '#338d36',
          shadowColor: '#326F34',
        })
        break

      default:
        setLabelInfo({
          text: 'Default',
          backgroundColor: '#D08D08',
          shadowColor: '#AD7211',
        })
        break
    }
  }, [opStatus])

  return (
    <View style={[styles.shadow, { backgroundColor: labelInfo?.shadowColor }]}>
      <View
        style={[styles.status, { backgroundColor: labelInfo?.backgroundColor }]}
      >
        <Text style={styles.textStatus}>{labelInfo?.text}</Text>
      </View>
    </View>
  )
}

export default EventLabel
