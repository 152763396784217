import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { AllowNotifications, RegisterUserData } from '@/views'

export type OnboardingNavigatorParamList = {
  CompleteRegister: {}
  AllowNotifications: {}
}

const OnboardingStack =
  createNativeStackNavigator<OnboardingNavigatorParamList>()

export const OnboardingNavigator = () => {
  return (
    <OnboardingStack.Navigator
      initialRouteName="CompleteRegister"
      screenOptions={{ headerShown: false }}
    >
      <OnboardingStack.Screen
        name="CompleteRegister"
        component={RegisterUserData}
        options={{ title: 'Completar Perfil - Vincufy' }}
      />
      <OnboardingStack.Screen
        name="AllowNotifications"
        component={AllowNotifications}
        options={{ title: 'Aceptar Notis - Vincufy' }}
      />
    </OnboardingStack.Navigator>
  )
}
