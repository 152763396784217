import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { ParticipantDetail, MyBonds } from '@/views'

export type BondsNavigatorParamList = {
  MyBonds: {}
  BondParticipant: {
    eventId: string
    participantId: string
  }
}

const BondsStack = createNativeStackNavigator<BondsNavigatorParamList>()

export const BondsNavigator = () => {
  return (
    <BondsStack.Navigator
      initialRouteName={'MyBonds'}
      screenOptions={{ headerShown: false }}
    >
      <BondsStack.Screen
        name="MyBonds"
        component={MyBonds}
        options={{ title: 'Mis Vinculos - Vincufy' }}
      />
      <BondsStack.Screen
        name="BondParticipant"
        component={ParticipantDetail}
        options={{ title: 'Participante - Vincufy' }}
      />
    </BondsStack.Navigator>
  )
}
