import { View, ActivityIndicator } from 'react-native'
import styles from './LoaderStyles'

const Loader = () => {
  return (
    <View style={styles.overlay}>
      <ActivityIndicator size="large" color="#ffffff" />
    </View>
  )
}

export default Loader
