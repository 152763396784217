import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { getUserEventTickets } from '@/redux/thunks'
import { LOADING } from '@/redux/constants'
import { EventDetailNavigatorParamList } from 'routes/Navigators'
import { getUrlParams, useAppRouteParams } from 'routes/utils'

export const useMyTicketsModel = () => {
  const dispatch = useAppDispatch()
  const navParams = useAppRouteParams<
    EventDetailNavigatorParamList,
    'MyTickets'
  >()
  const [routeParams, setRouteParams] = useState<
    Partial<EventDetailNavigatorParamList['MyTickets']>
  >({})
  const params = {
    eventId: navParams?.eventId || routeParams?.eventId || '',
  }
  useEffect(() => {
    const [urlParamEventId] = getUrlParams('MyTickets')

    setRouteParams({
      eventId: urlParamEventId,
    })
  }, [])

  const userEventTickets = useAppSelector(
    (state) => state.event?.userEventsTickets,
  )
  const ticketEventId = userEventTickets?.[0]?.eventId || ''
  const isLoading = useAppSelector((state) => state.auth.isLoading)

  const getTickets = () => {
    if (!params.eventId) return
    dispatch(getUserEventTickets(params.eventId))
  }

  useEffect(() => {
    if (params.eventId && userEventTickets?.[0]?.eventId !== params.eventId) {
      getTickets()
    }
  }, [userEventTickets?.[0]?.eventId, params.eventId])

  const setIsLoading = (state) => {
    dispatch({
      type: LOADING,
      state,
    })
  }

  return {
    userEventTickets: ticketEventId !== params.eventId ? [] : userEventTickets,
    eventId: params.eventId,
    isLoading,
    setIsLoading,
    getTickets,
  }
}
