import { AsyncThunk, createAsyncThunk, GetThunkAPI } from '@reduxjs/toolkit'
import { RootState } from './store'
import { AxiosError } from 'axios'

export function createCustomAsyncThunk<Arg = any, Returned = any>(
  type: string,
  asyncFunction: (
    arg: Arg,
    thunkAPI: GetThunkAPI<{
      state: RootState
    }>,
  ) => Promise<Returned>,
): AsyncThunk<
  Returned,
  Arg,
  {
    state: RootState
  }
> {
  return createAsyncThunk<Returned, Arg>(
    type,
    async (
      arg: Arg,
      thunkAPI: GetThunkAPI<{
        state: RootState
      }>,
    ) => {
      try {
        return await asyncFunction(arg, thunkAPI)
      } catch (error) {
        // Si el error tiene datos estructurados (por ejemplo, del microservicio)
        if (
          error instanceof AxiosError &&
          error.response?.data?.success === false
        ) {
          return thunkAPI.rejectWithValue(error.response.data)
        }
        // Si no, simplemente lanza el error original
        throw error
      }
    },
  )
}
