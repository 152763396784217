import { StyleSheet } from 'react-native-web'

export default StyleSheet.create({
  backgroundImage: {
    flex: 1,
    justifyContent: 'center',
  },
  gral: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    maxWidth: '100vw',
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  logoVincufy: {
    marginTop: '10%',
    height: 60,
    width: '100%',
  },
  registerInputs: {
    marginTop: 50,
    marginBottom: '4%',
    width: '100%',
    alignItems: 'center',
  },
  error: {
    color: 'rgb(215, 64, 64)',
    marginTop: 8,
    marginBottom: -10,
  },
  registerFooter: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(197, 197, 197, 0.116)',
    paddingVertical: 10,
    marginTop: 30,
    paddingHorizontal: 20,
    borderRadius: 8,
    fontSize: 18,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 5,
  },
  span: {
    color: 'white',
    fontWeight: '500',
    textDecorationLine: 'underline',
    marginLeft: 5,
  },
  text: { color: 'white' },
  buttonText: {
    color: 'white',
  },
} as const)
