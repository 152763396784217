import { colors } from '@/theme'
import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  eventName: {
    color: 'white',
    fontSize: 16.5,
    width: '100%',
    maxWidth: 175,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 30,
    gap: 10,
    width: '100%',
  },
  bondRequestBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7,
    padding: 5,
    width: 150,
    height: 33,
    backgroundColor: '#9459CE',
    marginTop: 5,
  },
  bondRequestNoti: {
    position: 'absolute',
    right: -3,
    top: -3,
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: colors.secondary,
  },
  buttonText: {
    color: 'white',
    fontSize: 13,
    marginBottom: 2,
  },
})
