import { View } from 'react-native'
import { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import {
  getBondAction,
  getParticipantDetail,
  setShouldHideTabBar,
} from '@/redux/thunks'
import {
  Carousel,
  ScreenLayout,
  InfoCard,
  Loader,
  SetContactMethodModal,
} from '@/components'
import styles from './ParticipantDetailStyles'
import BondButton from '../BondButton/BondButton'
import { useParticipantDetailController } from './useParticipantDetailController'

const ParticipantDetail = () => {
  const { params, fromAdminPanel, goBackFunc } =
    useParticipantDetailController()

  const participantFetched = useRef<boolean>(false)

  const dispatch = useAppDispatch()
  const participantDetail = useAppSelector(
    (state) => state.event.participants[params.participantId] || null,
  )

  const [isOpenContactModal, setIsOpenContactModal] = useState(false)

  useEffect(() => {
    dispatch(setShouldHideTabBar(true))
  }, [])

  useEffect(() => {
    if (!fromAdminPanel && params.participantId) {
      if (participantDetail === null) {
        participantFetched.current = true
        dispatch(getParticipantDetail(params.participantId))
      } else if (participantFetched.current === false) {
        participantFetched.current = true
        dispatch(getBondAction({ participantId: params.participantId }))
      }
    }
  }, [participantDetail, params.participantId, fromAdminPanel])

  if (!participantDetail) return <Loader />

  return (
    <ScreenLayout goBackFunc={goBackFunc}>
      <Carousel images={participantDetail.profilePictures} />
      {!fromAdminPanel && <BondButton participantId={params.participantId} />}
      <View style={styles.cardContainer}>
        <InfoCard userInfo={participantDetail} />
      </View>
      <SetContactMethodModal
        isOpenContactModal={isOpenContactModal}
        setIsOpenContactModal={setIsOpenContactModal}
      />
    </ScreenLayout>
  )
}

export default ParticipantDetail
