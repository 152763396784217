//AUTH ACTIONS
export const LOGGED = 'LOGGED'
export const POST_LOGGED_FLOW_FINISHED = 'POST_LOGGED_FLOW_FINISHED'
export const AUTH_LOGOUT = 'LOGOUT'
export const LOADING = 'LOADING'

//USERS ACTIONS
export const USER_LOGOUT = 'USER_LOGOUT'
export const SET_USER = 'SET_USER'
export const SET_DEVICE_NOTIFICATIONS_STATUS = 'SET_DEVICE_NOTIFICATIONS_STATUS'
export const SET_IS_USERINFO_EMPTY = 'SET_IS_USERINFO_EMPTY'
export const SET_SHOULD_HIDE_TAB_BAR = 'SET_SHOULD_HIDE_TAB_BAR'
// export const GET_ALL_USERS = 'GET_ALL_USERS'
// export const DELETE_USER = 'DELETE_USER'
// export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'

//EVENTS ACTIONS
export const EVENT_LOGOUT = 'EVENT_LOGOUT'

export const GET_EVENT = 'GET_EVENT'

export const GET_EVENT_DETAIL = 'GET_EVENT_DETAIL'

export const GET_USER_EVENTS = 'GET_USER_EVENTS'
export const GET_USER_EVENTS_LOADING = 'GET_USER_EVENTS_LOADING'
export const GET_USER_EVENTS_ERROR = 'GET_USER_EVENTS_ERROR'

export const SET_SELECTED_EVENT_ID = 'SET_SELECTED_EVENT_ID'

export const SET_PARTICIPANTS = 'SET_PARTICIPANTS'
export const UPDATE_PARTICIPANTS_BONDS = 'UPDATE_PARTICIPANTS_BONDS'

export const GET_EVENT_PARTICIPANTS = 'GET_EVENT_PARTICIPANTS'
export const GET_EVENT_PARTICIPANTS_LOADING = 'GET_EVENT_PARTICIPANTS_LOADING'
export const GET_EVENT_PARTICIPANTS_ERROR = 'GET_EVENT_PARTICIPANTS_ERROR'

export const GET_EVENT_PARTICIPANT = 'GET_EVENT_PARTICIPANT'
export const GET_EVENT_PARTICIPANT_LOADING = 'GET_EVENT_PARTICIPANT_LOADING'
export const GET_EVENT_PARTICIPANT_ERROR = 'GET_EVENT_PARTICIPANTS_ERROR'

export const GET_ADMIN_EVENT_INFO = 'GET_ADMIN_EVENT_INFO'
export const GET_ADMIN_EVENT_INFO_LOADING = 'GET_ADMIN_EVENT_INFO_LOADING'
export const GET_ADMIN_EVENT_INFO_ERROR = 'GET_ADMIN_EVENT_INFO_ERROR'

export const SET_PARTICIPANT_STATUS = 'SET_PARTICIPANT_STATUS'
export const SET_PARTICIPANT_STATUS_LOADING = 'SET_PARTICIPANT_STATUS_LOADING'
export const SET_PARTICIPANT_STATUS_ERROR = 'SET_PARTICIPANT_STATUS_ERROR'

export const SET_TRANSITION_INFO = 'SET_TRANSITION_INFO'

export const SET_ENTRY_TICKETS = 'SET_ENTRY_TICKETS'
// export const CREATE_EVENT = 'CREATE_EVENT'
// export const EDIT_EVENT = 'EDIT_EVENT'
// export const GET_ALL_EVENTS = 'GET_ALL_EVENTS'
// export const GET_ADMINS = 'GET_ADMINS'
// export const GET_EVENT_ADMIN_INFO = 'GET_EVENT_ADMIN_INFO'
// export const ADD_ADMIN = 'ADD_ADMIN'
// export const ERASE_ADMIN = 'ERASE_ADMIN'

//TICKET ACTIONS
export const GET_USER_EVENTS_TICKETS = 'GET_USER_EVENTS_TICKETS'

export const GET_PARTICIPANT_BY_CODE = 'GET_PARTICIPANT_BY_CODE'
export const GET_PARTICIPANT_BY_CODE_LOADING = 'GET_PARTICIPANT_BY_CODE_LOADING'
export const GET_PARTICIPANT_BY_CODE_ERROR = 'GET_PARTICIPANT_BY_CODE_ERROR'

export const REQUEST_FREE_TICKET = 'REQUEST_FREE_TICKET'
export const REQUEST_FREE_TICKET_LOADING = 'REQUEST_FREE_TICKET_LOADING'
export const REQUEST_FREE_TICKET_ERROR = 'REQUEST_FREE_TICKET_ERROR'

export const SCAN_TICKET = 'SCAN_TICKET'
export const SCAN_TICKET_LOADING = 'SCAN_TICKET_LOADING'
export const SCAN_TICKET_ERROR = 'SCAN_TICKET_ERROR'

//BONDS ACTIONS
export const GET_BONDS = 'GET_BONDS'
export const GET_BONDS_LOADING = 'GET_BONDS_LOADING'
export const GET_BONDS_ERROR = 'GET_BONDS_ERROR'

export const GET_EVENT_BONDS = 'GET_EVENT_BONDS'
export const GET_EVENT_BONDS_LOADING = 'GET_EVENT_BONDS_LOADING'
export const GET_EVENT_BONDS_ERROR = 'GET_EVENT_BONDS_ERROR'

export const GET_BOND = 'GET_BOND'
export const GET_BOND_LOADING = 'GET_BOND_LOADING'
export const GET_BOND_ERROR = 'GET_BOND_ERROR'

export const SEND_BOND_REQUEST = 'SEND_BOND_REQUEST'
export const SEND_BOND_REQUEST_LOADING = 'SEND_BOND_REQUEST_LOADING'
export const SEND_BOND_REQUEST_ERROR = 'SEND_BOND_REQUEST_ERROR'

export const RESPOND_BOND_REQUEST = 'RESPOND_BOND_REQUEST'
export const RESPOND_BOND_REQUEST_LOADING = 'RESPOND_BOND_REQUEST_LOADING'
export const RESPOND_BOND_REQUEST_ERROR = 'RESPOND_BOND_REQUEST_ERROR'

export const BOND_LOGOUT = 'BOND_LOGOUT'
