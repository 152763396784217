import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    color: 'white',
    fontSize: 18,
    marginTop: 35,
    marginLeft: 25,
  },
})

export default styles
