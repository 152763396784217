export const updateRemainingSeconds = (
  virtualRoomStartAt,
  setRemainingSecondsToStart,
): null | number => {
  if (virtualRoomStartAt) {
    const totalSeconds = Math.floor(
      (new Date(virtualRoomStartAt).getTime() - new Date().getTime()) / 1000,
    )

    setRemainingSecondsToStart(totalSeconds)
    return totalSeconds
  }
  return null
}
