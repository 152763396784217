import { View, Text, ScrollView } from 'react-native'
import styles from './PreVirtualRoomStyles'
import EventCard from './EventCard/EventCard'
import CounterDown from './CounterDown/CounterDown'
import { TUserEventModel } from '@/models'

const PreVirtualRoom = ({
  remainingSecondsToStart,
  userEvent,
}: {
  remainingSecondsToStart: number
  userEvent?: TUserEventModel
}) => {
  return (
    <ScrollView
      contentContainerStyle={styles.container}
      showsVerticalScrollIndicator={false}
    >
      <Text style={styles.title}>Próximo evento</Text>
      <EventCard userEvent={userEvent} />
      <View style={styles.counterContainer}>
        <Text style={styles.counterTitle}>Apertura de sala virtual</Text>
        <CounterDown remainingSecondsToStart={remainingSecondsToStart} />
      </View>
    </ScrollView>
  )
}

export default PreVirtualRoom
