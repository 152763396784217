import { View, Text, Modal, Image } from 'react-native'
import plus from '@/assets/Profile/subir2.png'
import emptyProfilePic from '@/assets/Profile/emptyProfilePic.png'
import styles from './InfoModalStyles'
import { useModalContext } from '../context'
import { CustomPressable } from '@/components'
import { pickProfileImage } from '@/redux/thunks'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { theme } from '@/theme'

export default function InfoModal() {
  const dispatch = useAppDispatch()
  const { isOpenModal, closeModal } = useModalContext('InfoModal')
  const isLoading = useAppSelector((state) => state.auth.isLoading)

  const handlePickImage = async () => {
    const result = await dispatch(pickProfileImage(0)).unwrap()
    if (result === 'PERMISSION_NOT_GRANTED') {
      alert(
        'Para subir una nueva foto de perfil necesitamos permiso para acceder a tu galería.',
      )
    } else if (result === 'NO_IMAGE_SELECTED') {
    } else {
      closeModal()
    }
  }

  return (
    <Modal transparent={true} visible={isOpenModal} animationType="fade">
      <View style={styles.background}>
        <View style={[styles.modal, theme.bgGradientRight]}>
          <Text style={styles.text}>
            Agregá una foto de perfil para que podamos identificarte en el
            ingreso. También vas a poder vincularte con todas las personas que
            irán al evento.
          </Text>
          <Image source={emptyProfilePic} style={styles.imgInfo} />
          <View style={styles.buttonContainer}>
            <CustomPressable
              onPress={handlePickImage}
              style={styles.btn}
              isLoading={isLoading}
            >
              <Image source={plus} style={styles.btnIcon} />
              <Text style={styles.btnText}>Agregar</Text>
            </CustomPressable>
            <CustomPressable onPress={closeModal} style={styles.btn}>
              <Text style={styles.btnText}>Más tarde</Text>
            </CustomPressable>
          </View>
        </View>
      </View>
    </Modal>
  )
}
