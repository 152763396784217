import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  activeButton: {
    height: 35,
    width: 100,
    borderRadius: 8,
    overflow: 'hidden',
  },
  inactiveButton: {
    height: 35,
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  buttonText: {
    color: 'white',
    marginBottom: 2,
    fontSize: 13,
  },
} as const)

export default styles
