import { navigationLog } from '@/utils'
import { navigationRef } from 'routes/Navigation'
import { PAGES_NAV, TAllPageNavigatorsParams } from './pages-nav'
import { updateCurrentRoute } from './url-functions'

export const goBackTo = <PageName extends keyof TAllPageNavigatorsParams>(
  pageName: PageName,
  params: TAllPageNavigatorsParams[PageName],
): boolean => {
  const navReady = navigationRef?.isReady()
  const previousPage = {
    name: navigationRef?.getCurrentRoute()?.name,
    params: navigationRef?.getCurrentRoute()?.params,
  }
  const navExecuted = navReady && previousPage.name !== pageName
  navigationLog('goBackTo:', {
    pageName,
    params,
    navExecuted,
    previousPage,
  })

  if (navExecuted) {
    if (navigationRef?.canGoBack()) {
      navigationRef.goBack()
    } else {
      const options = PAGES_NAV[pageName].navStructure(params)

      navigationRef.reset({
        index: 0,
        routes: [options],
      })
    }

    updateCurrentRoute(pageName, params)
    return true
  }
  return false
}
