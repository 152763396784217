import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Image } from 'react-native'
import {
  styles,
  screenOptions,
  myProfile,
  myRelationships,
  myEvent,
} from './MyTabsNavigatorStyles'
import {
  ProfileNavigator,
  ProfileNavigatorParamList,
} from './Tabs/ProfileNavigator'
import {
  EventsNavigator,
  EventsNavigatorParamList,
} from './Tabs/EventsNavigator/EventsNavigator'
import { useAppSelector } from '@/redux/store'
import { NavigatorScreenParams } from '@react-navigation/native'
import { navigateTo } from 'routes/utils'
import { BondsNavigator, BondsNavigatorParamList } from './Tabs/BondsNavigator'

export type MyTabsNavigatorParamList = {
  EventsNavigator: NavigatorScreenParams<EventsNavigatorParamList>
  BondsNavigator: NavigatorScreenParams<BondsNavigatorParamList>
  ProfileNavigator: NavigatorScreenParams<ProfileNavigatorParamList>
}

const Tab = createBottomTabNavigator<MyTabsNavigatorParamList>()
const getTabIconColor = (focused: boolean) => (focused ? '#f7c040' : '#ffffff')

export const MyTabsNavigator = () => {
  const shouldHideTabBar = useAppSelector(
    (state) => state.user.shouldHideTabBar,
  )

  return (
    <Tab.Navigator
      initialRouteName="EventsNavigator"
      screenOptions={() => screenOptions(shouldHideTabBar)}
    >
      <Tab.Screen
        name="EventsNavigator"
        component={EventsNavigator}
        options={{
          tabBarIcon: ({ focused }) => (
            <Image
              source={myEvent}
              style={styles.tabIcon}
              tintColor={getTabIconColor(focused)}
            />
          ),
          tabBarLabel: 'Eventos',
          unmountOnBlur: true,
        }}
        listeners={() => ({
          tabPress: (e) => {
            e.preventDefault() // Evita la navegación normal
            navigateTo('MyEvents', {}) // , 'reset'
          },
        })}
      />
      <Tab.Screen
        name="BondsNavigator"
        component={BondsNavigator}
        options={{
          tabBarIcon: ({ focused }) => (
            <Image
              source={myRelationships}
              style={styles.tabIcon}
              tintColor={getTabIconColor(focused)}
            />
          ),
          tabBarLabel: 'Vínculos',
          unmountOnBlur: true,
        }}
        listeners={() => ({
          tabPress: (e) => {
            e.preventDefault() // Evita la navegación normal
            navigateTo('MyBonds', {}) // , 'reset'
          },
        })}
      />
      <Tab.Screen
        name="ProfileNavigator"
        component={ProfileNavigator}
        options={{
          tabBarIcon: ({ focused }) => (
            <Image
              source={myProfile}
              style={styles.tabIcon}
              tintColor={getTabIconColor(focused)}
            />
          ),
          tabBarLabel: 'Perfil',
          unmountOnBlur: true,
        }}
        listeners={() => ({
          tabPress: (e) => {
            e.preventDefault() // Evita la navegación normal
            navigateTo('MyProfile', {}) // , 'reset'
          },
        })}
      />
    </Tab.Navigator>
  )
}
