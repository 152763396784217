import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    marginTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: '85%',
    paddingVertical: 50,
    borderRadius: 7,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },
  bckgImg: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 7,
  },
  imgContainer: {
    borderRadius: 50,
    width: 90,
    height: 90,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: '100%',
    height: '100%',
    borderRadius: 50,
  },
  name: {
    color: 'white',
    fontSize: 24,
    fontWeight: '500',
    textAlign: 'center',
  },
  dateOfBirth: {
    color: 'white',
    fontSize: 18,
  },
  btn: {
    backgroundColor: '#8811c8',
    borderRadius: 7,
    padding: 10,
    width: 150,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  buttonText: {
    color: 'white',
    fontSize: 15,
    marginBottom: 2,
  },
  scannedTicket: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
  },
  icon: {
    height: 30,
    width: 30,
  },
  box: {
    backgroundColor: 'black',
    borderWidth: 1,
    borderColor: 'grey',
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  errorText: {
    color: 'rgb(224, 20, 20)',
    lineHeight: 17,
    fontSize: 12,
    marginTop: 7,
  },
} as const)
