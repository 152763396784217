import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  box: {
    backgroundColor: '#1e1e1e',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center', // Centrar los elementos hijos horizontalmente
    justifyContent: 'center', // Centrar los elementos hijos verticalmente
  },

  timeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    alignItems: 'center', // Centrar verticalmente los elementos en la fila
  },
  time: {
    fontSize: 24,
    color: '#ffffff',
    margin: 5,
    textAlign: 'center', // Asegurarse de que el texto esté centrado
  },
  label: {
    fontSize: 14,
    color: '#b0b0b0',
    textAlign: 'center', // Asegurarse de que la etiqueta esté centrada
  },
});

export default styles;
