import {
  ENVIRONMENT,
  URL_API_VINCUFY_LOCAL_DEVTUNNEL,
  URL_API_VINCUFY_LOCAL_NETWORK,
  URL_API_VINCUFY_LOCAL_DEVICE,
  URL_API_VINCUFY_DEV,
  URL_API_VINCUFY_PROD,
} from '@env'

const ENVIRONMENTS = {
  FRONT_ONLY: 'front-only', // No Connection with back (use local mocks) <BEST>
  LOCALHOST: 'localhost', // Connection with local back (through "localhost")
  LOCAL_NETWORK: 'local-network', // Connection with local back (through wifi network IP)
  LOCAL_TUNNEL: 'local-tunnel', // Connection with local back (through public vsc tunnel) <BEST>
  DEV: 'dev', // Connection with cloud back (dev env) <NOT_WORKING>
  PRODUCTION: 'production', // Connection with cloud back (prod env) <AVOID>
} as const

const URL_API_VINCUFY = {
  [ENVIRONMENTS.LOCALHOST]: URL_API_VINCUFY_LOCAL_DEVICE || '',
  [ENVIRONMENTS.LOCAL_NETWORK]: URL_API_VINCUFY_LOCAL_NETWORK || '',
  [ENVIRONMENTS.LOCAL_TUNNEL]: URL_API_VINCUFY_LOCAL_DEVTUNNEL || '',
  [ENVIRONMENTS.FRONT_ONLY]: '',
  [ENVIRONMENTS.DEV]: URL_API_VINCUFY_DEV || '',
  [ENVIRONMENTS.PRODUCTION]: URL_API_VINCUFY_PROD || '',
}

export const isProdEnv = () => {
  if (ENVIRONMENT === ENVIRONMENTS.LOCALHOST) return false
  if (ENVIRONMENT === ENVIRONMENTS.LOCAL_NETWORK) return false
  if (ENVIRONMENT === ENVIRONMENTS.LOCAL_TUNNEL) return false
  if (ENVIRONMENT === ENVIRONMENTS.FRONT_ONLY) return false
  if (ENVIRONMENT === ENVIRONMENTS.DEV) return false
  return true
}

const envs = {
  URL_API_VINCUFY: URL_API_VINCUFY[ENVIRONMENT || ENVIRONMENTS.PRODUCTION],
  ENVIRONMENTS,
} as const

export default envs
