import { useState } from 'react'
import { View, Text } from 'react-native'
import { ScreenLayout, TicketSearchBar } from '@/components'
import styles from './TicketControlStyles'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { getParticipantByCode } from '@/redux/thunks'
import ParticipantFoundCard from './components/ParticipantFoundCard/ParticipantFoundCard'
import { fetchStatus, TParticipantDetailModel } from '@/models'
import { scanTicket } from '../../../redux/thunks/ticket.thunk'
import { AdminPanelNavigatorParamList } from 'routes/Navigators'
import { goBackTo, useAppRouteParams } from 'routes/utils'

const TicketControl = () => {
  const params = useAppRouteParams<
    AdminPanelNavigatorParamList,
    'TicketControl'
  >()
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useAppDispatch()
  const participantFound = useAppSelector((state) =>
    state.event.participantByCode
      ? (state.event.participants[
          state.event.participantByCode
        ] as TParticipantDetailModel)
      : null,
  )
  const participantByCodeStatus = useAppSelector(
    (state) => state.event.participantByCodeStatus,
  )
  const participantByCodeError = useAppSelector(
    (state) => state.event.participantByCodeError,
  )

  const onSearch = (searchTerm: string) => {
    if (!params?.eventId) return
    dispatch(
      getParticipantByCode({ eventId: params.eventId, code: searchTerm }),
    )
  }

  const onScanTicket = () => {
    if (!participantFound || !params?.eventId) return
    dispatch(
      scanTicket({
        eventId: params.eventId,
        ticketId: participantFound.ticketId,
        participantId: participantFound.participantId,
      }),
    )
  }

  return (
    <ScreenLayout
      goBackFunc={
        !params?.eventId
          ? null
          : () => goBackTo('AdminPanel', { eventId: params.eventId })
      }
    >
      <View style={styles.container}>
        <Text style={styles.title}>Ingresar código</Text>
        <TicketSearchBar
          placeHolder={'Código de entrada...'}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onSearch={onSearch}
          isLoading={participantByCodeStatus === fetchStatus.LOADING}
        />
        <ParticipantFoundCard
          showResult={participantByCodeStatus === fetchStatus.SUCCESS}
          participant={participantFound}
          error={participantByCodeError}
          onScanTicket={onScanTicket}
        />
      </View>
    </ScreenLayout>
  )
}

export default TicketControl
