import { View, Text, TextInput } from 'react-native'
import { CustomPressable, Header } from '@/components'
import styles from './ChangeInfoSettingsStyles'
import { useChangeInfoSettings } from './UseChangeInfoSettings'
import { theme } from '@/theme'
import { ProfileNavigatorParamList } from 'routes/Navigators'
import { goBackTo, useAppRouteParams } from 'routes/utils'

const ChangeInfoSettings = () => {
  const params = useAppRouteParams<
    ProfileNavigatorParamList,
    'ChangeInfoSettings'
  >()
  const isEmail = params?.item === 'mail'

  const {
    currentValue,
    email,
    areaCode,
    phoneNumber,
    errors,
    handleChangeInput,
    handleSubmit,
    isSubmitDisabled,
    isSubmitLoading,
  } = useChangeInfoSettings(isEmail)

  return (
    <>
      <Header goBackFunc={() => goBackTo('MyProfile', {})} />
      <View style={styles.container}>
        <Text style={styles.label}>Este es tu {params?.item} actual</Text>
        <View style={styles.inputGroup}>
          <View style={[styles.inputFilledView, theme.bgGradientRight]}>
            <View style={styles.inputFilled}>
              <Text style={styles.text}>{currentValue}</Text>
            </View>
          </View>
          {isEmail ? (
            <>
              <TextInput
                style={styles.input}
                value={email}
                onChange={(e) => handleChangeInput('email', e)}
                placeholder={`Escribí tu nuevo mail`}
                placeholderTextColor="white"
              />
              {errors?.email && (
                <Text style={styles.errorText}>{errors.email}</Text>
              )}
            </>
          ) : (
            <View style={styles.inputRow}>
              <View style={[styles.inputContainer, styles.phoneContainer]}>
                <Text style={styles.labelText}>Cod. área</Text>
                <TextInput
                  style={styles.code}
                  value={areaCode}
                  onChange={(e) => handleChangeInput('areaCode', e)}
                  placeholder="Ej: 011"
                  placeholderTextColor="white"
                  inputMode="numeric"
                  maxLength={3}
                />
                {errors.areaCode && (
                  <Text style={styles.errorText}>{errors.areaCode}</Text>
                )}
              </View>
              <View style={[styles.inputContainer, styles.phoneContainer]}>
                <Text style={styles.labelText}>Número sin el 15</Text>
                <TextInput
                  style={styles.phone}
                  value={phoneNumber}
                  onChange={(e) => handleChangeInput('phoneNumber', e)}
                  placeholder="Ej: 2843532"
                  placeholderTextColor="white"
                  inputMode="numeric"
                  maxLength={7}
                />
                {errors.phoneNumber && (
                  <Text style={styles.errorText}>{errors.phoneNumber}</Text>
                )}
              </View>
            </View>
          )}
        </View>
        <CustomPressable
          style={
            isSubmitDisabled ? styles.disabledButton : styles.enabledButton
          }
          disabled={!!isSubmitDisabled}
          onPress={handleSubmit}
          isLoading={isSubmitLoading}
        >
          <Text style={{ color: 'white' }}>Cambiar</Text>
        </CustomPressable>
      </View>
    </>
  )
}

export default ChangeInfoSettings
