import { StyleSheet, Platform } from 'react-native'

export default StyleSheet.create({
  circle: {
    borderRadius: 40,
    height: 45,
    width: 45,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: -0.5,
  },
  icon: {
    width: 15,
    height: 15,
  },
  buttonText: {
    fontSize: 11,
    color: '#fff',
    flex: 1,
    textAlign: 'center',
    marginLeft: 5,
  },
  thermometerButton: {
    flexDirection: 'row',
    borderRadius: Platform.OS === 'ios' ? 25 : 37,
    borderBottomRightRadius: 45,
    borderTopRightRadius: 45,
    height: 33,
    width: 111,
    alignItems: 'center',
  },
})
