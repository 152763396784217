import { TParticipantDetailModel } from '@/models'
import { participantsMock } from './participantsMock'

export const virtualRoomMock: {
  participants: TParticipantDetailModel[]
  totalParticipants: number
  page: number
} = {
  page: 1,
  totalParticipants: 5,
  participants: participantsMock,
}
