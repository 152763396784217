import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { MyProfile, ContactMethodScreen, ChangeInfoSettings } from '@/views'

export type ProfileNavigatorParamList = {
  MyProfile: {}
  ChangeInfoSettings: { item: string }
  ContactMethodScreen: { origin: string }
}

const ProfileStack = createNativeStackNavigator<ProfileNavigatorParamList>()

export const ProfileNavigator = () => {
  return (
    <ProfileStack.Navigator
      initialRouteName="MyProfile"
      screenOptions={{ headerShown: false }}
    >
      <ProfileStack.Screen
        name="MyProfile"
        component={MyProfile}
        options={{ title: 'Perfil - Vincufy' }}
      />
      <ProfileStack.Screen
        name="ChangeInfoSettings"
        component={ChangeInfoSettings}
        options={{ title: 'Editar Perfil - Vincufy' }}
      />
      <ProfileStack.Screen
        name="ContactMethodScreen"
        component={ContactMethodScreen}
        options={{ title: 'Métodos de Contacto - Vincufy' }}
      />
    </ProfileStack.Navigator>
  )
}
