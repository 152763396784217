//Response al hacer una búsqueda en la pantalla de control de ingreso

import { TUserAuthModel } from '@/models'

export const userAuthMock: TUserAuthModel = {
  id: '1',
  userId: '1',
  userName: 'admin1',
  email: 'admin1@test.com',
}
