import { useAppDispatch, useAppSelector } from '@/redux/store'
import { getUserBondsAction } from '@/redux/thunks'
import { useEffect, useState } from 'react'
import { fetchStatus } from '@/models/fetch.model'
import { BondStatus, TEventModel, TParticipantDetailModel } from '@/models'
import { devLog } from '@/utils'

export type TUserBonds = {
  eventId: TEventModel['id']
  eventName: TEventModel['name']
  bonds: TParticipantDetailModel[]
}[]

export const useBondsController = () => {
  const dispatch = useAppDispatch()
  const participants = useAppSelector((state) => state.event.participants)
  const bondsStatus = useAppSelector((state) => state.bond.bondsStatus)
  const bondsLoading =
    bondsStatus === fetchStatus.LOADING || bondsStatus === fetchStatus.TO_FETCH
  const [bondsByEvents, setBondsByEvents] = useState<TUserBonds>([])

  const getBonds = () => {
    dispatch(getUserBondsAction())
  }

  useEffect(() => {
    if (bondsStatus === fetchStatus.TO_FETCH) {
      getBonds()
    }
  }, [bondsStatus])

  useEffect(() => {
    const participantsArray = Object.values(participants)
    if (participantsArray.length) {
      setBondsByEvents(
        participantsArray
          .filter((part) => part.bond?.status === BondStatus.ACCEPTED)
          .reduce((events, part) => {
            const newEvents = [...events]
            const selectedEventIndex = newEvents.findIndex(
              (event) => event.eventId === part.eventId,
            )
            if (selectedEventIndex >= 0) {
              newEvents[selectedEventIndex].bonds.push(part)
            } else {
              newEvents.push({
                eventId: part.eventId,
                eventName: part.eventName,
                bonds: [part],
              })
            }

            return newEvents
          }, [] as TUserBonds),
      )
    }
  }, [participants])

  return { bonds: bondsByEvents, bondsLoading, getBonds }
}
