import { TEventModel } from './core/event.model'
import { TParticipantModel } from './core/participant.model'
import { TVirtualRoomModel } from './core/virtual-room.model'

export type UserEventRole = 'NOTHING' | 'EVENT_TEAM' | 'PARTICIPANT'
export const UserEventRole = {
  NOTHING: 'NOTHING',
  EVENT_TEAM: 'EVENT_TEAM',
  PARTICIPANT: 'PARTICIPANT',
} as const

export type TUserEventModel = {
  eventId: TEventModel['id']
  eventName: TEventModel['name']
  flyer: TEventModel['flyer']
  type: TEventModel['type']
  visualization: TEventModel['visualization']
  startDate: TEventModel['startAt']
  endDate: TEventModel['endAt']

  virtualRoomStartAt: TVirtualRoomModel['startAt'] | null
  virtualRoomEndAt: TVirtualRoomModel['endAt'] | null

  participantStatus: TParticipantModel['status'] | null
  role: UserEventRole
}
