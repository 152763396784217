import {
  BondStatus,
  GenderType,
  ParticipantStatus,
  SentimentalSituationType,
  TParticipantDetailModel,
} from '@/models'

const profilePic =
  'https://firebasestorage.googleapis.com/v0/b/vincufy-34e1a.appspot.com/o/29e15344-f0cf-4f13-8a5c-f7caa29d3a54_photo_1729062411531.png?alt=media&token=be59cb8f-df15-48eb-ba72-381f73cf30a8'

export const participantsMock: TParticipantDetailModel[] = [
  {
    participantId: '10',
    status: ParticipantStatus.WITH_TICKET,

    userId: '10',
    name: 'Marcos',
    lastName: 'Juarez',
    age: 28,
    phone: '',
    birthDate: '',
    gender: GenderType.MALE,
    originLocation: 'Cruz del Eje',
    sentimentalSituation: SentimentalSituationType.SINGLE,

    userProfileId: '2',
    description:
      'Creador de ideas y soluciones tecnológicas, apasionado por aprender y hacer la diferencia en cada proyecto. 🌟',
    profilePictures: [profilePic, profilePic, profilePic],
    contactMethods: {
      email: '',
      linkedin: '',
      instagram: '',
      whatsapp: '',
    },

    eventId: '2',
    eventName: 'Pool party',

    ticketId: '1',
    ticketTypeId: '1',
    ticketScannedAt: '',

    bond: {
      id: '2',
      status: BondStatus.ACCEPTED,
      requesterId: '2',
      receiverId: '1',
      currentUserIsRequester: false,
    },
  },
  {
    participantId: '2',
    status: ParticipantStatus.WITH_TICKET,

    userId: '2',
    name: 'Marcos',
    lastName: 'Juarez',
    age: 28,
    phone: '',
    birthDate: '',
    gender: GenderType.MALE,
    originLocation: 'Cruz del Eje',
    sentimentalSituation: SentimentalSituationType.SINGLE,

    userProfileId: '2',
    description:
      'Creador de ideas y soluciones tecnológicas, apasionado por aprender y hacer la diferencia en cada proyecto. 🌟',
    profilePictures: [profilePic, profilePic, profilePic],
    contactMethods: {
      email: '',
      linkedin: '',
      instagram: '',
      whatsapp: '',
    },

    eventId: '1',
    eventName: 'Cumple Leonel',

    ticketId: '1',
    ticketTypeId: '1',
    ticketScannedAt: '',

    bond: {
      id: '2',
      status: BondStatus.ACCEPTED,
      requesterId: '2',
      receiverId: '1',
      currentUserIsRequester: false,
    },
  },
  {
    participantId: '3',
    status: ParticipantStatus.WITH_TICKET,

    userId: '3',
    name: 'Agustin',
    lastName: 'Dalvit',
    age: 27,
    phone: '',
    birthDate: '',
    gender: GenderType.MALE,
    originLocation: 'Córdoba',
    sentimentalSituation: SentimentalSituationType.SINGLE,

    userProfileId: '3',
    description:
      'Siempre explorando nuevas oportunidades para innovar, aprender y crecer en el mundo de la tecnología. 💻✨',
    profilePictures: [profilePic, profilePic, profilePic],
    contactMethods: {
      email: '',
      linkedin: '',
      instagram: '',
      whatsapp: '',
    },

    eventId: '1',
    eventName: 'Cumple Leonel',

    ticketId: '2',
    ticketTypeId: '1',
    ticketScannedAt: new Date().toISOString(),

    bond: {
      id: '4',
      status: BondStatus.ACCEPTED,
      requesterId: '4',
      receiverId: '1',
      currentUserIsRequester: false,
    },
  },
  {
    participantId: '4',
    status: ParticipantStatus.WITH_TICKET,

    userId: '4',
    name: 'Pedro',
    lastName: 'Sanchez',
    age: 42,
    phone: '',
    birthDate: '',
    gender: GenderType.MALE,
    originLocation: 'España',
    sentimentalSituation: SentimentalSituationType.SINGLE,

    userProfileId: '4',
    description:
      'Apasionado por transformar ideas en realidad, con enfoque en creatividad, impacto y evolución constante. 🚀',
    profilePictures: [profilePic, profilePic, profilePic],
    contactMethods: {
      email: '',
      linkedin: '',
      instagram: '',
      whatsapp: '',
    },
    eventId: '1',
    eventName: 'Cumple Leonel',

    ticketId: '3',
    ticketTypeId: '1',
    ticketScannedAt: '',

    bond: {
      id: '4',
      status: BondStatus.ACCEPTED,
      requesterId: '4',
      receiverId: '1',
      currentUserIsRequester: false,
    },
  },
  {
    participantId: '5',
    status: ParticipantStatus.WITH_TICKET,

    userId: '5',
    name: 'Dani',
    lastName: 'Vinet',
    age: 22,
    phone: '',
    birthDate: '',
    gender: GenderType.MALE,
    originLocation: 'Pilar',
    sentimentalSituation: SentimentalSituationType.SINGLE,

    userProfileId: '5',
    description:
      'Tecnología, innovación y crecimiento personal: mis tres motores para cada desafío que asumo. 🌍🔥',
    profilePictures: [profilePic, profilePic, profilePic],
    contactMethods: {
      email: '',
      linkedin: '',
      instagram: '',
      whatsapp: '',
    },

    eventId: '1',
    eventName: 'Cumple Leonel',

    ticketId: '4',
    ticketTypeId: '1',
    ticketScannedAt: '',

    bond: {
      id: '5',
      status: BondStatus.ACCEPTED,
      requesterId: '1',
      receiverId: '5',
      currentUserIsRequester: true,
    },
  },
  {
    participantId: '6',
    status: ParticipantStatus.WITH_TICKET,

    userId: '6',
    name: 'Lucas',
    lastName: 'Marchi',
    age: 26,
    phone: '',
    birthDate: '',
    gender: GenderType.MALE,
    originLocation: 'La Plata',
    sentimentalSituation: SentimentalSituationType.SINGLE,

    userProfileId: '6',
    description:
      'Amante de los retos, creador de soluciones y explorador de nuevas formas de innovar. 💡🌟',
    profilePictures: [profilePic, profilePic, profilePic],
    contactMethods: {
      email: '',
      linkedin: '',
      instagram: '',
      whatsapp: '',
    },

    eventId: '1',
    eventName: 'Cumple Leonel',

    ticketId: '5',
    ticketTypeId: '1',
    ticketScannedAt: '',

    bond: {
      id: '6',
      status: BondStatus.ACCEPTED,
      requesterId: '6',
      receiverId: '1',
      currentUserIsRequester: false,
    },
  },

  {
    participantId: '7',
    status: ParticipantStatus.WITH_TICKET,

    userId: '7',
    name: 'Carlos',
    lastName: 'Cruz',
    age: 26,
    phone: '',
    birthDate: '',
    gender: GenderType.MALE,
    originLocation: 'La Plata',
    sentimentalSituation: SentimentalSituationType.SINGLE,

    userProfileId: '6',
    description:
      'Amante de los retos, creador de soluciones y explorador de nuevas formas de innovar. 💡🌟',
    profilePictures: [profilePic, profilePic, profilePic],
    contactMethods: {
      email: '',
      linkedin: '',
      instagram: '',
      whatsapp: '',
    },

    eventId: '1',
    eventName: 'Cumple Leonel',

    ticketId: '5',
    ticketTypeId: '1',
    ticketScannedAt: '',

    bond: {
      id: '6',
      status: BondStatus.ACCEPTED,
      requesterId: '6',
      receiverId: '1',
      currentUserIsRequester: false,
    },
  },
  {
    participantId: '8',
    status: ParticipantStatus.WITH_TICKET,

    userId: '8',
    name: 'Leo',
    lastName: 'Paz',
    age: 26,
    phone: '',
    birthDate: '',
    gender: GenderType.MALE,
    originLocation: 'La Plata',
    sentimentalSituation: SentimentalSituationType.SINGLE,

    userProfileId: '6',
    description:
      'Amante de los retos, creador de soluciones y explorador de nuevas formas de innovar. 💡🌟',
    profilePictures: [profilePic, profilePic, profilePic],
    contactMethods: {
      email: '',
      linkedin: '',
      instagram: '',
      whatsapp: '',
    },

    eventId: '1',
    eventName: 'Cumple Leonel',

    ticketId: '5',
    ticketTypeId: '1',
    ticketScannedAt: '',

    bond: {
      id: '6',
      status: BondStatus.ACCEPTED,
      requesterId: '6',
      receiverId: '1',
      currentUserIsRequester: false,
    },
  },
  {
    participantId: '9',
    status: ParticipantStatus.WITH_TICKET,

    userId: '9',
    name: 'Juli',
    lastName: 'Lopez',
    age: 26,
    phone: '',
    birthDate: '',
    gender: GenderType.MALE,
    originLocation: 'La Plata',
    sentimentalSituation: SentimentalSituationType.SINGLE,

    userProfileId: '6',
    description:
      'Amante de los retos, creador de soluciones y explorador de nuevas formas de innovar. 💡🌟',
    profilePictures: [profilePic, profilePic, profilePic],
    contactMethods: {
      email: '',
      linkedin: '',
      instagram: '',
      whatsapp: '',
    },

    eventId: '1',
    eventName: 'Cumple Leonel',

    ticketId: '5',
    ticketTypeId: '1',
    ticketScannedAt: '',

    bond: {
      id: '6',
      status: BondStatus.ACCEPTED,
      requesterId: '6',
      receiverId: '1',
      currentUserIsRequester: false,
    },
  },
]
