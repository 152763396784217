import {
  TUserEventModel,
  fetchStatus,
  TParticipantDetailModel,
  TEventDetailModel,
  TTicketDetailModel,
  TParticipantModel,
  ParticipantStatus,
  TTicketsRequestModel,
  TEventParticipantsCursor,
  TBondDetailModel,
  TEventTicketTypeModel,
} from '@/models'
import { TAdminEventInfoModel } from '@/models/admin-event-info.model'
import {
  SET_PARTICIPANTS,
  GET_EVENT_PARTICIPANTS,
  GET_EVENT_PARTICIPANTS_LOADING,
  GET_EVENT_PARTICIPANTS_ERROR,
  GET_USER_EVENTS,
  GET_USER_EVENTS_LOADING,
  SET_SELECTED_EVENT_ID,
  EVENT_LOGOUT,
  GET_ADMIN_EVENT_INFO,
  GET_EVENT_DETAIL,
  SET_TRANSITION_INFO,
  SET_ENTRY_TICKETS,
  GET_EVENT_PARTICIPANT,
  GET_EVENT_PARTICIPANT_ERROR,
  GET_USER_EVENTS_ERROR,
  GET_ADMIN_EVENT_INFO_ERROR,
  GET_ADMIN_EVENT_INFO_LOADING,
  SET_PARTICIPANT_STATUS,
  SET_PARTICIPANT_STATUS_ERROR,
  SET_PARTICIPANT_STATUS_LOADING,
  GET_USER_EVENTS_TICKETS,
  GET_PARTICIPANT_BY_CODE,
  GET_PARTICIPANT_BY_CODE_ERROR,
  GET_PARTICIPANT_BY_CODE_LOADING,
  SCAN_TICKET,
  SCAN_TICKET_ERROR,
  SCAN_TICKET_LOADING,
  REQUEST_FREE_TICKET,
  REQUEST_FREE_TICKET_ERROR,
  REQUEST_FREE_TICKET_LOADING,
  UPDATE_PARTICIPANTS_BONDS,
} from '@/redux/constants'

export type TEventParticipants = {
  participantsId: TParticipantDetailModel['participantId'][]
  cursor?: TEventParticipantsCursor
  randOrderSeed?: number
  hasMore: boolean
  status: fetchStatus
  error: null | string
}

export type TEventState = {
  userEvents: TUserEventModel[]
  userEventsStatus: fetchStatus
  userEventsError: null | string
  // selectedEventId?: string
  eventDetail?: TEventDetailModel
  participants: Record<
    TParticipantDetailModel['participantId'],
    TParticipantDetailModel
  >
  eventsParticipants: Record<string, TEventParticipants>
  eventParticipantFetch: {
    status: fetchStatus
    error: null | string
  }
  userEventsTickets: TTicketDetailModel[]
  requestFreeTicket?: TTicketsRequestModel
  requestFreeTicketStatus: fetchStatus
  requestFreeTicketError: null | string
  adminEventInfo?: TAdminEventInfoModel
  adminEventInfoStatus: fetchStatus
  adminEventInfoError: null | string
  updateParticipantStatusStatus: fetchStatus
  updateParticipantStatusError: null | string
  participantByCode: null | TParticipantModel['id']
  participantByCodeStatus: fetchStatus
  participantByCodeError: null | string
  scanTicketStatus: fetchStatus
  scanTicketError: null | string
  //NOT USED
  transitionInfo: any
  foundEntryTickets: any
}

const initialState: TEventState = {
  userEvents: [],
  userEventsStatus: fetchStatus.TO_FETCH,
  userEventsError: null,
  // selectedEventId: undefined,
  eventDetail: undefined,
  participants: {},
  eventsParticipants: {},
  eventParticipantFetch: {
    status: fetchStatus.TO_FETCH,
    error: null,
  },
  requestFreeTicket: undefined,
  requestFreeTicketStatus: fetchStatus.TO_FETCH,
  requestFreeTicketError: null,
  adminEventInfo: undefined,
  adminEventInfoStatus: fetchStatus.TO_FETCH,
  adminEventInfoError: null,
  updateParticipantStatusStatus: fetchStatus.TO_FETCH,
  updateParticipantStatusError: null,
  userEventsTickets: [],
  participantByCode: null,
  participantByCodeStatus: fetchStatus.TO_FETCH,
  participantByCodeError: null,
  scanTicketStatus: fetchStatus.TO_FETCH,
  scanTicketError: null,
  //NOT USED
  transitionInfo: {},
  foundEntryTickets: {},
} as const

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_EVENTS_LOADING:
      return {
        ...state,
        userEventsStatus: fetchStatus.LOADING,
      }
    case GET_USER_EVENTS:
      return {
        ...state,
        userEvents: action.payload.userEvents as TUserEventModel[],
        userEventsStatus: fetchStatus.SUCCESS,
      }
    case GET_USER_EVENTS_ERROR:
      return {
        ...state,
        userEventsStatus: fetchStatus.ERROR,
        userEventsError: action.payload.error as string,
      }
    case GET_EVENT_DETAIL:
      return {
        ...state,
        eventDetail: action.payload as TEventDetailModel,
      }
    case SET_PARTICIPANTS:
      return {
        ...state,
        participants: {
          ...state.participants,
          ...(
            action.payload.participants as Partial<TParticipantDetailModel>[]
          ).reduce(
            (parts, part) => ({
              ...parts,
              [part.participantId as string]: {
                ...(state.participants?.[part.participantId as string] || {}),
                ...part,
                bond:
                  part.bond === undefined
                    ? state.participants?.[part.participantId as string]
                        ?.bond || null
                    : part.bond,
              },
            }),
            {} as TEventState['participants'],
          ),
        },
      }
    case UPDATE_PARTICIPANTS_BONDS:
      const updatePart = (part: TParticipantDetailModel) => {
        const foundNewPart = (
          action.payload.participants as TBondDetailModel[]
        ).find((newPart) => newPart.userId === part.userId)
        return foundNewPart ? { ...part, bond: foundNewPart.bond } : part
      }
      return {
        ...state,
        participants: {
          ...Object.values(state.participants).reduce(
            (allParts, part) => ({
              ...allParts,
              [part.participantId]: updatePart(part),
            }),
            {} as TEventState['participants'],
          ),
        },
      }
    case GET_EVENT_PARTICIPANTS_LOADING:
      const loadingEventId: string = action.payload.eventId
      return {
        ...state,
        eventsParticipants: {
          ...state.eventsParticipants,
          [loadingEventId]: {
            ...((action.payload.initialFetch
              ? undefined
              : state.eventsParticipants[loadingEventId]) || {
              participantsId: [],
              hasMore: true,
            }),
            status: fetchStatus.LOADING,
            error: null,
          },
        },
      }
    case GET_EVENT_PARTICIPANTS:
      const eventId: string = action.payload.eventId

      const newParticipantsId: TParticipantDetailModel['participantId'][] =
        (action.payload.participants as TParticipantDetailModel[]).flatMap(
          (part) => part.participantId,
        ) || []

      return {
        ...state,
        eventsParticipants: {
          ...state.eventsParticipants,
          [eventId]: {
            participantsId: [
              ...(state.eventsParticipants?.[eventId]?.participantsId || []),
              ...newParticipantsId,
            ],
            cursor: action.payload.cursor as TEventParticipantsCursor,
            randOrderSeed: action.payload.randOrderSeed as number,
            hasMore: action.payload.hasMore as boolean,
            status: fetchStatus.SUCCESS,
            error: null,
          },
        },
      }
    case GET_EVENT_PARTICIPANTS_ERROR:
      return {
        ...state,
        eventsParticipants: {
          ...state.eventsParticipants,
          [action.payload.eventId as string]: {
            ...(state.eventsParticipants[action.payload.eventId as string] || {
              participantsId: [],
            }),
            status: fetchStatus.ERROR,
            error: action.payload.error as string,
          },
        },
      }
    case GET_EVENT_PARTICIPANT:
      return {
        ...state,
        eventParticipantFetch: {
          status: fetchStatus.SUCCESS,
          error: null,
        },
      }
    case GET_EVENT_PARTICIPANT_ERROR:
      return {
        ...state,
        eventParticipantFetch: {
          status: fetchStatus.ERROR,
          error: action.payload.error as string,
        },
      }
    case GET_ADMIN_EVENT_INFO_LOADING:
      return {
        ...state,
        adminEventInfoStatus: fetchStatus.LOADING,
        adminEventInfoError: null,
      }
    case GET_ADMIN_EVENT_INFO:
      return {
        ...state,
        adminEventInfo: action.payload.adminEventInfo as TAdminEventInfoModel,
        adminEventInfoStatus: fetchStatus.SUCCESS,
        adminEventInfoError: null,
      }
    case GET_ADMIN_EVENT_INFO_ERROR:
      return {
        ...state,
        adminEventInfoStatus: fetchStatus.ERROR,
        adminEventInfoError: action.payload.error as string,
      }
    case SET_PARTICIPANT_STATUS_LOADING:
      return {
        ...state,
        updateParticipantStatusStatus: fetchStatus.LOADING,
        updateParticipantStatusError: null,
      }
    case SET_PARTICIPANT_STATUS:
      if (!state.adminEventInfo) return state
      let ticketTypeId = action.payload.ticketTypeId as string
      let newStatus =
        action.payload.newStatus === ParticipantStatus.WITH_TICKET
          ? ParticipantStatus.ACCEPTED
          : (action.payload.newStatus as 'PENDING' | 'ACCEPTED' | 'REJECTED')
      const prevStatus: 'PENDING' | 'ACCEPTED' | 'REJECTED' =
        action.payload.prevStatus === ParticipantStatus.WITH_TICKET
          ? ParticipantStatus.ACCEPTED
          : (action.payload.prevStatus as 'PENDING' | 'ACCEPTED' | 'REJECTED')
      const participantsId =
        (action.payload.participants as TParticipantModel[]).flatMap(
          (part) => part.id,
        ) || []

      const newEventTicketTypes: TEventTicketTypeModel[] =
        state.adminEventInfo.eventTicketTypes.reduce(
          (allticketTypes, ticketType) => [
            ...allticketTypes,
            ticketType.id !== (action.payload.ticketTypeId as string)
              ? ticketType
              : {
                  ...ticketType,
                  ticketsGiven: action.payload.updatedTicketsGiven as number,
                },
          ],
          [] as TEventTicketTypeModel[],
        )

      return {
        ...state,
        adminEventInfo: {
          ...state.adminEventInfo,
          ticketTypesParticipants: {
            ...state.adminEventInfo.ticketTypesParticipants,
            [ticketTypeId]: {
              ...state.adminEventInfo.ticketTypesParticipants[ticketTypeId],
              [prevStatus]: [
                ...(state.adminEventInfo.ticketTypesParticipants[ticketTypeId][
                  prevStatus
                ].filter((partId) => !participantsId.includes(partId)) || []),
              ],
              [newStatus]: [
                ...(state.adminEventInfo.ticketTypesParticipants[ticketTypeId][
                  newStatus
                ] || []),
                ...participantsId,
              ],
            },
          },
          eventTicketTypes: newEventTicketTypes,
        },
        updateParticipantStatusStatus: fetchStatus.SUCCESS,
        updateParticipantStatusError: null,
      }
    case SET_PARTICIPANT_STATUS_ERROR:
      return {
        ...state,
        updateParticipantStatusStatus: fetchStatus.ERROR,
        updateParticipantStatusError: action.payload.error as string,
      }
    // case SET_SELECTED_EVENT_ID:
    //   return {
    //     ...state,
    //     selectedEventId: action.payload.selectedEventId,
    //   }
    case SET_ENTRY_TICKETS:
      return {
        ...state,
        foundEntryTickets: action.payload,
      }
    case SET_SELECTED_EVENT_ID:
      return {
        ...state,
        selectedEventId: action.payload,
      }
    case GET_USER_EVENTS_TICKETS:
      return {
        ...state,
        userEventsTickets: action.payload as TTicketDetailModel[],
      }

    case REQUEST_FREE_TICKET_LOADING:
      return {
        ...state,
        requestFreeTicket: null,
        requestFreeTicketStatus: fetchStatus.LOADING,
        requestFreeTicketError: null,
      }
    case REQUEST_FREE_TICKET:
      return {
        ...state,
        requestFreeTicket: action.payload
          .ticketsRequest as TTicketsRequestModel,
        requestFreeTicketStatus: fetchStatus.SUCCESS,
        requestFreeTicketError: null,
        eventDetail: state.eventDetail
          ? {
              ...state.eventDetail,
              participantStatus: (
                action.payload.ticketsRequest as TTicketsRequestModel
              ).participant.status,
            }
          : undefined,
      }
    case REQUEST_FREE_TICKET_ERROR:
      return {
        ...state,
        requestFreeTicket: null,
        requestFreeTicketStatus: fetchStatus.ERROR,
        requestFreeTicketError: action.payload.error as string,
      }
    case GET_PARTICIPANT_BY_CODE_LOADING:
      return {
        ...state,
        participantByCode: null,
        participantByCodeStatus: fetchStatus.LOADING,
        participantByCodeError: null,
      }
    case GET_PARTICIPANT_BY_CODE:
      return {
        ...state,
        participantByCode: action.payload.participantId as string,
        participantByCodeStatus: fetchStatus.SUCCESS,
        participantByCodeError: null,
      }
    case GET_PARTICIPANT_BY_CODE_ERROR:
      return {
        ...state,
        participantByCode: null,
        participantByCodeStatus: fetchStatus.ERROR,
        participantByCodeError: action.payload.error as string,
      }
    case SCAN_TICKET_LOADING:
      return {
        ...state,
        scanTicketStatus: fetchStatus.LOADING,
        scanTicketError: null,
      }
    case SCAN_TICKET:
      return {
        ...state,
        scanTicketStatus: fetchStatus.SUCCESS,
        scanTicketError: null,
      }
    case SCAN_TICKET_ERROR:
      return {
        ...state,
        scanTicketStatus: fetchStatus.ERROR,
        scanTicketError: action.payload.error as string,
      }
    case EVENT_LOGOUT:
      return initialState
    case SET_TRANSITION_INFO:
      return {
        ...state,
        transitionInfo: action.payload,
      }

    default:
      return state
  }
}

export default eventReducer
