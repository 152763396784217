import { useState } from 'react'
import { View, Text, TextInput, Image, Alert } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useAppDispatch } from '@/redux/store'
import styles from './RegisterUserDataStyles'
import logoVincufy from '@/assets/Header/logoHeaderVincufy.png'
import DatePickerCalendarRegister from '../Inputs/DatePickerCalendarRegister/DatePickerCalendarRegister'
import SelectGenderRegister from '../Inputs/SelectGenderRegister/SelectGenderRegister'
import { completeRegister } from '@/redux/thunks'
import { CustomPressable } from '@/components'
import { theme } from '@/theme'

const RegisterUserData = () => {
  const dispatch = useAppDispatch()
  const [selectGenderOpen, setSelectGenderOpen] = useState(false)
  const [isLoadingRegister, setIsLoadingRegister] = useState(false)
  const [input, setInput] = useState({
    name: '',
    lastName: '',
    dateOfBirth: '',
    city: '',
    gender: '',
  })

  const handleChange = (newValue: string, name: string) => {
    const newInput = { ...input, [name]: newValue }
    setInput(newInput)
  }

  const handleSubmit = async () => {
    try {
      setIsLoadingRegister(true)
      await dispatch(completeRegister({ newUser: input })).unwrap()
    } catch {
      Alert.alert('Ups algo salió mal, intentalo de nuevo!')
    } finally {
      setIsLoadingRegister(false)
    }
  }

  const registerBtnDisabled =
    input.name?.length === 0 ||
    input.lastName?.length === 0 ||
    input.dateOfBirth?.length === 0 ||
    input.city?.length === 0 ||
    input.gender?.length === 0

  return (
    <View style={[styles.backgroundImage, theme.bgGradientCenter]}>
      <KeyboardAwareScrollView
        contentContainerStyle={styles.gral}
        keyboardShouldPersistTaps="handled"
      >
        <Image
          source={logoVincufy}
          style={styles.logoVincufy}
          resizeMode="contain"
        />
        <View style={styles.inputDiv}>
          <TextInput
            value={input.name}
            placeholder="Ingresá tu nombre"
            maxLength={15}
            placeholderTextColor="rgb(96, 96, 96)"
            style={styles.input}
            onChangeText={(e) => handleChange(e, 'name')}
            autoCorrect={false}
          />
          <TextInput
            value={input.lastName}
            placeholder="Ingresá tu apellido"
            placeholderTextColor="rgb(96, 96, 96)"
            style={styles.input}
            maxLength={15}
            onChangeText={(e) => handleChange(e, 'lastName')}
            autoCorrect={false}
          />
          <DatePickerCalendarRegister userData={input} setUserData={setInput} />
          <TextInput
            value={input.city}
            placeholder="Ciudad de residencia"
            placeholderTextColor="rgb(96, 96, 96)"
            style={styles.input}
            maxLength={20}
            onChangeText={(e) => handleChange(e, 'city')}
            autoCorrect={false}
          />
          <SelectGenderRegister
            userData={input}
            setUserData={setInput}
            visible={selectGenderOpen}
            setSelectGenderOpen={setSelectGenderOpen}
          />
        </View>

        <CustomPressable
          onPress={handleSubmit}
          style={styles.registerButton}
          disabled={registerBtnDisabled}
          isLoading={isLoadingRegister}
        >
          <Text style={styles.buttonText}>Completar Perfil</Text>
        </CustomPressable>
      </KeyboardAwareScrollView>
    </View>
  )
}

export default RegisterUserData
