import { memo } from 'react'
import { View, Text, Image } from 'react-native-web'
import styles from '../AllowNotificationsStyles'
// import errorNotifications from '@/assets/AllowNotifications/noNotifications.png'
import logoVincufy from '@/assets/AllowNotifications/hablando.png'
import arrowrightIcon from '@/assets/AllowNotifications/arrowright.png'
import { CustomPressable } from '@/components'

const NoNotifications = ({ onContinue }) => (
  <View style={styles.container}>
    <Image source={logoVincufy} style={styles.imageError} />
    <Text style={styles.title}>Bienvenido a Vincufy!</Text>
    <Text style={styles.welcomeDescription}>
      Completá tu perfil con tus fotos favoritas, una descripción que te luzca y
      empezá a vincularte con otros participantes apenas abra la sala virtual!
    </Text>
    <CustomPressable onPress={onContinue} style={styles.allowBtn}>
      <Text style={styles.allowBtnText}>Continuar</Text>
      <Image
        source={arrowrightIcon}
        style={styles.allowBtnIcon}
        tintColor="white"
      />
    </CustomPressable>
  </View>
)

export default memo(NoNotifications)
