import { useAppDispatch, useAppSelector } from '@/redux/store'
import { getEventBondsAction, respondBondRequestAction } from '@/redux/thunks'
import { useEffect, useState } from 'react'
import { fetchStatus } from '@/models/fetch.model'
import { Vibration } from 'react-native'
import { BondStatus } from '@/models/core/bond.model'
import { TParticipantDetailModel } from '@/models'

export const useEventBondsController = (eventId?: string) => {
  const dispatch = useAppDispatch()
  const eventBondsStatus = useAppSelector(
    (state) => state.bond.eventBondsStatus,
  )
  const eventBondsError = useAppSelector((state) => state.bond.eventBondsError)
  const respondBondRequestStatus = useAppSelector(
    (state) => state.bond.respondBondRequestStatus,
  )
  const respondBondRequestError = useAppSelector(
    (state) => state.bond.respondBondRequestError,
  )
  const participants = useAppSelector((state) => state.event.participants)
  const [eventBondsRequest, setEventBondsRequest] = useState<
    TParticipantDetailModel[]
  >([])

  useEffect(() => {
    const participantsArray = Object.values(participants || {})
    if (participantsArray?.length && eventId) {
      setEventBondsRequest(
        participantsArray.filter(
          (part) =>
            part.eventId === eventId &&
            part?.bond?.status === BondStatus.PENDING &&
            !part?.bond?.currentUserIsRequester,
        ),
      )
    }
  }, [participants, eventId])

  const eventBondsLoading =
    eventBondsStatus === fetchStatus.LOADING ||
    eventBondsStatus === fetchStatus.TO_FETCH

  const acceptBondRequestLoading =
    respondBondRequestStatus === fetchStatus.LOADING

  const getEventBonds = () => {
    if (eventId) {
      dispatch(getEventBondsAction(eventId))
    }
  }

  useEffect(() => {
    if (eventBondsStatus === fetchStatus.TO_FETCH) getEventBonds()
  }, [])

  const acceptBondRequest = (bondId?: string) => {
    if (bondId) {
      try {
        dispatch(
          respondBondRequestAction({ bondId, status: BondStatus.ACCEPTED }),
        )
        Vibration.vibrate()
      } catch {
        console.error('Error al aceptar la solicitud')
      }
    }
  }

  return {
    eventBondsRequest,
    eventBondsLoading,
    eventBondsError,
    getEventBonds,
    acceptBondRequest,
    acceptBondRequestLoading,
    acceptBondRequestError: respondBondRequestError,
  }
}
