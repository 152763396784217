//Response para obtener los tickets del usuario de un evento
import defaultEventPic from '@/assets/MyEvents/defaultPicEvent.png'
import { TTicketDetailModel } from '@/models/ticket-detail.model'

export const usersTicketsMock: TTicketDetailModel[] = [
  {
    eventId: '1',
    eventName: 'Event Default 1',
    ticketTypeName: 'General',
    eventFlyer: '',
    ticketId: '1',
    code: '123J',
    ticketTypeId: '1',
    receiptId: '1',
  },
]
