import styles from './EventHeaderInfoStyles'
// import { formatStringWithHyphens } from '@/utils'
import { View, Text, Image } from 'react-native'
import { CustomPressable } from '@/components'
import defaultEventPic from '@/assets/MyEvents/defaultPicEvent.png'
import { navigateTo } from 'routes/utils'

const EventHeaderInfo = ({ eventDetail }) => {
  const { flyer = '', eventName = '', eventId = '' } = eventDetail || {}
  const eventPicSource = flyer ? { uri: flyer } : defaultEventPic

  return (
    <>
      <View style={styles.imageEvent}>
        <Image source={eventPicSource} style={styles.image} />
      </View>
      <Text style={styles.titleEvent}>{eventName}</Text>
      <View style={styles.buttonDetailsContainer}>
        <CustomPressable
          style={styles.buttonDetails}
          onPress={() => {
            navigateTo('EventDetail', {
              eventId: eventId,
            })
          }}
        >
          <Text style={styles.buttonDetailsText}>Detalles</Text>
        </CustomPressable>
      </View>
    </>
  )
}

export default EventHeaderInfo
