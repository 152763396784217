import { useState, useRef, useEffect } from 'react'
import { useAppDispatch } from '@/redux/store'
import {
  View,
  Image,
  ScrollView,
  Dimensions,
  NativeSyntheticEvent,
  NativeScrollEvent,
  Text,
  ActivityIndicator,
} from 'react-native'
import { CustomPressable } from '..'
import {
  deleteProfilePictureDB,
  pickProfileImage,
} from '@/redux/thunks/user.thunk'
import styles from './CarouselStyles'
import changePic from '@/assets/Profile/changePic.png'
import deletePic from '@/assets/Profile/deletePic.png'
import defaultPic from '@/assets/People/defaultProfilePic.png'
import uploadPic from '@/assets/Profile/upload2.png'
// import SmallModal from '../Modals/SmallModal/SmallModal'
import { customAlert } from '@/utils/custom-alert.util'
import {
  ConfirmModal,
  TConfirmModal,
} from '../Modals/ConfirmModal/ConfirmModal'

const Carousel = ({
  images = [],
  isEditingProfile = false,
  // isMyProfile = false,
}: {
  images?: string[]
  isEditingProfile?: boolean
  // isMyProfile?: boolean
}) => {
  const dispatch = useAppDispatch()
  const scrollViewRef = useRef<ScrollView | null>(null)
  const [confirmModal, setConfirmModal] = useState<TConfirmModal>(null)
  // const [renderedImages, setRenderedImages] = useState<string[]>([])

  const MAX_PROFILE_PICTURES = 3
  type TImageConfig = {
    key: string
    isUploadImg?: boolean
    isDefaultImg?: boolean
    imageUrl: string
    showEditBtns: boolean
    showImageCounter: boolean
    imageNumber: number
    totalImagesToShow: number
  }
  const [imagesConfig, setImagesConfig] = useState<TImageConfig[]>([])
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const currentImages: string[] = images.filter((pic) => pic.length)
    const showUploadImage =
      isEditingProfile && currentImages.length < MAX_PROFILE_PICTURES
    const totalImagesToShow: number | null =
      currentImages.length + (showUploadImage ? 1 : 0)
    const showImageCounter = totalImagesToShow > 1

    const newImagesConfig: TImageConfig[] = []

    currentImages.forEach((currentImg, index) => {
      newImagesConfig.push({
        key: currentImg,
        imageUrl: currentImg,
        showEditBtns: isEditingProfile,
        showImageCounter,
        imageNumber: index + 1,
        totalImagesToShow,
      })
    })

    if (showUploadImage) {
      newImagesConfig.push({
        key: 'upload-img',
        isUploadImg: true,
        imageUrl: '',
        showEditBtns: false,
        showImageCounter,
        imageNumber: currentImages.length + 1,
        totalImagesToShow,
      })
    }

    if (!newImagesConfig.length) {
      newImagesConfig.push({
        key: 'default-img',
        isDefaultImg: true,
        imageUrl: '',
        showEditBtns: false,
        showImageCounter: false,
        imageNumber: 1,
        totalImagesToShow: 1,
      })
    }

    setImagesConfig(newImagesConfig)
  }, [images, isEditingProfile])

  const handlePickImage = async () => {
    const result = await dispatch(pickProfileImage(currentImageIndex)).unwrap()
    if (result === 'PERMISSION_NOT_GRANTED') {
      customAlert(
        'Para subir una nueva foto de perfil necesitamos permiso para acceder a tu galería.',
      )
    }
  }

  const handleConfirmDelete = () => {
    setConfirmModal({
      text: '¿Querés eliminar esta foto de perfil?',
      confirmFunc: async () => {
        await dispatch(deleteProfilePictureDB(currentImageIndex)).unwrap()
      },
    })
  }

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const contentOffsetX = event.nativeEvent.contentOffset.x
    const windowWidth = Dimensions.get('window').width
    const newIndex = Math.round(contentOffsetX / windowWidth)
    setCurrentImageIndex(newIndex)
  }

  if (!imagesConfig.length)
    return (
      <View style={styles.carouselContainer}>
        <ActivityIndicator size="large" color="#ffffff" />
      </View>
    )

  return (
    <View style={styles.carouselContainer}>
      <ScrollView
        ref={scrollViewRef}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        onScroll={handleScroll}
        scrollEventThrottle={200}
        style={{ width: Dimensions.get('window').width }}
      >
        {imagesConfig.map((imageConfig) => (
          <View key={imageConfig.key}>
            {imageConfig.showImageCounter && (
              <Text style={styles.picCounter}>
                {imageConfig.imageNumber}/{imageConfig.totalImagesToShow}
              </Text>
            )}
            <CustomPressable
              style={styles.imageContainer}
              onPress={imageConfig.isUploadImg ? handlePickImage : () => {}}
              disabled={!imageConfig.isUploadImg}
            >
              <Image
                source={
                  imageConfig.isUploadImg
                    ? uploadPic
                    : imageConfig.isDefaultImg
                      ? defaultPic
                      : { uri: imageConfig.imageUrl }
                }
                style={styles.image}
                resizeMode="cover"
              />
            </CustomPressable>
          </View>
        ))}
      </ScrollView>
      {imagesConfig[currentImageIndex]?.showEditBtns && (
        <View style={styles.buttonsContainer}>
          <CustomPressable onPress={handlePickImage}>
            <Image source={changePic} style={styles.editButton} />
          </CustomPressable>
          <CustomPressable onPress={handleConfirmDelete}>
            <Image source={deletePic} style={styles.editButton} />
          </CustomPressable>
        </View>
      )}
      {/* <View style={styles.indicatorContainer}>
      {imagesConfig.map((imageConfig) => (
        <View
          key={imageConfig.key}
          style={[
            styles.indicator,
            imageConfig.imageNumber -1 === currentImageIndex && styles.activeIndicator,
          ]}
        />
      ))}
    </View> */}
      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
      />
    </View>
  )
}

export default Carousel
