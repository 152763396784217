import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { ParticipantDetail, AdminPanel, TicketControl } from '@/views'

export type AdminPanelNavigatorParamList = {
  AdminPanel: {
    eventId: string
  }
  AdminPanelParticipant: {
    eventId: string
    participantId: string
  }
  TicketControl: {
    eventId: string
  }
  TicketControlParticipant: {
    eventId: string
    participantId: string
  }
}

const AdminPanelStack =
  createNativeStackNavigator<AdminPanelNavigatorParamList>()

export const AdminPanelNavigator = () => {
  return (
    <AdminPanelStack.Navigator
      initialRouteName={'AdminPanel'}
      screenOptions={{ headerShown: false }}
    >
      <AdminPanelStack.Screen
        name="AdminPanel"
        component={AdminPanel}
        options={{ title: 'Panel del organizador - Vincufy' }}
      />
      <AdminPanelStack.Screen
        name="AdminPanelParticipant"
        component={ParticipantDetail}
        options={{ title: 'Participante - Vincufy' }}
      />
      <AdminPanelStack.Screen
        name="TicketControl"
        component={TicketControl}
        options={{ title: 'Tickets en puerta - Vincufy' }}
      />
      <AdminPanelStack.Screen
        name="TicketControlParticipant"
        component={ParticipantDetail}
        options={{ title: 'Participante - Vincufy' }}
      />
    </AdminPanelStack.Navigator>
  )
}
