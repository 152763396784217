import { View, Text } from 'react-native'
import styles from './ProfilePicStyles'
import { CustomPressable } from '@/components'
import { TParticipantDetailModel } from '@/models'
import defaultProfilePic from '@/assets/People/defaultProfilePic.png'
import { useAppSelector } from '@/redux/store'

const ProfilePic = ({
  participantId,
  goToDetail,
}: {
  participantId: TParticipantDetailModel['participantId']
  goToDetail: (part: TParticipantDetailModel['participantId']) => void
}) => {
  const participantPic = useAppSelector(
    (state) => state.event.participants[participantId].profilePictures[0],
  )
  const participantName = useAppSelector(
    (state) => state.event.participants[participantId].name,
  )

  // const profilePic = participantPic
  //   ? { uri: participantPic }
  //   : defaultProfilePic

  const profilePic = participantPic ? participantPic : defaultProfilePic

  return (
    <CustomPressable
      onPress={() => goToDetail(participantId)}
      style={styles.container}
    >
      {/* <Image
        source={profilePic}
        style={styles.image}
        resizeMode="cover"
        defaultSource={defaultProfilePic}
      /> */}
      <img src={profilePic} alt="Profile" style={styles.image} loading="lazy" />
      <View style={styles.containerName}>
        <Text style={styles.textName}>{participantName}</Text>
      </View>
    </CustomPressable>
  )
}

export default ProfilePic
