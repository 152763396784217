import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { MyTickets, EventDetail, TransitionScreen } from '@/views'

export type EventDetailNavigatorParamList = {
  EventDetail: { eventId: string }
  MyTickets: { eventId: string }
  TransitionScreen: {
    eventId: string
  }
}

const EventDetailStack =
  createNativeStackNavigator<EventDetailNavigatorParamList>()

export const EventDetailNavigator = () => {
  return (
    <EventDetailStack.Navigator
      initialRouteName={'EventDetail'}
      screenOptions={{ headerShown: false }}
    >
      <EventDetailStack.Screen
        name="EventDetail"
        component={EventDetail}
        options={{ title: 'Detalles del evento - Vincufy' }}
      />
      <EventDetailStack.Screen
        name="MyTickets"
        component={MyTickets}
        options={{ title: 'Mis tickets - Vincufy' }}
      />
      <EventDetailStack.Screen
        name="TransitionScreen"
        component={TransitionScreen}
        options={{ title: 'Ingresando a la app' }}
      />
    </EventDetailStack.Navigator>
  )
}
