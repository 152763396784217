import {
  HeaderButtons,
  EventHeaderInfo,
  InfoCapsule,
  ParticipantsTicketControl,
} from './components'
import { Text, ScrollView, ActivityIndicator } from 'react-native'
import { ScreenLayout, CustomRefreshControl } from '@/components'
import styles from './AdminPanelStyles'
import { useAdminPanelController } from './useAdminPanelController'
import { goBackTo } from 'routes/utils'

const AdminPanel = () => {
  const {
    params,
    eventHeaderInfo,
    adminEventInfo,
    totalPendingParticipants,
    totalConfirmedTickets,
    totalAvailableTickets,
    refreshAdminEventInfo,
  } = useAdminPanelController()

  return (
    <ScreenLayout goBackFunc={() => goBackTo('MyEvents', {})}>
      <ScrollView
        style={styles.container}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <CustomRefreshControl onRefresh={refreshAdminEventInfo} />
        }
      >
        <HeaderButtons
          eventName={eventHeaderInfo?.eventName}
          eventType={eventHeaderInfo?.type}
          eventId={params?.eventId}
        />
        <EventHeaderInfo eventDetail={eventHeaderInfo} />
        {adminEventInfo === null ? (
          <ActivityIndicator size="small" color="#ffffff" />
        ) : (
          <>
            <InfoCapsule
              totalPendingParticipants={totalPendingParticipants}
              totalConfirmedTickets={totalConfirmedTickets}
              totalAvailableTickets={totalAvailableTickets}
            />
            <Text style={styles.title}>Invitados</Text>
            <ParticipantsTicketControl adminEventInfo={adminEventInfo} totalAvailableTickets={totalAvailableTickets}/>
            {/* {hasTicketsRequest ? (
            ) : (
              <PublicEventBox
                eventId={params?.eventId}
                participants={adminEventInfo.participants || []}
                eventType={adminEventInfo.type}
              />
            )} */}
          </>
        )}
      </ScrollView>
    </ScreenLayout>
  )
}

export default AdminPanel
